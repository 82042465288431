import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Grid, Container} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import PropTypes from 'prop-types'
import cls from 'classnames'
import numeral from 'numeral'
import styles from './GrowthResultsHeader.styles.js'

const formatNumber = value => numeral(value).format('0,0')
const formatCurrencyHeader = value => numeral(value).format('$0,0.[00]')

class GrowthResults extends React.Component {
  renderMap(locations) {
    if (window) {
      const {planResults} = this.props
      const MapComponent = require('./GrowthResultsMap.jsx').default
      return <MapComponent plan={planResults.plan} locations={locations} />
    }
    return null
  }

  render() {
    const {classes, planResults} = this.props

    const figureIdea = Math.floor(planResults.totalMonthlyRevenue * 12).toString().length

    const {locations} = planResults

    return (
      <React.Fragment>
        {planResults && planResults.plan && (
          <Container>
            <div className={classes.title}>
              Nice -&nbsp;
              {figureIdea > 4 ? (
                <span>
                  You&apos;ve got a <strong>{figureIdea} figure idea!</strong>
                </span>
              ) : (
                <span>You are onto something here!</span>
              )}
            </div>
          </Container>
        )}
        <Grid container className={classes.dashboardContainer}>
          <Grid item sm={4} className={cls(classes.dashboardItem, classes.dashboardMapItem)}>
            {this.renderMap(locations)}
          </Grid>
          <Grid item sm={4} className={classes.dashboardItem}>
            <span className={classes.totalImpressionsNumber}>
              {formatNumber(planResults.totalImpressions)}
            </span>
            <span className={classes.totalImpressionsLabel}>impressions</span>

            <div className={classes.dashboardImpressionsBar}>
              {planResults.locations.map((item, i) => {
                return (
                  <span
                    key={i}
                    className={classes.dashboardImpressionsBarItem}
                    style={{backgroundColor: item.color, width: `${item.searchPercent}%`}}
                  />
                )
              })}
            </div>
          </Grid>
          <Grid item sm={4} className={classes.dashboardItem}>
            <span className={classes.totalRevenueNumber}>
              {formatCurrencyHeader(planResults.totalMonthlyRevenue)}
            </span>
            <span className={classes.totalRevenueLabel}>Total Monthly Revenue</span>
            <span className={classes.totalProfitNumber}>
              {formatCurrencyHeader(planResults.totalMonthlyProfit)}
            </span>
            <span className={classes.totalProfitLabel}>Total Monthly Profit</span>
            <span className={classes.baseOnSpendLabel}>
              (Based on a monthly spend of {formatCurrencyHeader(planResults.monthlySpend)})
            </span>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

GrowthResults.displayName = 'GrowthResults'

GrowthResults.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

function mapStateToProps() {
  return {}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true}),
)(GrowthResults)
