import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Typography, CircularProgress} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import {withStyles} from '@material-ui/styles'
import PropTypes from 'prop-types'
import styles from './GrowthResults.styles.js'
import PlanApi from './redux/api'

class GrowthResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      results: null,
    }
  }

  componentDidMount() {
    this.getResults(this.props.params.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.id !== this.props.params.id) {
      this.getResults(this.props.params.id)
    }
  }

  render() {
    const {results} = this.state
    const {classes} = this.props

    const loading = (
      <div className={classes.loading}>
        <span>
          <Typography variant="h2" style={{color: '#abacb3', fontStyle: 'italic'}}>
            Ideas are worth nothing... unless executed.
          </Typography>

          <Typography variant="h4" gutterBottom>
            Processing results. Please wait.
          </Typography>
        </span>
        <CircularProgress />
      </div>
    )

    if (!results || !results.plan) {
      return loading
    }

    return (
      <div style={{overflow: 'hidden'}}>
        {results.plan.status === 'processing' && loading}
        {results.plan.status === 'error' && (
          <Typography variant="h4" align="center" color="error" gutterBottom>
            <ErrorIcon style={{verticalAlign: 'middle', marginRight: 10}} />
            There was an error while processing. You maybe seeing incomplete results.
          </Typography>
        )}
        {React.Children.map(this.props.children, child =>
          React.cloneElement(child, {
            planResults: this.state.results,
            getResults: this.getResults.bind(this),
          }),
        )}
      </div>
    )
  }

  getResults(id) {
    this.setState({isLoading: true}, () => {
      PlanApi.getResults(id)
        .then(response => {
          this.setState({results: response})

          if (response.plan.status === 'processing') {
            setTimeout(() => {
              this.getResults(id)
            }, 1000)
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.setState({isLoading: false})
        })
    })
  }
}

GrowthResults.displayName = 'GrowthResults'

GrowthResults.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

function mapStateToProps() {
  return {}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true}),
)(GrowthResults)
