import React, {useState, useEffect} from 'react'
import {
  Dialog,
  DialogTitle,
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {withRouter} from 'react-router'
import moment from 'moment'
import _ from 'lodash'
import AutoComplete from '../common/Autocomplete'
import InputTextField from '../common/styles/InputTextField.jsx'
import {CustomTooltip} from './GrowthPlan.styles.js'
import {
  CampaignStatusType,
  AdvertisingChannelType,
  AdGroupType,
  AdvertisingChannelEnum,
  SalesCountryOptions,
  AdvertisingChannelSubType,
  TrackingTemplatesType,
} from './CreateCampaignDialog.const'
import NumberFormatInput from '../common/NumberFormatInput.jsx'
import PlanApi from './redux/api'
import {
  createNotificationFromError,
  createSuccess,
  createError,
} from '../common/redux/actions.notifications'
import AdCreationForm from './AdCreationForm'
import {styles} from './CreateCampaignDialog.styles'
import CreateBidModifier from './CreateBidModifier'
import CreateSiteLink from './CreateSiteLink'
import CreateConversionAction from './CreateConversionAction'
import CreateCampaignBiddingConfiguration from './CreateCampaignBiddingConfiguration'
import LocationsSelect from './LocationsSelect'
import {parseCampaignErrors} from '../utils/ErrorUtils'

function CreateCampaignDialog({
  classes,
  createNotificationFromError,
  createSuccess,
  createError,
  isOpen,
  plan,
  onClose,
}) {
  const [canCreateAd, setCanCreateAd] = useState(false)
  const [canCreateSiteLink, setCanCreateSiteLink] = useState(false)
  const [canCreateBidModifier, setCanCreateBidModifier] = useState(false)
  const [canCreateConversionAction, setCanCreateConversionAction] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [campaignName, setCampaignName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [advertisingChannel, setAdvertisingChannel] = useState('')
  const [advertisingChannelSubType, setAdvertisingChannelSubType] = useState('')
  const [campaignStatus, setCampaignStatus] = useState(CampaignStatusType[0])
  const [biddingStrategy, setBiddingStrategy] = useState('')
  const [manualTargetCPA, setManualTargetCPA] = useState(false)
  const [manualTargetROAS, setManualTargetROAS] = useState(false)
  const [targetCPA, setTargetCPA] = useState('')
  const [targetROAS, setTargetROAS] = useState('')
  const [portfolioStrategy, setPortfolioStrategy] = useState('')
  const [createNewPortfolioBidding, setCreateNewPortfolioBidding] = useState('false')
  const [newPortfolioStrategyName, setNewPortfolioStrategyName] = useState('')
  const [budgetName, setBudgetName] = useState('')
  const [calloutExtension, setCalloutExtension] = useState('')
  const [trackingUrlTemplate, setTrackingUrlTemplate] = useState('')
  const [budgetAmount, setBudgetAmount] = useState()
  const [isExplicitlyShared, setIsExplicitlyShared] = useState(false)
  const [enhancedCpcEnabled, setEnhancedCpcEnabled] = useState(false)
  const [enableLocal, setEnableLocal] = useState(false)
  const [adGroup, setAdGroup] = useState('')
  const [trackingTemplatesType, setTrackingTemplatesType] = useState('')
  const [merchantCenterId, setMerchantCenterId] = useState()
  const [salesCountry, setSalesCountry] = useState(SalesCountryOptions[0])
  const [appId, setAppId] = useState('')
  const [targetGoogleSearch, setTargetGoogleSearch] = useState(true)
  const [targetSearchNetwork, setTargetSearchNetwork] = useState(false)
  const [targetContentNetwork, setTargetContentNetwork] = useState(false)
  const [targetPartnerSearchNetwork, setTargetPartnerSearchNetwork] = useState(false)
  const [response, setResponse] = useState()
  const [keywords, setKeywords] = useState(plan.plan.keywords)
  const [locations, setLocations] = useState(
    plan.plan.locations.features.map(location => ({
      ...location.properties,
      value: location.id,
      label: location.properties.name,
    })),
  )

  const onCampaignNameChange = e => setCampaignName(e.target.value)
  const onStartDateChange = e => setStartDate(e.target.value)
  const onEndDateChange = e => setEndDate(e.target.value)
  const onAdvertisingChange = value => setAdvertisingChannel(value)
  const onAdvertisingSubTypeChange = value => setAdvertisingChannelSubType(value)
  const onBiddingStrategyChange = value => setBiddingStrategy(value)
  const onAdGroupChange = value => setAdGroup(value)
  const onTrackingTemplatesTypeChange = value => setTrackingTemplatesType(value)
  const onSalesCountryChange = value => setSalesCountry(value)
  const onBudgetNameChange = e => setBudgetName(e.target.value)
  const onCalloutExtensionChange = e => setCalloutExtension(e.target.value)
  const onTrackingUrlTemplateChange = e => setTrackingUrlTemplate(e.target.value)
  const onBudgetAmountChange = e => setBudgetAmount(e.target.value)
  const onMerchantCenterIdChange = e => setMerchantCenterId(e.target.value)
  const onAppIdChange = e => setAppId(e.target.value)
  const onKeywordsChange = e => setKeywords(e.target.value)
  const onLocationChange = value => setLocations(value)

  const handleClose = () => {
    setCanCreateAd(false)
    setCanCreateBidModifier(false)
    setCanCreateSiteLink(false)
    setCanCreateConversionAction(false)
    onClose()
  }
  const handleSkipSiteLink = () => {
    setCanCreateBidModifier(true)
    setCanCreateAd(false)
    setCanCreateSiteLink(false)
    setCanCreateConversionAction(false)
  }

  const handleSkipBidModifier = () => {
    setCanCreateBidModifier(false)
    setCanCreateAd(false)
    setCanCreateSiteLink(false)
    setCanCreateConversionAction(true)
  }
  const handleSkipConversionAction = () => {
    setCanCreateBidModifier(false)
    setCanCreateAd(true)
    setCanCreateSiteLink(false)
    setCanCreateConversionAction(false)
  }

  useEffect(() => {
    if (isDisplay()) {
      setTargetGoogleSearch(false)
      setTargetContentNetwork(true)
    }
    if (isSearch()) {
      setTargetGoogleSearch(true)
    }
  }, [advertisingChannel])

  const handleSubmit = async () => {
    setIsLoading(true)
    const payload = {
      name: campaignName,
      campaignStatus: campaignStatus.value,
      startDate,
      endDate,
      advertisingChannelType: advertisingChannel.value,
      biddingStrategyType: biddingStrategy.value,
      budgetName,
      budgetAmount: parseFloat(budgetAmount).toFixed(2),
      isExplicitlyShared,
      adGroupType: adGroup.value,
      tracking_template_level: trackingTemplatesType.value,
      enhancedCpcEnabled,
      trackingUrlTemplate,
      calloutText: calloutExtension,
      advertisingChannelSubType: advertisingChannelSubType.value,
      appId,
      createNewPortfolioBidding: createNewPortfolioBidding === 'true',
      portfolioBiddingStrategyId: portfolioStrategy ? portfolioStrategy.value : '',
      newPortfolioBiddingStrategyName: newPortfolioStrategyName,
      targetCpa: targetCPA,
      targetRoas: targetROAS,
      keywords,
      locations: locations.map(location => location.value),
    }

    if (biddingStrategy?.value === 'TARGET_CPA' || biddingStrategy?.value === 'TARGET_ROAS') {
      if (createNewPortfolioBidding === 'false' && !portfolioStrategy) {
        createError('Please select a portfolio bidding strategy')
        setIsLoading(false)
        return
      }

      if (
        createNewPortfolioBidding === 'true' &&
        (!newPortfolioStrategyName || (!targetROAS && !targetCPA))
      ) {
        createError('Please fill in all the required fields for new portfolio bidding strategy')
        setIsLoading(false)
        return
      }
    }

    if (isShopping()) {
      payload.merchantId = Number(merchantCenterId)
      payload.enableLocal = enableLocal
      payload.salesCountry = salesCountry.value
    }

    if (isSearch()) {
      payload.targetGoogleSearch = targetGoogleSearch
    }

    if (isDisplay()) {
      payload.targetGoogleSearch = targetGoogleSearch
      payload.targetContentNetwork = targetContentNetwork
    }

    if (isMultiChannel()) {
      payload.targetGoogleSearch = targetGoogleSearch
      payload.targetSearchNetwork = targetSearchNetwork
      payload.targetContentNetwork = targetContentNetwork
      payload.targetPartnerSearchNetwork = targetPartnerSearchNetwork
    }

    try {
      const resp = await PlanApi.createCampaign(plan.plan.id, payload)
      setResponse(resp)
      createSuccess('Your Campaign, Default Ad Group and Budget has been successfully created.')
      setCanCreateSiteLink(true)
    } catch (e) {
      const error = parseCampaignErrors(e)
      if (error) {
        createError(error)
      } else {
        createNotificationFromError(e)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const isShopping = () => {
    return advertisingChannel.value === AdvertisingChannelEnum.SHOPPING
  }

  const isSearch = () => {
    return advertisingChannel.value === AdvertisingChannelEnum.SEARCH
  }

  const isDisplay = () => {
    return advertisingChannel.value === AdvertisingChannelEnum.DISPLAY
  }

  const isMultiChannel = () => {
    return advertisingChannel.value === AdvertisingChannelEnum.MULTI_CHANNEL
  }

  const canSubmit = () => {
    const val =
      !!campaignName &&
      !!startDate &&
      !!endDate &&
      !!advertisingChannel &&
      !!biddingStrategy &&
      !!budgetName &&
      !!budgetAmount &&
      !!adGroup

    return isShopping() ? !!merchantCenterId && val : val
  }

  const filterOptions = optionsList =>
    optionsList.filter(option => {
      if (isSearch()) return option.label.includes('SEARCH')
      if (isDisplay()) return option.label.includes('DISPLAY')
      if (isMultiChannel()) return option.label.includes('APP')
      if (isShopping()) return option.label.includes('SHOPPING')
      return option
    })

  return (
    <Dialog
      aria-labelledby="edit-confirmation-dialog-title"
      open={isOpen}
      scroll="body"
      disableBackdropClick
      className={classes.dialog}
    >
      <Grid container spacing={0} className={classes.container}>
        {canCreateSiteLink ? (
          <CreateSiteLink onSkip={handleSkipSiteLink} plan={plan} />
        ) : canCreateBidModifier && response ? (
          <CreateBidModifier onSkip={handleSkipBidModifier} plan={plan} response={response} />
        ) : canCreateConversionAction ? (
          <CreateConversionAction onSkip={handleSkipConversionAction} plan={plan} />
        ) : canCreateAd ? (
          <AdCreationForm onClose={handleClose} plan={plan} adGroup={adGroup} />
        ) : (
          <>
            <Grid item sm={12} className={classes.center}>
              <DialogTitle
                id="edit-confirmation-dialog-title"
                className={classes.dialogTitle}
                disableTypography
              >
                Create Campaign
              </DialogTitle>
            </Grid>
            <Grid container spacing={1} style={{marginLeft: '-14px'}}>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Campaign Name *
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Tooltip Text"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="text"
                  name="name"
                  value={campaignName}
                  onChange={onCampaignNameChange}
                  inputProps={{
                    maxLength: 40,
                    'aria-label': 'name',
                  }}
                />
              </Grid>

              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>Campaign Status *</Typography>
                <AutoComplete
                  required
                  className={classes.inputField}
                  value={campaignStatus}
                  options={CampaignStatusType}
                  placeholder="Campaign Status"
                  onChange={value => setCampaignStatus(value)}
                />
              </Grid>

              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>Start Date *</Typography>
                <InputTextField
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={onStartDateChange}
                  inputProps={{
                    'aria-label': 'startDate',
                    min: moment().format('YYYY-MM-DD'),
                  }}
                />
              </Grid>
              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>End Date *</Typography>
                <InputTextField
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="date"
                  name="startDate"
                  value={endDate}
                  onChange={onEndDateChange}
                  inputProps={{
                    'aria-label': 'endDate',
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Advertising Channel Type *
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Tooltip Text"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <AutoComplete
                  required
                  className={classes.inputField}
                  value={advertisingChannel}
                  options={AdvertisingChannelType}
                  placeholder="Advertising Channel"
                  onChange={onAdvertisingChange}
                />
              </Grid>
              {(isSearch() || isDisplay() || isMultiChannel()) && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="target_google_search"
                        name="target_google_search"
                        value={targetGoogleSearch}
                        checked={targetGoogleSearch}
                        disabled={isSearch() || isDisplay()}
                        onChange={e => setTargetGoogleSearch(!targetGoogleSearch)}
                      />
                    }
                    label={
                      <Typography className={classes.checkboxLabel}>
                        Target Google Search
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}
              {isMultiChannel() && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="target_search_network"
                        name="target_search_network"
                        value={targetSearchNetwork}
                        checked={targetSearchNetwork}
                        onChange={e => setTargetSearchNetwork(!targetSearchNetwork)}
                      />
                    }
                    label={
                      <Typography className={classes.checkboxLabel}>
                        Target Search Network
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}
              {(isDisplay() || isMultiChannel()) && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="target_content_network"
                        name="target_content_network"
                        value={targetContentNetwork}
                        checked={targetContentNetwork}
                        disabled={isDisplay()}
                        onChange={e => setTargetContentNetwork(!targetContentNetwork)}
                      />
                    }
                    label={
                      <Typography className={classes.checkboxLabel}>
                        Target Content Network
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}

              {isMultiChannel() && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="target_partner_search_network"
                        name="target_partner_search_network"
                        value={targetPartnerSearchNetwork}
                        checked={targetPartnerSearchNetwork}
                        onChange={e => setTargetPartnerSearchNetwork(!targetPartnerSearchNetwork)}
                      />
                    }
                    label={
                      <Typography className={classes.checkboxLabel}>
                        Target Partner Search Network
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Advertising Channel Sub Type
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Tooltip Text"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <AutoComplete
                  required
                  className={classes.inputField}
                  value={advertisingChannelSubType}
                  options={filterOptions(AdvertisingChannelSubType)}
                  placeholder="Advertising Channel Sub Type"
                  onChange={onAdvertisingSubTypeChange}
                />
              </Grid>
              {isShopping() && (
                <>
                  <Grid item sm={12} className={classes.inputGrid}>
                    <Typography className={classes.label}>
                      Merchant Center ID *
                      <CustomTooltip
                        disableFocusListener
                        disableTouchListener
                        title="Merchant Center ID"
                        placement="top"
                        className={classes.tooltip}
                      >
                        <InfoOutlinedIcon className={classes.icon} />
                      </CustomTooltip>
                    </Typography>
                    <InputTextField
                      withValidator
                      shouldValidateOnBlur={true}
                      required
                      type="string"
                      name="merchantCenterId"
                      value={merchantCenterId}
                      onChange={onMerchantCenterIdChange}
                      inputComponent={NumberFormatInput}
                      inputProps={{
                        maxLength: 18,
                        thousandSeparator: false,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} className={classes.inputGrid}>
                    <Typography className={classes.label}>
                      Sales Country
                      <CustomTooltip
                        disableFocusListener
                        disableTouchListener
                        title="Tooltip Text"
                        placement="top"
                        className={classes.tooltip}
                      >
                        <InfoOutlinedIcon className={classes.icon} />
                      </CustomTooltip>
                    </Typography>
                    <AutoComplete
                      required
                      isDisabled
                      className={classes.inputField}
                      value={salesCountry}
                      options={SalesCountryOptions}
                      placeholder="Advertising Channel"
                      onChange={onSalesCountryChange}
                    />
                  </Grid>
                  <Grid item sm={12} className={classes.inputGrid}>
                    <FormControlLabel
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          id="local-inventory"
                          name="local-inventory"
                          value={enableLocal}
                          checked={enableLocal}
                          onChange={e => setEnableLocal(!enableLocal)}
                        />
                      }
                      label={
                        <Typography className={classes.checkboxLabel}>
                          Local Inventory Ads
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </Grid>
                </>
              )}

              <CreateCampaignBiddingConfiguration
                biddingStrategy={biddingStrategy}
                setBiddingStrategy={setBiddingStrategy}
                manualTargetCPA={manualTargetCPA}
                setManualTargetCPA={setManualTargetCPA}
                manualTargetROAS={manualTargetROAS}
                setManualTargetROAS={setManualTargetROAS}
                targetCPA={targetCPA}
                setTargetCPA={setTargetCPA}
                targetROAS={targetROAS}
                setTargetROAS={setTargetROAS}
                isShopping={isShopping}
                isDisplay={isDisplay}
                onBiddingStrategyChange={onBiddingStrategyChange}
                portfolioStrategy={portfolioStrategy}
                setPortfolioStrategy={setPortfolioStrategy}
                newPortfolioStrategyName={newPortfolioStrategyName}
                setNewPortfolioStrategyName={setNewPortfolioStrategyName}
                createNewPortfolioBidding={createNewPortfolioBidding}
                setCreateNewPortfolioBidding={setCreateNewPortfolioBidding}
              />

              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Budget Name *
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Tooltip Text"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="text"
                  name="name"
                  value={budgetName}
                  onChange={onBudgetNameChange}
                  inputProps={{
                    maxLength: 40,
                    'aria-label': 'name',
                  }}
                />
              </Grid>
              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Budget Amount *
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Tooltip Text"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="string"
                  name="budgetAmount"
                  value={budgetAmount}
                  onChange={onBudgetAmountChange}
                  placeholder="$"
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    prefix: '$',
                    maxLength: 18,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      id="is-explicitly-shared"
                      name="is-explicitly-shared"
                      value={isExplicitlyShared}
                      checked={isExplicitlyShared}
                      onChange={e => setIsExplicitlyShared(!isExplicitlyShared)}
                    />
                  }
                  label={
                    <Typography className={classes.checkboxLabel}>Is Explicitly Shared</Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      id="is-explicitly-shared"
                      name="is-explicitly-shared"
                      value={enhancedCpcEnabled}
                      checked={enhancedCpcEnabled}
                      onChange={e => setEnhancedCpcEnabled(!enhancedCpcEnabled)}
                    />
                  }
                  label={
                    <Typography className={classes.checkboxLabel}>Enhanced CPC Enabled</Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  App Id
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Tooltip Text"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="app Id"
                  value={appId}
                  onChange={onAppIdChange}
                  inputProps={{
                    maxLength: 40,
                    'aria-label': 'name',
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Ad Groups Type *
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Tooltip Text"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <AutoComplete
                  required
                  className={classes.inputField}
                  value={adGroup}
                  options={filterOptions(AdGroupType)}
                  placeholder="Ad Group"
                  onChange={onAdGroupChange}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Tracking Templates Type
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Tooltip Text"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <AutoComplete
                  className={classes.inputField}
                  value={trackingTemplatesType}
                  options={TrackingTemplatesType}
                  placeholder="Tracking Templates Type"
                  onChange={onTrackingTemplatesTypeChange}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Tracking Url Template
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="ex: https://www.trackingtemplate.foo/?url={lpurl}"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="name"
                  value={trackingUrlTemplate}
                  onChange={onTrackingUrlTemplateChange}
                  inputProps={{
                    maxLength: 500,
                    'aria-label': 'name',
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Callout Extension
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Comma separate if more than 1"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="name"
                  value={calloutExtension}
                  onChange={onCalloutExtensionChange}
                  inputProps={{
                    maxLength: 500,
                    'aria-label': 'name',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>Keywords</Typography>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  required
                  name="keywords"
                  value={keywords}
                  onChange={onKeywordsChange}
                  placeholder="Keywords"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>Region</Typography>
                <LocationsSelect required value={locations} onChange={onLocationChange} />
              </Grid>
            </Grid>

            <Grid item sm={12} className={classes.buttons}>
              <Grid item sm={12} className={classes.center}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className={classes.continueButton}
                  onClick={handleSubmit}
                  disabled={!canSubmit() || isLoading}
                >
                  Create Campaign
                </Button>
              </Grid>
              <Grid item sm={12} className={classes.center}>
                <Button size="small" className={classes.cancelButton} onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
    schedule: state.schedule,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess, createError}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(CreateCampaignDialog)
