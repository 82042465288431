const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 18%',
    position: 'absolute',
    top: 112, //header height (80px) + padding (32px)
    height: '100%',
    backgroundColor: theme.palette.Gray[300],
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 30,
    fontWeight: 400,
    color: theme.palette.Blue[600],
    textAlign: 'left',
    marginBottom: 32,
  },
  subtitle: {
    fontSize: 24,
    fontWeight: 400,
    color: theme.palette.Gray[800],
  },
  tooltip: {
    position: 'relative',
    top: 6,
    fill: '#979797',
    marginBottom: 8,
  },
  icon: {
    margin: `0 4px`,
    width: '25px',
    color: '#979797',
    cursor: `pointer`,
  },
  select: {
    color: theme.palette.Gray[800],
    borderColor: theme.palette.Blue[600],
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    padding: '8px 8px',
    '& > .Select-arrow': {
      backgroundColor: 'red',
    },
  },
})
export default styles
