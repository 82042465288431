import React, {useState} from 'react'
import {Button, DialogTitle, Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {withRouter} from 'react-router'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import {connect} from 'react-redux'
import {styles} from './CreateCampaignDialog.styles'
import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications'
import PlanApi from './redux/api'
import InputTextField from '../common/styles/InputTextField'

const CreateConversionAction = ({
  classes,
  onSkip,
  plan,
  createSuccess,
  createNotificationFromError,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [lookbackWindowDays, setlookbackWindowDays] = useState('')
  const [defaultValue, setdefaultValue] = useState('')

  const onSiteLinkChange = e => setName(e.target.value)
  const onWindowDaysChange = e => setlookbackWindowDays(e.target.value)
  const onDefaultValueChange = e => setdefaultValue(e.target.value)

  const handleSkip = () => {
    onSkip()
  }

  const canAdd = () => {
    return !_.isEmpty(name) && !_.isEmpty(lookbackWindowDays) && !_.isEmpty(defaultValue)
  }

  const createNewCA = () => {
    return {
      name,
      lookback_window_days: lookbackWindowDays,
      default_value: defaultValue,
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await PlanApi.createConversionAction(plan.plan.id, createNewCA())
      createSuccess('Conversion Action created successfully.')
      handleSkip()
    } catch (e) {
      createNotificationFromError(`An Error occurred when creating Conversion Action: ${e}`)
    } finally {
      setIsLoading(false)
      resetParameters()
    }
  }

  const resetParameters = () => {
    setName('')
    setlookbackWindowDays('')
    setdefaultValue('')
  }
  return (
    <>
      <Grid item sm={12} className={classes.center}>
        <DialogTitle
          id="edit-confirmation-dialog-title"
          className={classes.dialogTitle}
          disableTypography
        >
          Create Conversion Actions
        </DialogTitle>
      </Grid>
      <Grid container spacing={1} style={{marginLeft: '-14px'}}>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>Conversion Action Text *</Typography>
          <InputTextField
            withValidator
            required
            shouldValidateOnBlur={true}
            type="text"
            name="name"
            value={name}
            onChange={onSiteLinkChange}
            inputProps={{
              'aria-label': 'name',
            }}
          />
        </Grid>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>Look Back Window Days *</Typography>
          <InputTextField
            withValidator
            shouldValidateOnBlur={true}
            type="number"
            required
            name="lookbackWindowDays"
            value={lookbackWindowDays}
            onChange={onWindowDaysChange}
            inputProps={{
              'aria-label': 'lookbackWindowDays',
            }}
          />
        </Grid>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>Default Value *</Typography>
          <InputTextField
            withValidator
            shouldValidateOnBlur={true}
            type="number"
            required
            name="defaultValue"
            value={defaultValue}
            onChange={onDefaultValueChange}
            inputProps={{
              'aria-label': 'defaultValue',
            }}
          />
        </Grid>
      </Grid>

      <Grid item sm={12} className={classes.buttons}>
        <Grid item sm={12} className={classes.center}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.bmCreate}
            onClick={handleSubmit}
            disabled={!canAdd() || isLoading}
          >
            Create Conversion Action
          </Button>
        </Grid>
        <Grid item sm={12} className={classes.center}>
          <Button size="small" className={classes.cancelButton} onClick={handleSkip}>
            Skip
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(CreateConversionAction)
