import settings from '../../../config/settings'
import axios from '../api/axios'

const {apiBaseURL} = settings

export default class PlanHistoryApi {
  static getPlanHistory(params) {
    return axios.get(`${apiBaseURL}/plans`, {params})
  }
}
