const styles = theme => ({
  reportsTable: {
    display: 'flex',
    width: '100%',
    marginTop: 24,
    marginBottom: 32,
    flexWrap: 'wrap',
    gap: 8,
  },
  reportsCell: {
    minWidth: 195,
    minHeight: 120,
    backgroundColor: theme.palette.Gray[100],
    border: `1px solid ${theme.palette.Gray[400]}`,
    borderRadius: 4,
    padding: 16,
  },
  reportsCellHeader: {
    fontSize: 14,
    color: theme.palette.Blue[500],
    fontWeight: 400,
  },
  reportsCellContent: {
    fontSize: 16,
    color: theme.palette.Gray[900],
    fontWeight: 400,
    marginTop: 8,
  },
})
export default styles
