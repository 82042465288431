import React, {useState, useEffect} from 'react'
import {
  Dialog,
  DialogTitle,
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {withRouter} from 'react-router'
import _ from 'lodash'
import moment from 'moment'
import AutoComplete from '../common/Autocomplete'
import InputTextField from '../common/styles/InputTextField.jsx'
import {CustomTooltip} from './GrowthPlan.styles.js'
import {
  CampaignStatusType,
  BiddingStrategyType,
  AdvertisingChannelType,
  AdGroupType,
  AdvertisingChannelEnum,
  SalesCountryOptions,
  AdvertisingChannelSubType,
  TrackingTemplatesType,
} from './CreateCampaignDialog.const'
import NumberFormatInput from '../common/NumberFormatInput.jsx'
import PlanApi from './redux/api'
import {
  createNotificationFromError,
  createSuccess,
  createError,
} from '../common/redux/actions.notifications'
import {styles} from './CreateCampaignDialog.styles'
import CreateCampaignBiddingConfiguration from './CreateCampaignBiddingConfiguration'
import LocationsSelect from './LocationsSelect'
import {parseCampaignErrors} from '../utils/ErrorUtils'

function EditCampaignDialog({
  classes,
  createNotificationFromError,
  createSuccess,
  createError,
  isOpen,
  plan,
  onClose,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [manualTargetCPA, setManualTargetCPA] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false)
  const [manualTargetROAS, setManualTargetROAS] = useState(false)

  // Mutable fields
  const [campaignName, setCampaignName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [campaignStatus, setCampaignStatus] = useState(CampaignStatusType[0])
  const [biddingStrategy, setBiddingStrategy] = useState('')
  const [budgetName, setBudgetName] = useState('')
  const [budgetAmount, setBudgetAmount] = useState('')
  const [isExplicitlyShared, setIsExplicitlyShared] = useState(false)
  const [enhancedCpcEnabled, setEnhancedCpcEnabled] = useState(false)
  const [targetGoogleSearch, setTargetGoogleSearch] = useState(false)
  const [targetSearchNetwork, setTargetSearchNetwork] = useState(false)
  const [targetContentNetwork, setTargetContentNetwork] = useState(false)
  const [targetPartnerSearchNetwork, setTargetPartnerSearchNetwork] = useState(false)
  const [targetCPA, setTargetCPA] = useState('')
  const [targetROAS, setTargetROAS] = useState('')
  const [portfolioStrategy, setPortfolioStrategy] = useState('')
  const [createNewPortfolioBidding, setCreateNewPortfolioBidding] = useState('false')
  const [newPortfolioStrategyName, setNewPortfolioStrategyName] = useState('')
  const [enableLocal, setEnableLocal] = useState(false)
  const [merchantCenterId, setMerchantCenterId] = useState('')
  const [locations, setLocations] = useState([])
  const [negativeKeywords, setNegativeKeywords] = useState('')
  const [language, setLanguage] = useState('')
  const [languages, setLanguages] = useState([])

  // Immutable fields
  const [advertisingChannel, setAdvertisingChannel] = useState('')
  const [advertisingChannelSubType, setAdvertisingChannelSubType] = useState('')
  const [calloutExtension, setCalloutExtension] = useState('')
  const [trackingUrlTemplate, setTrackingUrlTemplate] = useState('')
  const [adGroupType, setAdGroupType] = useState('')
  const [trackingTemplatesType, setTrackingTemplatesType] = useState('')
  const [salesCountry] = useState(SalesCountryOptions[0])

  useEffect(() => {
    setIsLoading(true)
    PlanApi.getCampaign(plan?.id).then(
      ({
        campaign,
        campaignBudget,
        adGroup,
        trackingTemplateLevel,
        account,
        asset,
        biddingStrategy: originalBiddingStrategy,
        locations: campaignLocations,
        negativeKeywords,
        language: campaignLanguage,
      }) => {
        setCampaignStatus(
          CampaignStatusType.find(status => status.value === campaign?.status) ||
            CampaignStatusType[0],
        )
        setManualTargetCPA(!!originalBiddingStrategy?.targetCpa?.targetCpaMicros)
        setManualTargetROAS(!!originalBiddingStrategy?.targetRoas?.targetRoas)
        setCampaignName(campaign?.name?.split(' #')[0] || '')
        setStartDate(campaign?.startDate || '')
        setEndDate(campaign?.endDate || '')
        setBiddingStrategy(
          BiddingStrategyType.find(type => type.value === campaign?.biddingStrategyType),
        )
        setBudgetName(campaignBudget?.name.split(' #')[0] || '')
        setBudgetAmount(parseFloat(campaignBudget?.amountMicros).toFixed(2) / 1000000)
        setIsExplicitlyShared(campaignBudget?.explicitlyShared)
        setEnhancedCpcEnabled(campaign?.manualCpc?.enhancedCpcEnabled)
        setTargetGoogleSearch(campaign?.networkSettings?.targetGoogleSearch)
        setTargetSearchNetwork(campaign?.networkSettings?.targetSearchNetwork)
        setTargetContentNetwork(campaign?.networkSettings?.targetContentNetwork)
        setTargetPartnerSearchNetwork(campaign?.networkSettings?.targetPartnerSearchNetwork)
        setTargetCPA(
          parseFloat(
            originalBiddingStrategy?.targetCpa?.targetCpaMicros ||
              campaign?.maximizeConversions?.targetCpa,
          ).toFixed(2) / 1000000,
        )
        setTargetROAS(originalBiddingStrategy?.targetRoas?.targetRoas)
        setPortfolioStrategy(
          originalBiddingStrategy
            ? {value: originalBiddingStrategy?.id, label: originalBiddingStrategy?.name}
            : '',
        )
        setMerchantCenterId(campaign?.shoppingSetting?.merchantId)
        setAdvertisingChannel(
          AdvertisingChannelType.find(type => type.value === campaign?.advertisingChannelType),
        )
        setAdvertisingChannelSubType(
          AdvertisingChannelSubType.find(
            type => type.value === campaign?.advertisingChannelSubType,
          ),
        )
        setCalloutExtension(asset?.calloutAsset?.calloutText)
        setTrackingUrlTemplate(
          trackingTemplateLevel === 'campaign'
            ? campaign?.trackingUrlTemplate
            : account?.trackingUrlTemplate,
        )
        setAdGroupType(AdGroupType.find(type => type.value === adGroup?.type))
        setTrackingTemplatesType(
          TrackingTemplatesType.find(type => type.value === trackingTemplateLevel),
        )
        setLocations(
          campaignLocations.map(location => ({
            ...location,
            value: location.locationId,
            label: location.name,
          })),
        )
        setNegativeKeywords(negativeKeywords)
        setLanguage(campaignLanguage)
        setIsLoading(false)
      },
    )
  }, [])

  const onCampaignNameChange = e => setCampaignName(e.target.value)
  const onStartDateChange = e => setStartDate(e.target.value)
  const onEndDateChange = e => setEndDate(e.target.value)
  const onBiddingStrategyChange = value => setBiddingStrategy(value)
  const onBudgetNameChange = e => setBudgetName(e.target.value)
  const onBudgetAmountChange = e => setBudgetAmount(e.target.value)
  const onMerchantCenterIdChange = e => setMerchantCenterId(e.target.value)
  const onLocationChange = value => setLocations(value)
  const onNegativeKeywordsChange = e => setNegativeKeywords(e.target.value)
  const onLanguageChange = value => setLanguage(value)

  const handleClose = () => {
    onClose()
  }
  useEffect(() => {
    if (isDisplay()) {
      setTargetGoogleSearch(false)
      setTargetContentNetwork(true)
    }
    if (isSearch()) {
      setTargetGoogleSearch(true)
    }
  }, [advertisingChannel])

  useEffect(() => {
    loadLanguages()
  }, [])

  const loadLanguages = async () => {
    setIsLoadingLanguages(true)
    const response = await PlanApi.getLanguages()
    const lang = response.map(item => ({
      label: item.languageName,
      value: item.criterionId,
    }))
    const empty = {
      label: 'None',
      value: '',
    }
    setLanguages([empty, ...lang])
    setIsLoadingLanguages(false)
  }

  const getPayload = async () => {
    const {
      campaign,
      campaignBudget,
      biddingStrategy: originalBiddingStrategy,
      negativeKeywords: originalNegativeKeywords,
      language: originalLanguage,
    } = await PlanApi.getCampaign(plan?.id)
    const payload = {
      campaignStatus: campaignStatus.value,
    }

    if (biddingStrategy?.value === 'TARGET_CPA' || biddingStrategy?.value === 'TARGET_ROAS') {
      if (createNewPortfolioBidding === 'false' && !portfolioStrategy) {
        createError('Please select a portfolio bidding strategy')
        setIsLoading(false)
        return
      }

      if (
        createNewPortfolioBidding === 'true' &&
        (!newPortfolioStrategyName || (!targetROAS && !targetCPA))
      ) {
        createError('Please fill in all the required fields for new portfolio bidding strategy')
        setIsLoading(false)
        return
      }
    }

    if (campaignName && campaignName !== campaign?.name?.split(' #')[0]) {
      payload.name = campaignName
    }

    if (startDate && startDate !== campaign?.startDate) {
      payload.startDate = startDate
    }

    if (endDate && endDate !== campaign?.endDate) {
      payload.endDate = endDate
    }

    if (budgetName && budgetName !== campaignBudget?.name.split(' #')[0]) {
      payload.budgetName = budgetName
    }

    if (
      budgetAmount &&
      budgetAmount !== parseFloat(campaignBudget?.amountMicros).toFixed(2) / 1000000
    ) {
      payload.budgetAmount = budgetAmount
    }

    if (biddingStrategy) {
      payload.biddingStrategyType = biddingStrategy.value
    }

    if (createNewPortfolioBidding) {
      payload.createNewPortfolioBidding = createNewPortfolioBidding
    }

    if (portfolioStrategy && portfolioStrategy.value !== originalBiddingStrategy?.id) {
      payload.portfolioBiddingStrategyId = portfolioStrategy?.value || ''
    }

    if (newPortfolioStrategyName) {
      payload.newPortfolioBiddingStrategyName = newPortfolioStrategyName
    }

    if (targetCPA) {
      payload.targetCpa = targetCPA
    }

    if (targetROAS) {
      payload.targetRoas = targetROAS
    }

    if (isExplicitlyShared === true || isExplicitlyShared === false) {
      payload.isExplicitlyShared = isExplicitlyShared
    }

    if (
      (enhancedCpcEnabled === true || enhancedCpcEnabled === false) &&
      biddingStrategy?.value === 'MANUAL_CPC'
    ) {
      payload.enhancedCpcEnabled = enhancedCpcEnabled
    }

    if (advertisingChannel?.value === 'SHOPPING') {
      if (enableLocal === true || enableLocal === false) {
        payload.enableLocal = enableLocal
      }

      if (merchantCenterId) {
        payload.merchantCenterId = merchantCenterId
      }
    }

    payload.locations = locations.map(location => location.value)

    payload.negativeKeywords = negativeKeywords

    payload.language = language.value

    return payload
  }

  const handleSubmit = async () => {
    setIsEditing(true)
    try {
      await PlanApi.updateCampaign(plan.id, await getPayload())
      createSuccess('Your campaign was successfully edited.')
      handleClose()
    } catch (e) {
      const error = parseCampaignErrors(e)
      if (error) {
        createError(error)
      } else {
        createNotificationFromError(e)
      }
    } finally {
      setIsEditing(false)
    }
  }

  const handleRemove = async () => {
    try {
      await PlanApi.removeCampaign(plan.id)
      createSuccess('Your campaign was successfully deleted.')
    } catch (e) {
      const error = parseCampaignErrors(e)
      if (error) {
        createError(error)
      } else {
        createNotificationFromError(e)
      }
    } finally {
      onClose()
      setIsLoading(false)
    }
  }

  const isShopping = () => {
    return advertisingChannel.value === AdvertisingChannelEnum.SHOPPING
  }

  const isSearch = () => {
    return advertisingChannel.value === AdvertisingChannelEnum.SEARCH
  }

  const isDisplay = () => {
    return advertisingChannel.value === AdvertisingChannelEnum.DISPLAY
  }

  const isMultiChannel = () => {
    return advertisingChannel.value === AdvertisingChannelEnum.MULTI_CHANNEL
  }

  const canSubmit = () => {
    const val =
      !!campaignName &&
      !!startDate &&
      !!endDate &&
      !!advertisingChannel &&
      !!biddingStrategy &&
      !!budgetName &&
      !!budgetAmount &&
      !!adGroupType

    return isShopping() ? !!merchantCenterId && val : val
  }

  return (
    <Dialog
      aria-labelledby="edit-confirmation-dialog-title"
      open={isOpen}
      scroll="body"
      disableBackdropClick
      className={classes.dialog}
    >
      <Grid container spacing={0} className={classes.container}>
        {isLoading || isLoadingLanguages ? (
          <Typography>Loading campaign data...</Typography>
        ) : (
          <>
            <Grid item sm={12} className={classes.center}>
              <DialogTitle
                id="edit-confirmation-dialog-title"
                className={classes.dialogTitle}
                disableTypography
              >
                Edit Campaign
              </DialogTitle>
            </Grid>
            <Grid container spacing={1} style={{marginLeft: '-14px'}}>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>Campaign Name *</Typography>
                <InputTextField
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="text"
                  name="name"
                  value={campaignName}
                  onChange={onCampaignNameChange}
                  inputProps={{
                    maxLength: 40,
                    'aria-label': 'name',
                  }}
                />
              </Grid>

              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>Campaign Status *</Typography>
                <AutoComplete
                  required
                  className={classes.inputField}
                  value={campaignStatus}
                  options={CampaignStatusType}
                  placeholder="Campaign Status"
                  onChange={value => setCampaignStatus(value)}
                />
              </Grid>

              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Start Date *
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Immutable field if the campaign has been started"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  disabled={startDate && moment(startDate).isSameOrBefore(moment())}
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={onStartDateChange}
                  inputProps={{
                    'aria-label': 'startDate',
                  }}
                />
              </Grid>
              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  End Date *
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Immutable field if the campaign has been ended"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  disabled={endDate && moment(endDate).isSameOrBefore(moment())}
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="date"
                  name="startDate"
                  value={endDate}
                  onChange={onEndDateChange}
                  inputProps={{
                    'aria-label': 'endDate',
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Advertising Channel Type
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Immutable field"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <AutoComplete
                  isDisabled
                  className={classes.inputField}
                  value={advertisingChannel}
                  placeholder="Advertising Channel"
                />
              </Grid>
              {(isSearch() || isDisplay() || isMultiChannel()) && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="target_google_search"
                        name="target_google_search"
                        value={targetGoogleSearch}
                        checked={targetGoogleSearch}
                        disabled
                      />
                    }
                    label={
                      <Typography className={classes.checkboxLabel}>
                        Target Google Search
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}
              {isMultiChannel() && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="target_search_network"
                        name="target_search_network"
                        value={targetSearchNetwork}
                        checked={targetSearchNetwork}
                        disabled
                      />
                    }
                    label={
                      <Typography className={classes.checkboxLabel}>
                        Target Search Network
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}
              {(isDisplay() || isMultiChannel()) && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="target_content_network"
                        name="target_content_network"
                        value={targetContentNetwork}
                        checked={targetContentNetwork}
                        disabled
                      />
                    }
                    label={
                      <Typography className={classes.checkboxLabel}>
                        Target Content Network
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}

              {isMultiChannel() && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="target_partner_search_network"
                        name="target_partner_search_network"
                        value={targetPartnerSearchNetwork}
                        checked={targetPartnerSearchNetwork}
                        disabled
                      />
                    }
                    label={
                      <Typography className={classes.checkboxLabel}>
                        Target Partner Search Network
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}
              {isMultiChannel() && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <Typography className={classes.label}>
                    Advertising Channel Sub Type
                    <CustomTooltip
                      disableFocusListener
                      disableTouchListener
                      title="Immutable field"
                      placement="top"
                      className={classes.tooltip}
                    >
                      <InfoOutlinedIcon className={classes.icon} />
                    </CustomTooltip>
                  </Typography>
                  <AutoComplete
                    isDisabled
                    className={classes.inputField}
                    value={advertisingChannelSubType}
                    placeholder="Advertising Channel Sub Type"
                  />
                </Grid>
              )}
              {isShopping() && (
                <>
                  <Grid item sm={12} className={classes.inputGrid}>
                    <Typography className={classes.label}>
                      Merchant Center ID *
                      <CustomTooltip
                        disableFocusListener
                        disableTouchListener
                        title="Merchant Center ID"
                        placement="top"
                        className={classes.tooltip}
                      >
                        <InfoOutlinedIcon className={classes.icon} />
                      </CustomTooltip>
                    </Typography>
                    <InputTextField
                      withValidator
                      shouldValidateOnBlur={true}
                      required
                      type="string"
                      name="merchantCenterId"
                      value={merchantCenterId}
                      onChange={onMerchantCenterIdChange}
                      inputComponent={NumberFormatInput}
                      inputProps={{
                        maxLength: 18,
                        thousandSeparator: false,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} className={classes.inputGrid}>
                    <Typography className={classes.label}>
                      Sales Country
                      <CustomTooltip
                        disableFocusListener
                        disableTouchListener
                        title="Tooltip Text"
                        placement="top"
                        className={classes.tooltip}
                      >
                        <InfoOutlinedIcon className={classes.icon} />
                      </CustomTooltip>
                    </Typography>
                    <AutoComplete
                      disabled
                      className={classes.inputField}
                      value={salesCountry}
                      options={SalesCountryOptions}
                      placeholder="Advertising Channel"
                    />
                  </Grid>
                  <Grid item sm={12} className={classes.inputGrid}>
                    <FormControlLabel
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          id="local-inventory"
                          name="local-inventory"
                          value={enableLocal}
                          checked={enableLocal}
                          onChange={e => setEnableLocal(!enableLocal)}
                        />
                      }
                      label={
                        <Typography className={classes.checkboxLabel}>
                          Local Inventory Ads
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </Grid>
                </>
              )}

              <CreateCampaignBiddingConfiguration
                biddingStrategy={biddingStrategy}
                setBiddingStrategy={setBiddingStrategy}
                manualTargetCPA={manualTargetCPA}
                setManualTargetCPA={setManualTargetCPA}
                manualTargetROAS={manualTargetROAS}
                setManualTargetROAS={setManualTargetROAS}
                targetCPA={targetCPA}
                setTargetCPA={setTargetCPA}
                targetROAS={targetROAS}
                setTargetROAS={setTargetROAS}
                isShopping={isShopping}
                isDisplay={isDisplay}
                onBiddingStrategyChange={onBiddingStrategyChange}
                portfolioStrategy={portfolioStrategy}
                setPortfolioStrategy={setPortfolioStrategy}
                newPortfolioStrategyName={newPortfolioStrategyName}
                setNewPortfolioStrategyName={setNewPortfolioStrategyName}
                createNewPortfolioBidding={createNewPortfolioBidding}
                setCreateNewPortfolioBidding={setCreateNewPortfolioBidding}
              />

              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Budget Name *
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Immutable field if isn't explicitly shared"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  disabled={!isExplicitlyShared}
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="text"
                  name="name"
                  value={budgetName}
                  onChange={onBudgetNameChange}
                  inputProps={{
                    maxLength: 40,
                    'aria-label': 'name',
                  }}
                />
              </Grid>
              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Budget Amount *
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title={"Immutable field if isn't explicitly shared"}
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  disabled={!isExplicitlyShared}
                  withValidator
                  shouldValidateOnBlur={true}
                  type="string"
                  name="budgetAmount"
                  value={budgetAmount}
                  onChange={onBudgetAmountChange}
                  placeholder="$"
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    prefix: '$',
                    maxLength: 18,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      id="is-explicitly-shared"
                      name="is-explicitly-shared"
                      value={isExplicitlyShared}
                      checked={isExplicitlyShared}
                      onChange={e => setIsExplicitlyShared(!isExplicitlyShared)}
                    />
                  }
                  label={
                    <Typography className={classes.checkboxLabel}>Is Explicitly Shared</Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
              {biddingStrategy?.value === 'MANUAL_CPC' && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="is-explicitly-shared"
                        name="is-explicitly-shared"
                        value={enhancedCpcEnabled}
                        checked={enhancedCpcEnabled}
                        onChange={e => setEnhancedCpcEnabled(!enhancedCpcEnabled)}
                      />
                    }
                    label={
                      <Typography className={classes.checkboxLabel}>
                        Enhanced CPC Enabled
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}
              {/* <Grid item sm={12} className={classes.inputGrid}>
              <Typography className={classes.label}>
                App Id
                <CustomTooltip
                  disableFocusListener
                  disableTouchListener
                  title="Immutable field"
                  placement="top"
                  className={classes.tooltip}
                >
                  <InfoOutlinedIcon className={classes.icon} />
                </CustomTooltip>
              </Typography>
              <InputTextField
                disabled
                withValidator
                shouldValidateOnBlur={true}
                type="text"
                name="app Id"
                value={appId}
                inputProps={{
                  maxLength: 40,
                  'aria-label': 'name',
                }}
              />
            </Grid> */}
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Ad Groups Type
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Immutable field"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <AutoComplete
                  isDisabled
                  className={classes.inputField}
                  value={adGroupType}
                  placeholder="Ad Group"
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Tracking Templates Type
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Immutable field"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <AutoComplete
                  isDisabled
                  className={classes.inputField}
                  value={trackingTemplatesType}
                  placeholder="Tracking Templates Type"
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Tracking Url Template
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Immutable field"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  disabled
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="name"
                  value={trackingUrlTemplate}
                  inputProps={{
                    maxLength: 500,
                    'aria-label': 'name',
                    style: {color: '#040D14'},
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Callout Extension
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="Immutable field"
                    placement="top"
                    className={classes.tooltip}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  disabled
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="name"
                  value={calloutExtension}
                  inputProps={{
                    maxLength: 500,
                    'aria-label': 'name',
                    style: {color: '#040D14'},
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>Region</Typography>
                <LocationsSelect required value={locations} onChange={onLocationChange} />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>Negative Keywords</Typography>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="negative_keywords"
                  value={negativeKeywords}
                  onChange={onNegativeKeywordsChange}
                  inputProps={{
                    maxLength: 40,
                    'aria-label': 'negative_keywords',
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>Language</Typography>
                <AutoComplete
                  disabled
                  className={classes.inputField}
                  value={language}
                  onChange={onLanguageChange}
                  options={languages}
                  placeholder="Language"
                />
              </Grid>
            </Grid>

            <Grid item sm={12} className={classes.buttons}>
              <Grid item sm={12} className={classes.center}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className={classes.continueButton}
                  onClick={handleSubmit}
                  disabled={!canSubmit() || isEditing}
                >
                  {isEditing ? 'Updating...' : 'Edit Campaign'}
                </Button>
              </Grid>
              {!isEditing && (
                <>
                  <Grid item sm={12} className={classes.center}>
                    <Button size="small" className={classes.cancelButton} onClick={handleClose}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item sm={12} className={classes.center}>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.removeButton}
                      onClick={handleRemove}
                    >
                      Remove Campaign
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
    schedule: state.schedule,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess, createError}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(EditCampaignDialog)
