import {TextField} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'

export const styles = theme => ({
  selectPlanText: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '45px',
    textAlign: 'center',
  },
  paymentContainer: {
    display: 'grid',
    gap: '20px',
  },
  paymentContainerSignup: {
    padding: '16px 0 0 0',
    display: 'grid',
    gap: '20px',
  },
  paymentButtonSignupDiv: {
    padding: 6,
  },
  paymentButtonSignup: {
    border: '1px solid #E7873C',
    boxShadow: '0px 2px 12px -2px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    color: '#ffffff',
    backgroundColor: '#D88A48',
    margin: '0 auto',
    width: '100%',
    '&:hover': {
      backgroundColor: '#F58220',
    },
    textTransform: 'uppercase',
  },
  paymentButton: {
    border: '1px solid #E7873C',
    boxShadow: '0px 2px 12px -2px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    color: '#ffffff',
    backgroundColor: '#D88A48',
    width: '395px',
    '&:hover': {
      backgroundColor: '#F58220',
    },
    textTransform: 'none',
  },
  paymentPaper: {
    position: 'absolute',
    width: 500,
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    padding: 5,
    top: `50%`,
    left: `45%`,
    transform: `translate(-${25}%, -${50}%)`,
  },
  paymentPaperSignup: {
    width: 500,
    margin: '0 auto',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    padding: 5,
  },
  formRow: {
    // fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    margin: '24px 15px 8px',
  },
  formRowGrid: {
    // fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    margin: '4px 0 4px',
  },

  formGroup: {
    margin: '4px 15px 4px',
    padding: 15,
    border: ' 1px solid #C4C4C4',
    backgroundColor: '#ffffff',
    willChange: 'opacity, transform',
    borderRadius: '5px',
  },
  textField: {
    padding: '8px 15px 8px',
  },
  buttonField: {padding: '2px 15px 2px', display: 'flex'},
  submitButton: {
    border: '1px solid #E7873C',
    boxShadow: '0px 2px 12px -2px rgba(0, 0, 0, 0.25)',
    borderRadius: '6px',
    color: '#ffffff',
    backgroundColor: '#E7873C',
    width: '100%',
    '&:hover': {
      backgroundColor: '#F58220',
    },
    margin: '0 auto',
    textTransform: 'none',
  },
  addPaymentMethodModal: {
    overflowY: 'auto',
  },
  makePrimaryButton: {
    color: '#ffffff',
    backgroundColor: '#02766c',
    '&:hover': {
      backgroundColor: '#02766c',
      opacity: '70%',
    },
  },
  deleteButton: {
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#D84848',
      opacity: '70%',
    },
    backgroundColor: '#D84848',
  },
  cardIcon: {
    width: 38,
    height: 24,
  },
  defaultIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  itemText: {
    // fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '21px',
    lineHeight: '100%',
    textAlign: 'center',
  },
  editIconContainer: {textAlign: 'center'},
  editIcon: {
    fontSize: 20,
    color: '#000000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardItem: {
    '&:hover': {
      opacity: '70%',
    },
  },
})
export const CustomTextField = withStyles({
  root: {
    margin: '4px 0px 8px',
  },
})(TextField)

import {useStyles, Title, BodyText, StyledButton} from '../CancelModal/styles'

export {useStyles, Title, BodyText, StyledButton}
