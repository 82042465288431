import React from 'react'
import {Grid, Typography, withWidth} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {withRouter} from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import NoCardAvailableIcon from '../../../assets/icons/NoCardAvailableIcon.svg'
import AmExCardIcon from '../../../assets/icons/AmExCardIcon.svg'
import DiscoverCardIcon from '../../../assets/icons/DiscoverCardIcon.svg'
import MasterCardIcon from '../../../assets/icons/MasterCardIcon.svg'
import VisaCardIcon from '../../../assets/icons/VisaCardIcon.svg'
import {createNotificationFromError, createSuccess} from '../../common/redux/actions.notifications'
import {styles} from './styles'

function PaymentMethods({
  classes,
  customer,
  setAddPaymentMethodOpen,
  setEditPaymentMethod,
  customerPaymentMethods,
}) {
  const handleOnEdit = paymentMethod => {
    setEditPaymentMethod(paymentMethod)
    setAddPaymentMethodOpen(true)
  }

  const getCardIcon = brand => {
    switch (brand) {
      case 'visa':
        return VisaCardIcon
      case 'amex':
        return AmExCardIcon
      case 'discover':
        return DiscoverCardIcon
      case 'mastercard':
        return MasterCardIcon
      default:
        return NoCardAvailableIcon
    }
  }

  return (
    <Grid container className={classes.paymentContainer}>
      {customerPaymentMethods &&
        customerPaymentMethods.length > 0 &&
        customerPaymentMethods.map((paymentMethod, index) => {
          const icon = getCardIcon(paymentMethod.card.brand)

          if (paymentMethod.metadata && paymentMethod.metadata.sourceId) {
            return (
              <Grid container className={classes.cardItem} key={index}>
                <Grid item xs={1} lg={1}>
                  <img src={icon} className={classes.cardIcon} alt={index} />
                </Grid>
                <Grid item xs={9} lg={9}>
                  <Typography variant="body2" className={classes.itemText}>
                    •••• •••• •••• {paymentMethod.card.last4}
                  </Typography>
                </Grid>
                <Grid item xs={1} lg={1} className={classes.editIconContainer}>
                  <EditIcon
                    onClick={() => handleOnEdit(paymentMethod)}
                    className={classes.editIcon}
                  />
                </Grid>
                {customer.defaultPaymentMethod.id === paymentMethod.id && (
                  <Grid item xs={1} lg={1}>
                    <CheckCircleIcon className={classes.defaultIcon} />
                  </Grid>
                )}
              </Grid>
            )
          }
          return <Grid container key={index} style={{display: 'none'}} />
        })}
    </Grid>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
  }
}

PaymentMethods.propTypes = {}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess}, dispatch)
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withRouter,
  withStyles(styles),
)(PaymentMethods)
