import {Paper, Typography, Button} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'

export const styles = () => ({
  cardInnerContainer: {
    padding: '16 16 8',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1.5fr 1.2fr 5fr 1fr .7fr',
  },
  checkIcon: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '100%',
    marginRight: 6,
    color: '#6A6A6A',
    justifyContent: 'center',
  },
  plan: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    color: '#000000',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    color: '#6A6A6A',
  },
  cancelButton: {
    textTransform: 'none',
    justifySelf: 'center',
    textDecoration: 'underline',
    borderRadius: 50,
    color: '#6A6A6A',
    fontSize: '12px',
  },
  group: {},
})

export const PlanCardPaper = withStyles({
  root: {
    boxShadow: '0px 4px 44px -21px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    backgroundColor: '#fff',
    width: 310,
    height: 440,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 20px',
    margin: '10px',
  },
})(Paper)

export const ChooseButton = withStyles({
  root: {
    backgroundColor: '#DDBD63',
    borderRadius: '38px',
    height: 46,
    width: '80%',
    textAlign: 'center',
    margin: '0 auto',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#DDBD63',
      opacity: '70%',
    },
  },
  disabled: {
    backgroundColor: '#ffffff',
  },
})(Button)
