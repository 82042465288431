export const CampaignStatusType = [
  {
    value: 'PAUSED',
    label: 'Paused',
  },
  {
    value: 'ENABLED',
    label: 'Enabled',
  },
]

export const BiddingStrategyType = [
  {
    value: 'MANUAL_CPC',
    label: 'MANUAL_CPC',
    description: 'Manual click based bidding where user pays per click.',
  },
  // {
  //   value: 'MANUAL_CPM',
  //   label: 'MANUAL_CPM',
  //   description: 'Manual impression based bidding where user pays per thousand impressions.',
  // },
  {
    value: 'TARGET_SPEND',
    label: 'TARGET_SPEND',

    description:
      'Target Spend (Maximize Clicks) is an automated bid strategy that sets your bids to help get as many clicks as possible within your budget.',
  },
  {
    value: 'TARGET_CPA',
    label: 'TARGET_CPA',
    description:
      'Target CPA is an automated bid strategy that sets bids to help get as many conversions as possible at the target cost per acquisition (CPA) you set.',
  },
  {
    value: 'TARGET_ROAS',
    label: 'TARGET_ROAS',
    description:
      'Target ROAS is an automated bidding strategy that helps you maximize revenue while averaging a specific target return on average spend (ROAS).',
  },
  {
    value: 'MAXIMIZE_CONVERSIONS',
    label: 'MAXIMIZE_CONVERSIONS',
    description:
      'Maximize conversions is an automated bidding strategy that automatically sets bids to help get the most conversions for your campaign while spending your budget.',
  },
  // {
  //   value: 'MAXIMIZE_CONVERSION_VALUE',
  //   label: 'MAXIMIZE_CONVERSION_VALUE',
  //   description:
  //     'Maximize conversion value is an automated bidding strategy that automatically sets bids to maximize revenue while spending your budget.',
  // },
  // {
  //   value: 'TARGET_OUTRANK_SHARE',
  //   label: 'TARGET_OUTRANK_SHARE',
  //   description:
  //     'Target Outrank Share is an automated bidding strategy that sets bids based on the target fraction of auctions where the advertiser should outrank a specific competitor.',
  // },
  // {
  //   value: 'NONE',
  //   label: 'NONE',
  //   description:
  //     'Special bidding strategy type used to reset the bidding strategy at AdGroup and AdGroupCriterion.',
  // },
  // {
  //   value: 'UNKNOWN',
  //   label: 'UNKNOWN',
  //   description: '',
  // },
]

export const AdvertisingChannelEnum = {
  SEARCH: 'SEARCH',
  DISPLAY: 'DISPLAY',
  SHOPPING: 'SHOPPING',
  MULTI_CHANNEL: 'MULTI_CHANNEL',
}

export const AdvertisingChannelType = [
  {
    value: 'SEARCH',
    label: 'SEARCH',
    description: 'Search Network. Includes display bundled, and Search+ campaigns.',
  },
  {value: 'DISPLAY', label: 'DISPLAY', description: 'Google Display Network only.'},
  {
    value: 'SHOPPING',
    label: 'SHOPPING',
    description:
      'Shopping campaigns serve on the shopping property and on google.com search results.',
  },
  // {value: 'HOTEL', label: 'HOTEL', description: 'Hotel Ads campaigns.'},
  // {value: 'VIDEO', label: 'VIDEO', description: 'Video campaigns.'},
  // {
  //   value: 'MULTI_CHANNEL',
  //   label: 'MULTI_CHANNEL',
  //   description:
  //     'App Campaigns, and App Campaigns for Engagement, that run across multiple channels.',
  // },
  // {value: 'LOCAL', label: 'LOCAL', description: 'Local ads campaigns.'},
  // {value: 'SMART', label: 'SMART', description: 'Smart campaigns'},
]

export const AdCreationEnum = {
  ExpandedTextAd: 'ExpandedTextAd',
  ExpandedDynamicSearchAd: 'ExpandedDynamicSearchAd',
  CallAdInfo: 'CallAdInfo',
}

export const AdCreationType = [
  {value: 'ExpandedTextAd', label: 'ExpandedTextAd'},
  {value: 'ExpandedDynamicSearchAd', label: 'ExpandedDynamicSearchAd'},
  {value: 'CallAdInfo', label: 'CallAdInfo'},
]

export const AdGroupType = [
  {
    value: 'SEARCH_STANDARD',
    label: 'SEARCH_STANDARD',
    description: 'Default AdGroup type for Search Campaigns',
  },
  // {
  //   value: 'SEARCH_DYNAMIC_ADS',
  //   label: 'SEARCH_DYNAMIC_ADS',
  //   description: 'AdGroup type for Dynamic Search Ads campaigns.',
  // },
  {
    value: 'DISPLAY_STANDARD',
    label: 'DISPLAY_STANDARD',
    description: 'Default AdGroup type for Display Campaigns',
  },
  {
    value: 'SHOPPING_PRODUCT_ADS',
    label: 'SHOPPING_PRODUCT_ADS',
    description: 'Default AdGroup type for Shopping Campaigns serving standard products ads.',
  },
  // {
  //   value: 'SHOPPING_SHOWCASE_ADS',
  //   label: 'SHOPPING_SHOWCASE_ADS',
  //   description: 'AdGroups limited to serving Showcase/Merchant ads in shopping results.',
  // },
  // {
  //   value: 'SHOPPING_GOAL_OPTIMIZED_ADS',
  //   label: 'SHOPPING_GOAL_OPTIMIZED_ADS',
  //   description: 'Ad group type for Smart Shopping Campaigns.',
  // },
]

export const SalesCountryOptions = [
  {
    value: 'US',
    label: 'United States',
  },
]

export const AdvertisingChannelSubType = [
  {value: 'SEARCH_MOBILE_APP', label: 'SEARCH_MOBILE_APP'},
  {value: 'DISPLAY_MOBILE_APP', label: 'DISPLAY_MOBILE_APP'},
  {value: 'SEARCH_EXPRESS', label: 'SEARCH_EXPRESS'},
  {value: 'DISPLAY_EXPRESS', label: 'DISPLAY_EXPRESS'},
  {value: 'SHOPPING_SMART_ADS', label: 'SHOPPING_SMART_ADS'},
  {value: 'DISPLAY_GMAIL_AD', label: 'DISPLAY_GMAIL_AD'},
  {value: 'DISPLAY_SMART_CAMPAIGN', label: 'DISPLAY_SMART_CAMPAIGN'},
  {value: 'VIDEO_OUTSTREAM', label: 'VIDEO_OUTSTREAM'},
  {value: 'VIDEO_ACTION', label: 'VIDEO_ACTION'},
  {value: 'VIDEO_NON_SKIPPABLE', label: 'VIDEO_NON_SKIPPABLE'},
  {value: 'APP_CAMPAIGN', label: 'APP_CAMPAIGN'},
  {value: 'APP_CAMPAIGN_FOR_ENGAGEMENT', label: 'APP_CAMPAIGN_FOR_ENGAGEMENT'},
  {value: 'LOCAL_CAMPAIGN', label: 'LOCAL_CAMPAIGN'},
  {value: 'SHOPPING_COMPARISON_LISTING_ADS', label: 'SHOPPING_COMPARISON_LISTING_ADS'},
]

export const TrackingTemplatesType = [
  {value: 'account', label: 'Account'},
  {value: 'campaign', label: 'Campaign'},
]

export const Devices = [
  {value: 'DESKTOP', label: 'DESKTOP'},
  {value: 'MOBILE', label: 'MOBILE'},
  {value: 'TABLET', label: 'TABLET'},
]

export const Level = [
  {value: 'campaign', label: 'Campaign'},
  {value: 'ad_group', label: 'Ad Group'},
]

export const ConversionTrackingTypes = [
  {value: 'WEBPAGE', label: 'WEBPAGE'},
  {value: 'WEBPAGE_ONCLICK', label: 'WEBPAGE_ONCLICK'},
  {value: 'CLICK_TO_CALL', label: 'CLICK_TO_CALL'},
]
