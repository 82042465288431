import React, {useMemo, useState} from 'react'
import {CircularProgress, Grid, withWidth, OutlinedInput} from '@material-ui/core'
import {withStyles, useTheme} from '@material-ui/styles'
import {withRouter} from 'react-router'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import {CardElement} from '@stripe/react-stripe-js'
import {createNotificationFromError, createSuccess} from '../../common/redux/actions.notifications'
import {styles} from './styles'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import InputValidator from '../../common/InputValidator'

function PaymentMethod({
  classes,
  stripe,
  setBillingDetails,
  billingDetails,
  setError,
  error,
  isLoading,
  isComponentValid,
  handleSubmit,
  elements,
}) {
  const theme = useTheme()
  const useOptions = () => {
    const fontSize = 14
    return useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: theme.palette.Gray[900],
            fontFamily: 'Roboto, Source Code Pro, monospace',
            '::placeholder': {
              color: theme.palette.Gray[600],
              fontSize: '12px',
            },
          },
          invalid: {
            color: '#000000',
          },
        },
        hidePostalCode: true,
      }),
      [fontSize],
    )
  }

  const options = useOptions()

  if (!elements || !stripe) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return <CircularProgress />
  }

  return (
    <>
      <section className={classes.formWrapper}>
        <div className={classes.cardInfoLabel}>Card Information</div>
        <Grid item xs={12} style={{flexBasis: 0}}>
          <fieldset className={classes.cardElement}>
            <CardElement
              options={options}
              onChange={e => {
                setError(e.error)
              }}
            />
          </fieldset>
          <InputValidator canShowLabel={false} shouldValidateOnValueChange shouldValidateOnBlur>
            <OutlinedInput
              id="Name"
              variant="outlined"
              placeholder="Name on Card"
              size="small"
              name="name"
              className={classes.inputField}
              onChange={e => {
                setBillingDetails({...billingDetails, name: e.target.value})
              }}
              value={billingDetails.name}
              required
              fullWidth
            />
          </InputValidator>
        </Grid>
        <div className={classes.addressText}>Billing Address</div>
        <Grid item xs={12} style={{flexBasis: 0}}>
          <OutlinedInput
            id="line1"
            variant="outlined"
            placeholder="Address"
            size="small"
            name="line1"
            className={classes.inputField}
            onChange={e => {
              setBillingDetails({...billingDetails, line1: e.target.value})
            }}
            value={billingDetails.line1}
            required
            fullWidth
          />
        </Grid>

        <Grid container justify="space-between" item xs={12} style={{flex: '0 0 0%'}}>
          <Grid item xs={12}>
            <OutlinedInput
              id="city"
              variant="outlined"
              placeholder="City"
              size="small"
              name="city"
              className={classes.inputField}
              style={{marginRight: '8px'}}
              onChange={e => {
                setBillingDetails({...billingDetails, city: e.target.value})
              }}
              value={billingDetails.city}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container justify="space-between" item xs={12} style={{flexBasis: 0}}>
          <Grid
            item
            xs={6}
            style={{
              paddingRight: '8px',
            }}
          >
            <OutlinedInput
              id="state"
              variant="outlined"
              placeholder="State"
              size="small"
              name="state"
              className={classes.inputField}
              // style={{marginLeft: '8px'}}
              onChange={e => {
                setBillingDetails({...billingDetails, state: e.target.value})
              }}
              value={billingDetails.state}
              required
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              paddingLeft: '8px',
            }}
          >
            <OutlinedInput
              id="postal_code"
              variant="outlined"
              placeholder="Zip Code"
              size="small"
              name="postal_code"
              className={classes.inputField}
              // style={{marginLeft: '8px'}}
              onChange={e => {
                setBillingDetails({...billingDetails, postal_code: e.target.value})
              }}
              value={billingDetails.postal_code}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </section>
      <section className={classes.buttonWrapper}>
        <Grid item xs={12} style={{flex: '0 0 0%'}}>
          <ButtonWithProgress
            disabled={!billingDetails.name || error}
            type="submit"
            name="btn-create"
            fullWidth
            variant="contained"
            className={classes.submitButton}
            onClick={() => handleSubmit(stripe, elements)}
            isLoading={isLoading}
            loadingStyle={{color: theme.palette.Gray[200]}}
          >
            {!isLoading && 'Confirm Payment'}
          </ButtonWithProgress>
        </Grid>
      </section>
    </>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
  }
}

PaymentMethod.propTypes = {}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess}, dispatch)
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withRouter,
  withStyles(styles),
)(PaymentMethod)
