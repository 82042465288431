import React from 'react'
import {Dialog, DialogTitle, Grid, Button, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'

export default function EditConfirmationDialog({isOpen, onClickContinue, onClickCancel}) {
  const classes = useStyles()

  return (
    <Dialog
      aria-labelledby="edit-confirmation-dialog-title"
      open={isOpen}
      scroll="body"
      disableBackdropClick
      className={classes.dialog}
    >
      <Grid className={classes.container}>
        <Grid item sm={12} className={classes.center}>
          <DialogTitle
            id="edit-confirmation-dialog-title"
            className={classes.dialogTitle}
            disableTypography
          >
            Continue?
          </DialogTitle>
        </Grid>

        <Grid item sm={12} className={classes.center}>
          <Typography variant="subtitle1" className={classes.center}>
            These changes will create a new plan.
          </Typography>
          <Typography variant="body1" className={classes.center}>
            &nbsp;Are you ok with this?
          </Typography>
        </Grid>
        <div className={classes.buttons}>
          <Grid item sm={12} className={classes.center}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.continueButton}
              onClick={onClickContinue}
            >
              Continue to Update
            </Button>
          </Grid>
          <Grid item sm={12} className={classes.center}>
            <Button size="small" className={classes.cancelButton} onClick={onClickCancel}>
              Cancel
            </Button>
          </Grid>
        </div>
      </Grid>
    </Dialog>
  )
}

const useStyles = makeStyles({
  container: {
    padding: '8%',
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: 48,
    fontWeight: '600',
    color: '#00235e',
    paddingBottom: 0,
  },
  center: {
    textAlign: 'center',
    margin: '8px, 16px',
  },
  continueButton: {
    fontWeight: '600',
    textTransform: 'none',
    marginTop: '10%',
  },
  cancelButton: {
    marginTop: '8px',
    textTransform: 'capitalize',
  },
  buttons: {
    paddingTop: '8%',
  },
})
