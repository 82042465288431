import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import PropTypes from 'prop-types'
import {Map, TileLayer, GeoJSON, Tooltip, withLeaflet} from 'react-leaflet'
import Leaflet from 'leaflet'

const styles = {
  map: {
    minHeight: 285,
    flexGrow: 1,
  },
}

class GrowthResultsMap extends React.Component {
  render() {
    const {classes, plan, locations} = this.props

    let bounds
    if (plan.locations) {
      bounds = Leaflet.geoJSON(plan.locations).getBounds()

      const isBoundsEmpty = !bounds._northEast
      if (isBoundsEmpty) {
        bounds = null
      }
    }

    return (
      <Map
        key={plan.id}
        className={classes.map}
        bounds={bounds}
        keyboard={false}
        attributionControl={false}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
          opacity={0.5}
        />
        {locations.map(location => {
          if (!location.location.geometry) {
            return null
          }

          return (
            <GeoJSON
              key={location.id}
              data={location.location}
              pointToLayer={(feature, latlng) => {
                return Leaflet.circleMarker(latlng)
              }}
              style={() => {
                return {
                  color: '#ffffff',
                  weight: 2,
                  fillColor: location.color,
                  fillOpacity: 0.9,
                }
              }}
            >
              <Tooltip>{location.location.properties.name}</Tooltip>
            </GeoJSON>
          )
        })}
      </Map>
    )
  }
}

GrowthResultsMap.displayName = 'GrowthResultsMap'

GrowthResultsMap.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

function mapStateToProps() {
  return {}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true}),
  withLeaflet,
)(GrowthResultsMap)
