import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Paper, Typography, Grid, Box, Divider} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'

const colorMinHeight = 10
const colorMain = colorMinHeight * 4

const styles = theme => {
  return {
    paper: {
      padding: theme.spacing(3),
      margin: theme.spacing(1),
    },
    underline: {
      position: 'relative',
      textDecoration: 'none',
      '&:after': {
        content: '""',
        position: 'absolute',
        height: '3px',
        bottom: '-7px',
        margin: '0 auto',
        left: '0',
        right: '0',
        maxWidth: '6em',
        background: theme.palette.primary.dark,
      },
    },
    priMain: {
      background: theme.palette.primary.main,
      height: colorMain * 2,
    },
    priLight: {
      background: theme.palette.primary.light,
      height: colorMinHeight,
    },
    priDark: {
      background: theme.palette.primary.dark,
      height: colorMinHeight,
    },
    secMain: {
      background: theme.palette.secondary.main,
      height: colorMain,
    },
    secLight: {
      background: theme.palette.secondary.light,
      height: colorMinHeight,
    },
    secDark: {
      background: theme.palette.secondary.dark,
      height: colorMinHeight,
    },
    sucMain: {
      background: theme.palette.success.main,
      height: colorMinHeight,
    },
    warMain: {
      background: theme.palette.warning.main,
      height: colorMinHeight,
    },
    danMain: {
      background: theme.palette.danger.main,
      height: colorMinHeight,
    },
    infMain: {
      background: theme.palette.info.main,
      height: colorMinHeight,
    },
  }
}

class About extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <Grid container>
        <Grid item xs={12} md={12}>
          <Paper className={classes.paper}>
            <Box textAlign="center">
              <Typography variant="h5" className={classes.underline}>
                Welcome to BaseApp
              </Typography>
              <Typography variant="h1">This is your Style Guide</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.underline}>
              <Box textAlign="center">Typography</Box>
            </Typography>
            <Typography variant="h1" color="default">
              Headline / h1
            </Typography>
            <Typography variant="h2" color="default">
              Headline / h2
            </Typography>
            <Typography variant="h3" color="default">
              Headline / h3
            </Typography>
            <Typography variant="h4" color="default">
              Headline / h4
            </Typography>
            <Typography variant="h5" color="default">
              Headline / h5
            </Typography>
            <Typography variant="h6" color="default">
              Headline / h6
            </Typography>
            <Typography variant="subtitle1" color="default">
              Subtitle 1
            </Typography>
            <Typography variant="subtitle2" color="default">
              Subtitle 2
            </Typography>
            <Typography variant="body1" color="default">
              Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text.
              “It's not Latin, though it looks like it, and it actually says nothing,” Before &
              After magazine answered a curious reader, “Its ‘words’ loosely approximate the
              frequency with which letters occur in English, which is why at a glance it looks
              pretty real.”
            </Typography>
            <Typography variant="body1" color="default">
              In particular, the garbled words of lorem ipsum bear an unmistakable resemblance to
              sections 1.10.32–33 of Cicero's work, with the most notable passage excerpted below:
            </Typography>
            <Typography variant="body2" color="default">
              As Cicero would put it, “Um, not so fast.”
            </Typography>
            <Typography variant="body2" color="default">
              The placeholder text, beginning with the line “Lorem ipsum dolor sit amet, consectetur
              adipiscing elit”, looks like Latin because in its youth, centuries ago, it was Latin.
            </Typography>
            <Typography variant="caption" color="default">
              Caption
            </Typography>
            <br />
            <Typography variant="overline" color="default">
              Overline
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={3} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.underline}>
              <Box textAlign="center">Palette</Box>
            </Typography>
            <br />

            <Box className={classes.priMain} />
            <Box className={classes.priLight} />
            <Box className={classes.priDark} />
            <br />
            <Divider />
            <br />
            <Box className={classes.secMain} />
            <Box className={classes.secLight} />
            <Box className={classes.secDark} />
            <br />
            <Divider />
            <br />
            <Box className={classes.sucMain} />
            <Box className={classes.warMain} />
            <Box className={classes.danMain} />
            <br />
            <Divider />
            <br />
            <Box className={classes.infMain} />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

About.displayName = 'About'

export default compose(connect(), withStyles(styles, {withTheme: true}))(About)
