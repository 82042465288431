import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {ChooseButton, PlanCardPaper, styles} from './styles'

function Item({title, value = '0', prefix = '', complement = '', classes}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <CheckIcon className={classes.checkIcon} />
        <Typography className={classes.text}>{title}</Typography>
      </div>
      <Typography className={classes.text}>{prefix + value + complement}</Typography>
    </div>
  )
}

function TierPlanCard({
  tierPlan,
  isYearly,
  onClickChosen,
  onClickCancel,
  currentPlan,
  createNotificationFromError,
  router,
  classes,
  currentSubscriptionName,
}) {
  const formatPrice = string => Math.floor(parseFloat(string)).toLocaleString()

  // Additional users were hardcoded here untill we have setted this feature
  const getPerUserPrice = () => {
    if (tierPlan.name === 'PRO') return '45'
    if (tierPlan.name === 'BUSINESS') return '100'
    return 'N/A'
  }
  return (
    <Grid item>
      <PlanCardPaper>
        <div>
          <Typography className={classes.plan}>{tierPlan.name}</Typography>
          <Grid alignItems="flex-end" direction="row" justify="center" container>
            <Typography style={{fontSize: '48px'}}>
              {isYearly
                ? `$${formatPrice(tierPlan.yearlyPrice)}`
                : `$${formatPrice(tierPlan.monthlyPrice)}`}
            </Typography>
            <Typography style={{fontSize: '18px', marginBottom: '12px'}}>
              {isYearly ? '/year' : '/month'}
            </Typography>
          </Grid>
        </div>
        <div className={classes.group}>
          <Item
            classes={classes}
            title="Users (included)"
            value={tierPlan.name === 'ENTERPRISE' ? 'Unlimited' : '1'}
          />
          <Item
            classes={classes}
            title="Additional Users"
            value={getPerUserPrice()}
            complement={tierPlan.name !== 'ENTERPRISE' ? '/user' : ''}
          />
          <Item
            classes={classes}
            title="Monthly Searches"
            value={tierPlan.name === 'ENTERPRISE' ? 'Unlimited' : tierPlan.searchesPerMonth}
            complement={tierPlan.name === 'ENTERPRISE' ? '' : '/month'}
          />
          <Item classes={classes} title="Additional Searches" value="N/A" />
          <Item
            classes={classes}
            title="Monthly Price"
            value={formatPrice(tierPlan.monthlyPrice)}
            prefix="$"
            complement="/month"
          />
          <Item
            classes={classes}
            title="Yearly Price"
            value={formatPrice(tierPlan.yearlyPrice)}
            prefix="$"
            complement="/year"
          />
        </div>
        {isYearly ? (
          <ChooseButton
            disabled={tierPlan.name + '-Yearly' === currentSubscriptionName}
            onClick={() => onClickChosen(tierPlan)}
          >
            {currentPlan ? 'Change Plan' : 'Choose Plan'}
          </ChooseButton>
        ) : (
          <ChooseButton
            disabled={tierPlan.name + '-Monthly' === currentSubscriptionName}
            onClick={() => onClickChosen(tierPlan)}
          >
            {currentPlan ? 'Change Plan' : 'Choose Plan'}
          </ChooseButton>
        )}
      </PlanCardPaper>
    </Grid>
  )
}

export default compose(connect(null, null), withStyles(styles), withRouter)(TierPlanCard)
