import {withStyles} from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

export const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
  },
  title: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '45px',
    textAlign: 'center',
  },
  switch: {
    alignSelf: 'flex-end',
    marginBottom: '10%',
  },
  switchLabel: {
    '&:hover': {
      cursor: 'pointer',
      opacity: '70%',
    },
  },
  cancelButton: {
    textTransform: 'none',
    justifySelf: 'center',
    textAlign: 'center',
    textDecoration: 'underline',
    borderRadius: 50,
    color: '#6A6A6A',
    fontSize: '12px',
  },
})

export const SwitchButton = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.primary.main,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)
