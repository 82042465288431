import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {connect} from 'react-redux'
import _ from 'lodash'
import Helmet from 'react-helmet'
import Header from './common/Header.jsx'
import NotificationContainer from './common/NotificationContainer.jsx'
import {getProfile} from './profile/redux/actions'
import UserSession from './auth/UserSession'
import ImageUtils from './utils/ImageUtils'
import PageUtils from './utils/PageUtils'
import {GridContainerPage, GridItemPage} from './App.styles.js'
import FailedPaymentBanner from './FailedPaymentBanner'

const headerlessLocations = ['confirm-email']
const footerlessLocations = ['confirm-email']

class App extends React.Component {
  constructor() {
    super()

    this.state = {
      showHeader: true,
      showFooter: true,
    }
  }

  componentWillMount() {
    if (UserSession.getToken() && !this.props.profile) {
      this.props.dispatch(getProfile())
    }
  }

  componentDidMount() {
    this.setHeaderFooterState(this.props.location)

    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById('jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }

  componentWillReceiveProps(nextProps) {
    const hasLocationChanged =
      _.get(nextProps, 'location.pathname') !== _.get(this.props, 'location.pathname')
    if (hasLocationChanged) {
      this.setHeaderFooterState(nextProps.location)
    }
  }

  setHeaderFooterState(location) {
    const showHeader = headerlessLocations.indexOf(location.pathname.split('/')[1]) < 0
    const showFooter = footerlessLocations.indexOf(location.pathname.split('/')[1]) < 0

    if (showHeader !== this.state.showHeader || showFooter !== this.state.showFooter) {
      this.setState({showHeader, showFooter})
    }
  }

  render() {
    const {auth, profile} = this.props
    const {showHeader} = this.state
    return (
      <GridContainerPage
        id="appContain"
        container
        direction="column"
        alignItems="center"
        flex="true"
      >
        <CssBaseline />
        <Helmet
          defaultTitle={PageUtils.getTitle()}
          titleTemplate={`${PageUtils.getTitle()} - %s`}
          meta={[
            {name: 'twitter:card', content: 'summary'},
            {
              property: 'og:title',
              content:
                'Demand Validation, Revenue & Profitability Forecasting, Breakeven Analysis, Growth Plan Engine | FinJoy',
            },
            {property: 'og:site_name', content: PageUtils.getTitle()},
            {property: 'og:image', content: ImageUtils.getShareImgUrl()},
            {
              property: 'og:description',
              content:
                'Your idea is only as valuable as your customer. How much is your idea worth? All your business growth tools in one place.',
            },
          ]}
        />
        {showHeader ? <Header auth={auth} profile={profile} onHomeClick={() => {}} /> : null}

        <GridItemPage justify="center" alignItems="center" container flex="true">
          {profile &&
            profile.ownerAccount &&
            profile.ownerAccount.showPaymentMethodActionBanner && <FailedPaymentBanner />}
          {this.props.children}
        </GridItemPage>
        <NotificationContainer />
      </GridContainerPage>
    )
  }
}

App.displayName = 'App'

function mapStateToProps(state) {
  return {
    auth: state.auth,
    profile: _.get(state.profile, 'profile', null),
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(App)
