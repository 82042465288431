import React from 'react'
import {makeStyles} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles(theme => {
  return {
    container: {
      marginTop: 0,
      marginRight: 'auto',
      marginBottom: 0,
      marginLeft: 'auto',
      padding: '0 20px',
      width: '100%',
    },
  }
})

function FailedPaymentBanner() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Alert severity="error">
        Your payment method needs to be updated. You will not be able to create new Growth Plans
        until you update.
      </Alert>
    </div>
  )
}

export default FailedPaymentBanner
