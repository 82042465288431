import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Paper, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'

const styles = theme => {
  return {
    paper: {
      margin: theme.spacing(10, 10),
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: '32.5rem',
      padding: theme.spacing(10, 1),
      textAlign: 'center',
    },
  }
}

class Privacy extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <Paper className={classes.paper}>
        <Typography variant="h5">Welcome to BaseApp</Typography>
        <Typography variant="h1">Privacy Page</Typography>
      </Paper>
    )
  }
}

Privacy.displayName = 'Privacy'

export default compose(connect(), withStyles(styles, {withTheme: true}))(Privacy)
