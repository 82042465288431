import settings from '../../../config/settings'
import axios from '../api/axios'

const {apiBaseURL} = settings

export default class BillingApi {
  static addPaymentMethod(params) {
    return axios.post(`${apiBaseURL}/stripe-payments/create-payment-method`, params)
  }

  static getCustomer() {
    return axios.get(
      `${apiBaseURL}/stripe-payments/get-customer-method?expand=default_payment_method`,
    )
  }

  static getCustomerPaymentMethods() {
    return axios.get(`${apiBaseURL}/stripe-payments/get-customer-payment-methods`)
  }

  static subscribe(params) {
    return axios.post(`${apiBaseURL}/stripe-payments/start-subscription`, params)
  }

  static editPaymentMethod(params) {
    return axios.put(`${apiBaseURL}/stripe-payments/edit-payment-method`, params)
  }

  static deletePaymentMethod(params) {
    return axios.delete(`${apiBaseURL}/stripe-payments/delete-payment-method`, {params})
  }

  static makePrimaryPaymentMethod(params) {
    return axios.post(`${apiBaseURL}/stripe-payments/make-primary-payment-method`, params)
  }

  static getTierPlans(params) {
    return axios.get(`${apiBaseURL}/tier-plans`, {params})
  }

  static cancelSubscription() {
    return axios.post(`${apiBaseURL}/stripe-payments/cancel-subscription`)
  }

  static updateSubscription(params) {
    return axios.post(`${apiBaseURL}/stripe-payments/update-subscription`, params)
  }
}
