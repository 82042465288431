import React from 'react'

import {ChooseButton} from '../TierPlanCard/styles'

export default function SubmitButton({
  processing,
  error,
  children,
  disabled,
  className,
  submit = false,
  style = {},
  onClick = () => null,
}) {
  return (
    <ChooseButton
      variant="contained"
      type={submit ? 'submit' : 'button'}
      disabled={processing || disabled}
      onClick={onClick}
      className={className}
      style={style}
    >
      {processing ? 'Processing...' : children}
    </ChooseButton>
  )
}
