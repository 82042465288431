import _ from 'lodash'
import {NotificationActionTypes, NotificationType} from './actions.notifications.types'
import {createErrorNotificationMessage} from '../../utils/ErrorUtils'

export function create(type, message) {
  return {
    type: NotificationActionTypes.CREATE_NOTIFICATION,
    notification: {
      id: _.uniqueId(),
      message,
      type,
    },
  }
}

export function createError(message) {
  return {
    type: NotificationActionTypes.CREATE_NOTIFICATION,
    notification: {
      id: _.uniqueId(),
      message,
      type: NotificationType.error,
    },
  }
}

export function createSuccess(message) {
  return {
    type: NotificationActionTypes.CREATE_NOTIFICATION,
    notification: {
      id: _.uniqueId(),
      message,
      type: NotificationType.success,
    },
  }
}

export function createInfo(message) {
  return {
    type: NotificationActionTypes.CREATE_NOTIFICATION,
    notification: {
      id: _.uniqueId(),
      message,
      type: NotificationType.information,
      centered: true,
    },
  }
}

export function dismiss(notification) {
  return {
    type: NotificationActionTypes.DISMISS_NOTIFICATION,
    notification,
  }
}

export function createNotificationFromError(err) {
  const message = createErrorNotificationMessage(err)
  if (message) {
    return createError(message)
  }
}
