import {useEffect} from 'react'
import axios from 'axios'
import humps from 'humps'
import qs from 'qs'
import {makeUseAxios} from 'axios-hooks'
// import {useDispatch} from 'react-redux'
import UserSession from '../auth/UserSession'
import {createErrorNotification} from '../common/redux/sagas.errors.js'
import settings from '../../../config/settings'

function createAxiosInstance(file = false) {
  const instance = axios.create({
    baseURL: settings.apiBaseURL,
    paramsSerializer(params) {
      return qs.stringify(params, {arrayFormat: 'repeat'})
    },
  })

  if (!file) {
    instance.defaults.headers.post['Content-Type'] = 'application/json'
    instance.defaults.headers.patch['Content-Type'] = 'application/json'
    instance.defaults.headers.put['Content-Type'] = 'application/json'
  } else {
    instance.defaults.headers.post['Content-Type'] = 'multipart/form-data'
    instance.defaults.headers.patch['Content-Type'] = 'multipart/form-data'
    instance.defaults.headers.put['Content-Type'] = 'multipart/form-data'
  }

  instance.interceptors.request.use(request => {
    const authToken = UserSession.getToken()
    if (authToken) {
      if (request.headers && !request.headers.Authorization) {
        request.headers.Authorization = `Token ${authToken}`
      }
    }

    if (request.data && !file) {
      request.data = JSON.stringify(humps.decamelizeKeys(request.data))
    }

    return request
  })

  return instance
}

const hookInstance = createAxiosInstance()
hookInstance.interceptors.response.use(response => {
  if (response.data) {
    response.data = humps.camelizeKeys(response.data)
  }
  return response
})

const hookInstanceForFiles = createAxiosInstance(true)
hookInstanceForFiles.interceptors.response.use(response => {
  if (response.data) {
    response.data = humps.camelizeKeys(response.data)
  }
  return response
})

export const useAxios = makeUseAxios({
  axios: hookInstance,
})

export const useFileAxios = makeUseAxios({
  axios: hookInstanceForFiles,
  defaultOptions: {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
})

export const useAxiosWithErrorHandling = ({dispatch, isFile, ...config}, options) => {
  const [{data, loading, error, response}, execute, manualCancel] = !isFile
    ? useAxios(config, options)
    : useFileAxios(config, options)

  useEffect(() => {
    if (!loading && error) {
      const errorAction = createErrorNotification(error)
      if (errorAction) {
        dispatch(errorAction)
      }
    }
  }, [loading, error])

  return [{data, loading, error, response}, execute, manualCancel]
}

const reduxInstance = createAxiosInstance()
reduxInstance.interceptors.response.use(response => {
  if (response.data) {
    return humps.camelizeKeys(response.data)
  }
})

export default reduxInstance
