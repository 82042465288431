import React from 'react'
import {Paper, Grid, Modal, CircularProgress} from '@material-ui/core'

import {BodyText, Title, useStyles} from './styles'
import SubmitButton from '../common/SubmitButton'

export default function ConfirmDeleteModal({
  isOpen,
  onClose,
  onDelete,
  customer,
  isDeleting,
  paymentMethod,
}) {
  const classes = useStyles()
  let isDefaultPaymentMethod = false
  if (customer && customer.defaultPaymentMethod.id === paymentMethod.id) {
    isDefaultPaymentMethod = true
  }
  if (isDeleting) {
    return (
      <div style={{textAlign: 'center', marginTop: '50px'}}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Modal className={classes.modal} open={isOpen} onBackdropClick={onClose}>
        <Paper className={classes.paper}>
          <Grid item xs={12} align="center">
            <Title>Payment Method Delete Confirmation</Title>
          </Grid>
          <Grid item xs={12}>
            <BodyText style={{fontSize: '21px'}}>
              {isDefaultPaymentMethod
                ? 'To delete your primary credit card you must select or add a primary card'
                : 'Are you sure you want to delete this credit card?'}
            </BodyText>
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="stretch"
            className={classes.containerButton}
            alignContent="flex-end"
          >
            {isDeleting ? (
              <div style={{textAlign: 'center'}}>
                <CircularProgress />
              </div>
            ) : (
              <SubmitButton
                submit
                variant="contained"
                style={{
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#D84848',
                    opacity: '70%',
                  },
                  backgroundColor: '#D84848',
                }}
                color="primary"
                disabled={isDefaultPaymentMethod}
                onClick={() => {
                  onDelete(paymentMethod)
                }}
              >
                Delete
              </SubmitButton>
            )}
          </Grid>
        </Paper>
      </Modal>
    </div>
  )
}
