import React from 'react'
import {
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Paper,
  TableContainer,
  Divider,
  Typography,
} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import Loading from '../../common/Loading.jsx'
import styles from './GrowthReportsCampaign.styles'

function GrowthAdsCampaign({classes, report, isLoading}) {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1)
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer component={Paper} className={classes.reportsTable}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Ad</TableCell>
                <TableCell align="center">Ad Group</TableCell>
                <TableCell align="center">Ad Group Type</TableCell>
                <TableCell align="center">Ad Group Status</TableCell>
                <TableCell align="center">Clicks</TableCell>
                <TableCell align="center">Cost</TableCell>
                <TableCell align="center">Impr.</TableCell>
                <TableCell align="center">Conversions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {report.length > 0
                ? report.map(row => (
                    <TableRow key={row.name}>
                      <TableCell
                        align="center"
                        className={classes.reportsCellHeader}
                        component="th"
                        scope="row"
                      >
                        {row.type === 'EXPANDED_TEXT_AD' ? (
                          <>
                            <Typography className={classes.reportsCellHeader}>
                              {row.expandedTextAdHeadlinePart1} | {row.expandedTextAdHeadlinePart2}{' '}
                              | {row.expandedTextAdHeadlinePart3}
                            </Typography>
                            <Typography className={classes.reportDescriptionTextColor}>
                              {row.expandedTextAdDescription}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography className={classes.reportsCellHeader}>
                              Call {row.callAdPhoneNumber}
                            </Typography>
                            <Typography className={classes.reportSubTextColor}>
                              {row.callAdBusinessName} - {row.callAdHeadline1}.{' '}
                              {row.callAdHeadline2}
                            </Typography>
                            <Typography className={classes.reportDescriptionTextColor}>
                              {row.callAdDescription1}
                            </Typography>
                          </>
                        )}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {row.name}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {capitalize(
                          row.type
                            .toLowerCase()
                            .split('_')
                            .join(' '),
                        )}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {capitalize(row.status.toLowerCase())}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {row.metricClick}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {row.metricCostMicros}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {row.metricImpressions}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {row.metricConversions}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default withStyles(styles)(GrowthAdsCampaign)
