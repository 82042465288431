import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

function NumberFormatInput(props) {
  const {inputRef, onChange, ...other} = props

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      {...other}
    />
  )
}

NumberFormatInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default NumberFormatInput
