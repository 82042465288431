import {call, put, all, takeEvery} from 'redux-saga/effects'
import {push} from 'react-router-redux'
import PlanApi from './api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../common/redux/actions.notifications.js'
import * as ResponseActions from '../../common/redux/actions.responseStatus'

export default function* growthPlanFlow() {
  yield all([takeEvery(Types.SUBMIT_PLAN, submitPlan), takeEvery(Types.GET_PLAN, getPlan)])
}

function* submitPlan(action) {
  const {payload, callback, isEdit} = action
  try {
    let plan = ''
    if (isEdit) {
      plan = yield call(PlanApi.submitPlanEdit, payload.id, payload)
    } else plan = yield call(PlanApi.submitPlan, payload)
    yield put(Actions.submitPlanSuccess(plan))
    if (isEdit) put(NotificationActions.createSuccess('Updated Plan'))
    // else yield put(NotificationActions.createSuccess('Created Plan'))
    if (typeof callback === 'function') {
      callback(true, plan)
    } else {
      yield put(push(`/plan/${plan.id}`))
    }
  } catch (error) {
    yield put(NotificationActions.createError('Failed to submit Plan'))
    yield put(Actions.submitPlanFailure(error))
    if (typeof callback === 'function') {
      callback(false)
    }
  }
}

function* getPlan(action) {
  const {planId, params} = action
  try {
    const response = yield call(PlanApi.getPlan, planId, params)
    yield put(Actions.getPlanSuccess(response))
  } catch (error) {
    yield put(Actions.getPlanFailure(error))
    yield put(ResponseActions.setResponseStatus(404))
  }
}
