import imgLogo from '../../assets/logo.png'
import shareImg from '../../assets/home-eyecatcher.png'

export default class ImageUtils {
  static getAppLogoUrl() {
    return imgLogo
  }

  static getShareImgUrl() {
    return shareImg
  }
}
