export const styles = theme => ({
  container: {
    padding: '4%',
    width: 600,
    minHeight: 400,
  },
  label: {
    fontWeight: '600',
    paddingLeft: 8,
    fontSize: 18,
  },
  itemPadding: {
    marginTop: 25,
  },
  itemLabel: {
    fontWeight: '600',
    paddingLeft: 8,
    fontSize: 22,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  itemBorder: {padding: 10, borderBottom: '1px solid rgba(224, 224, 224, 1)'},
  dialogTitle: {
    textAlign: 'center',
    fontSize: 48,
    fontWeight: '600',
    color: '#00235e',
    paddingBottom: 0,
  },
  center: {
    textAlign: 'center',
    margin: '8px 16px',
  },
  continueButton: {
    fontWeight: '600',
    textTransform: 'none',
    marginTop: '10%',
  },
  bmAdd: {
    fontWeight: '600',
    textTransform: 'none',
    marginBottom: '10%',
  },
  bmCreate: {
    fontWeight: '600',
    textTransform: 'none',
  },
  removeButton: {
    fontWeight: '600',
    textTransform: 'none',
  },
  bmRemove: {
    fontWeight: '600',
    textTransform: 'none',
  },
  cancelButton: {
    marginTop: '8px',
    textTransform: 'capitalize',
  },
  buttons: {
    paddingTop: '10px',
  },
  icon: {
    margin: `0 4px`,
    width: '25px',
    color: '#979797',
    cursor: `pointer`,
  },
  inputGrid: {
    paddingLeft: '4px !important',
    width: '100%',
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  inputField: {
    margin: '8px',
    width: '100%',
  },
  checkboxLabel: {
    fontWeight: '600',
    fontSize: 18,
  },
  checkbox: {
    marginLeft: '0px',
    paddingLeft: '8px',
  },
  tooltip: {
    position: 'relative',
    top: 6,
    fill: '#979797',
  },
  customToolTip: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  portfolioSource: {
    flexDirection: 'row',
  },
  snippet: {
    width: '100%',
    minHeight: 150,
    margin: 8,
    resize: 'none',
  },
  inputGridKeywordAdd: {
    marginTop: 16,
    marginLeft: -8,
  },
})
