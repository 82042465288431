import React from 'react'
import {withStyles} from '@material-ui/styles'
import {compose} from 'redux'
import {connect} from 'react-redux'

const styles = theme => ({
  container: {
    marginTop: theme.spacing(3),
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    maxWidth: '62.5rem',
  },
})

function PlanHistory({children, classes}) {
  return <div className={classes.container}>{children}</div>
}

export default compose(connect(null, null), withStyles(styles))(PlanHistory)
