import React from 'react'
import {Paper, Grid, Modal, CircularProgress} from '@material-ui/core'

import {BodyText, Title, useStyles} from './styles'
import SubmitButton from '../common/SubmitButton'

export default function UpdateModal({
  isOpen,
  onClose,
  onClickConfirm,
  chosenPlan,
  customer,
  isSubscribing,
  handleAddPayment,
  currentPlan,
  isYearly,
}) {
  const classes = useStyles()
  if (isSubscribing) {
    return (
      <div style={{textAlign: 'center', marginTop: '50px'}}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Modal className={classes.modal} open={isOpen} onBackdropClick={onClose}>
        <Paper className={classes.paper}>
          <Grid item xs={12} align="center">
            <Title>Plan Update</Title>
          </Grid>
          <Grid item xs={12} style={{display: 'flex'}}>
            <Grid item xs={6}>
              <BodyText style={{fontSize: '.8em'}}>Current Plan:</BodyText>

              <BodyText style={{fontWeight: 700}}>{currentPlan}</BodyText>
            </Grid>
            <Grid item xs={6}>
              <BodyText style={{fontSize: '.8em'}}>Selected Plan:</BodyText>

              <BodyText style={{fontWeight: 700}}>{chosenPlan.name}</BodyText>
            </Grid>
          </Grid>
          {customer.defaultPaymentMethod ? (
            <Grid item xs={12}>
              <BodyText style={{padding: '8px'}}>
                Your card ending in <b>{customer.defaultPaymentMethod.last4} </b>
                will be charged{' '}
                <b>${isYearly ? chosenPlan.yearlyPrice : chosenPlan.monthlyPrice}</b> per{' '}
                {isYearly ? 'year' : 'month'}.
              </BodyText>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item xs={12}>
                <BodyText style={{padding: '8px'}}>No credit card on file</BodyText>
              </Grid>
              <Grid item xs={12}>
                <SubmitButton submit variant="contained" color="primary" onClick={handleAddPayment}>
                  Add a credit card
                </SubmitButton>
              </Grid>
            </React.Fragment>
          )}

          <Grid
            item
            xs={12}
            container
            alignItems="stretch"
            className={classes.containerButton}
            alignContent="flex-end"
          >
            {isSubscribing ? (
              <div style={{textAlign: 'center'}}>
                <CircularProgress />
              </div>
            ) : (
              <SubmitButton
                submit
                variant="contained"
                color="primary"
                disabled={!customer.defaultPaymentMethod}
                onClick={() => {
                  onClickConfirm(chosenPlan)
                }}
              >
                Confirm
              </SubmitButton>
            )}
          </Grid>
        </Paper>
      </Modal>
    </div>
  )
}
