import React, {useState} from 'react'
import {Link} from 'react-router'
import {Button, Divider, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import PlanApi from '../redux/api.js'
import CampaignReport from './GrowthReportsCampaign'
import KeywordsReport from './GrowthKeywordsCampaign'
import AdsReport from './GrowthAdsCampaign'
import SearchTermsReport from './GrowthSearchTermsCampaign'
import styles from './GrowthReports.styles.js'

function GrowthReports({params, classes}) {
  const [campaignReport, setCampaignReport] = useState({})
  const [keywordReport, setKeywordReport] = useState({})
  const [AdReport, setAdReport] = useState({})
  const [searchTermReport, setSearchTermReport] = useState({})

  const [isLoadingAdReport, setIsLoadingAdReport] = useState(false)
  const [isLoadingCampaignReport, setIsLoadingCampaignReport] = useState(false)
  const [isLoadingKeywordsReport, setIsLoadingKeywordsReport] = useState(false)
  const [isLoadingSearchTermReport, setIsLoadingSearchTermReport] = useState(false)

  const [toggleAds, setToggleAds] = useState(false)
  const [toggleKeywords, setToggleKeywords] = useState(false)
  const [toggleSearchTerms, setToggleSearchTerms] = useState(false)

  React.useEffect(() => {
    setIsLoadingCampaignReport(true)
    PlanApi.getCampaignReport(params?.id)
      .then(setCampaignReport)
      .finally(() => setIsLoadingCampaignReport(false))
  }, [])

  React.useEffect(() => {
    setIsLoadingKeywordsReport(true)
    PlanApi.getKeywordsReport(params?.id)
      .then(setKeywordReport)
      .finally(() => setIsLoadingKeywordsReport(false))
  }, [])
  React.useEffect(() => {
    setIsLoadingAdReport(true)
    PlanApi.getAdsReport(params?.id)
      .then(setAdReport)
      .finally(() => setIsLoadingAdReport(false))
  }, [])

  React.useEffect(() => {
    setIsLoadingSearchTermReport(true)
    PlanApi.getSearchTermsReport(params?.id)
      .then(setSearchTermReport)
      .finally(() => setIsLoadingSearchTermReport(false))
  }, [])
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Button
          variant="outlined"
          className={classes.headerBtn}
          component={Link}
          to={`/plan/${params?.id}`}
        >
          <ArrowBackIosIcon fontSize="inherit" style={{marginRight: 14}} />
          Back to plan
        </Button>
      </div>
      <div className={classes.row}>
        <Typography className={classes.title}>Campaign Reports</Typography>
      </div>
      <CampaignReport report={campaignReport} isLoading={isLoadingCampaignReport} />
      <div className={classes.row} onClick={() => setToggleAds(!toggleAds)}>
        <Typography className={classes.title}>Ads</Typography>
      </div>
      {toggleAds ? <AdsReport report={AdReport} isLoading={isLoadingAdReport} /> : null}
      <Divider />

      <div className={classes.row} onClick={() => setToggleKeywords(!toggleKeywords)}>
        <Typography className={classes.title}>Search Keywords</Typography>
      </div>
      {toggleKeywords ? (
        <KeywordsReport report={keywordReport} isLoading={isLoadingKeywordsReport} />
      ) : null}
      <Divider />

      <div className={classes.row} onClick={() => setToggleSearchTerms(!toggleSearchTerms)}>
        <Typography className={classes.title}>Search Terms</Typography>
      </div>
      {toggleSearchTerms ? (
        <SearchTermsReport report={searchTermReport} isLoading={isLoadingSearchTermReport} />
      ) : null}
    </div>
  )
}

export default withStyles(styles)(GrowthReports)
