import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

function createInitialState() {
  return {
    plan: {
      keywords: '',
      locations: [],
      conversionRate: '',
      closingRate: '',
      revenuePerContract: '',
      estimatedProfitMargin: '',
      isSubmitting: false,
      isLoading: false,
      errors: {},
    },
    forecast: {
      revenuePerContract: '',
      rateOfGrowth: '',
      lifetimeSpend: '',
      lifetimeSpendYear1: '',
      lifetimeSpendYear2: '',
      lifetimeSpendYear3: '',
      initialInvestment: '',
    },
  }
}

export default function growthPlan(state = createInitialState(), action) {
  switch (action.type) {
    case Types.UPDATE_LOCAL:
      if (action.rootKey) {
        return {
          ...state,
          [action.rootKey]: {
            ...state[action.rootKey],
            ...action.patch,
          },
        }
      }
      return {...state, ...action.patch}

    case Types.SUBMIT_PLAN:
      return {
        ...state,
        plan: {
          ...state.plan,
          isSubmitting: true,
        },
      }

    case Types.SUBMIT_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          errors: {},
          isSubmitting: false,
        },
      }

    case Types.SUBMIT_PLAN_FAILURE:
      return {
        ...state,
        plan: {
          ...state.plan,
          errors: ErrorUtils.getApiErrors(action.error),
          isSubmitting: false,
        },
      }

    case Types.GET_PLAN:
      return {
        ...state,
        plan: {
          ...createInitialState().plan,
          isLoading: true,
        },
      }
    case Types.GET_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          ...action.plan,
          errors: {},
          isLoading: false,
        },
      }

    case Types.GET_PLAN_FAILURE:
    case Types.CLEAR_PLAN:
      return {
        ...state,
        plan: {
          ...createInitialState().plan,
          isLoading: false,
        },
      }

    default:
      return state
  }
}
