import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import {Typography, Paper, Chip, MenuItem, TextField, NoSsr} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import {emphasize} from '@material-ui/core/styles/colorManipulator'
import Select, {createFilter, components} from 'react-select'
import AsyncSelect from 'react-select/async'

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({inputRef, ...props}) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      fullWidth
      style={{marginLeft: 0}}
      classes={{root: props.selectProps.classes.input}}
      InputProps={{
        inputComponent,
        disableUnderline: true,
        inputProps: {
          className: props.selectProps.classes.textInput,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}
function IndicatorsContainer(props) {
  return <div className={props.selectProps.classes.indicatorsContainer}>{props.children}</div>
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const isChrome = () => !!window && !!window.chrome && !!window.chrome.webstore
const isSafari = () => !!window && !!window.safari

function Input(props) {
  return (
    <components.Input
      {...props}
      aria-autocomplete="none"
      autoComplete={isChrome() ? 'none' : 'off'}
      className={
        !isSafari()
          ? props.selectProps.classes.interimText
          : props.selectProps.classes.interimTextNudge
      }
    />
  )
}

const customComponents = {
  Control,
  Input,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorsContainer,
}

class AutoComplete extends React.Component {
  render() {
    const {classes, matchFrom, async, ...other} = this.props
    const selectStyles = {
      input: styles => ({
        ...styles,
        margin: `2px`,
        paddingBottom: `2px`,
        paddingTop: `2px`,
      }),
      control: styles => ({...styles, padding: '8px', marginLeft: 8, marginBottom: 8}),
      indicatorSeparator: styles => ({...styles, marginTop: '8px', marginBottom: '8px'}),
    }
    const SelectComponent = async ? AsyncSelect : Select
    return (
      <NoSsr>
        <SelectComponent
          classes={classes}
          styles={selectStyles}
          components={customComponents}
          filterOption={createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom,
          })}
          {...other}
        />
      </NoSsr>
    )
  }
}

AutoComplete.propsTypes = {
  matchFrom: PropTypes.string,
}

AutoComplete.defaultProps = {
  matchFrom: 'start',
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    color: `#040D14`,
    fontWeight: 700,
    margin: '3px 0',
    padding: '12px 5px 12px 15px',
    fontFamily: 'Source Sans Pro',
    fontSize: `24px`,
    lineHeight: `36px`,
    textAlign: `center`,
    border: 'black solid 1px',
    borderRadius: `6px`,
    backgroundColor: '#F7F7F7',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: props => (props.isMulti ? 'auto' : 68),
  },
  interimText: {
    fontFamily: 'Source Sans Pro',
    fontSize: `24px`,
    lineHeight: `42px`,
    textAlign: `center`,
    fontWeight: 700,
    position: 'relative',
    top: -3,
  },
  interimTextNudge: {
    fontFamily: 'Source Sans Pro',
    fontSize: `24px`,
    lineHeight: `42px`,
    textAlign: `center`,
    fontWeight: 700,
    position: 'relative',
    top: 5,
  },
  textInput: {
    textAlign: `center`,
    display: 'flex',
    height: props => (props.isMulti ? 'auto' : undefined),
    padding: props => (props.isMulti ? '3px 0 3px 0' : undefined),
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
    overflow: 'visible',
  },
  indicatorsContainer: {
    alignItems: 'center',
    alignSelf: 'stretch',
    display: `flex`,
    flexShrink: 0,
    boxSizing: `border-box`,
    '& div': {
      padding: `8px`,
    },
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textAlign: 'left',
  },
  singleValue: {
    position: 'absolute',
    left: 2,
    margin: '6px 0 0',
    padding: '12px 0',
    fontFamily: 'Source Sans Pro',
    fontSize: `24px`,
    lineHeight: `42px`,
    textAlign: `center`,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '94%',
  },
  placeholder: {
    position: 'absolute',
    top: -10,
    left: 2,
    color: `#909497`,
    margin: '6px 0 0',
    padding: '4px 0',
    fontFamily: 'Source Sans Pro',
    fontSize: `24px`,
    lineHeight: `42px`,
    textAlign: `center`,
    fontWeight: 600,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: theme.spacing(1),
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
})
export default withStyles(styles, {withTheme: true})(AutoComplete)
