import React, {useState, useEffect} from 'react'
import {
  Dialog,
  DialogTitle,
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/HighlightOff'
import {withStyles} from '@material-ui/styles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import _ from 'lodash'
import PlayCircleIcon from '@material-ui/icons/PlayCircleFilledWhite'
import PauseCircleIcon from '@material-ui/icons/PauseCircleFilled'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import AddCircle from '@material-ui/icons/AddCircle'
import cx from 'classnames'
import {CustomTooltip} from '../GrowthPlan.styles.js'
import InputTextField from '../../common/styles/InputTextField.jsx'

import PlanApi from '../redux/api'
import {
  createNotificationFromError,
  createSuccess,
  createError,
} from '../../common/redux/actions.notifications'
import {styles} from '../CreateCampaignDialog.styles'

function EditAdgroupDialog({
  classes,
  createNotificationFromError,
  createSuccess,
  createError,
  isOpen,
  plan,
  onClose,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [keyword, setKeyword] = useState('')

  const [keywords, setKeywords] = useState([])
  const [ads, setAds] = useState([])
  const [isKeywordsLoading, setIsKeywordsLoading] = useState(true)
  const [keywordsAdCount, setKeywordsAdCount] = useState(0)
  const [isAdsLoading, setIsAdLoading] = useState(true)

  useEffect(() => {
    getKeywords()
    getAds()
  }, [])

  async function getKeywords() {
    try {
      setIsKeywordsLoading(true)
      const response = await PlanApi.getKeywords(plan.id)

      setKeywords(response)
      setKeywordsAdCount(keywordsAdCount => keywordsAdCount + response.length)
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsKeywordsLoading(false)
    }
  }
  async function getAds() {
    try {
      setIsAdLoading(true)
      const response = await PlanApi.getAds(plan.id)

      setAds(response)
      setKeywordsAdCount(keywordsAdCount => keywordsAdCount + response.length)
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsAdLoading(false)
    }
  }

  // const onCampaignNameChange = e => setCampaignName(e.target.value)
  const handleClose = () => {
    onClose()
    setKeywordsAdCount(0)
  }

  const handleRemove = async keyword => {
    try {
      const param = {
        criterion_id: keyword.id,
        status: 'REMOVED',
      }
      await PlanApi.updateKeywords(plan.id, param)
      createSuccess('Your keyword was successfully edited.')
      handleClose()
    } catch (e) {
      console.log(e)
      createNotificationFromError(`An error occurred due to ${e}.`)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePause = async keyword => {
    try {
      const param = {
        criterion_id: keyword.id,
        status: 'PAUSED',
      }
      await PlanApi.updateKeywords(plan.id, param)
      createSuccess('Your keyword was successfully edited.')
      handleClose()
    } catch (e) {
      console.log(e)
      createNotificationFromError(`An error occurred due to ${e}.`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleEnable = async keyword => {
    try {
      const param = {
        criterion_id: keyword.id,
        status: 'ENABLED',
      }
      await PlanApi.updateKeywords(plan.id, param)
      createSuccess('Your keyword was successfully edited.')
      handleClose()
    } catch (e) {
      console.log(e)
      createNotificationFromError(`An error occurred due to ${e}.`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAdRemove = async ad => {
    try {
      const param = {
        ad_id: ad.id,
        status: 'REMOVED',
      }

      await PlanApi.updateAds(plan.id, param)
      createSuccess('Your Ad was successfully edited.')
      handleClose()
    } catch (e) {
      console.log(e)
      createNotificationFromError(`An error occurred due to ${e}.`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAdPause = async ad => {
    try {
      const param = {
        ad_id: ad.id,
        status: 'PAUSED',
      }
      await PlanApi.updateAds(plan.id, param)
      createSuccess('Your Ad was successfully edited.')
      handleClose()
    } catch (e) {
      console.log(e)
      createNotificationFromError(`An error occurred due to ${e}.`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAdEnable = async ad => {
    try {
      const param = {
        ad_id: ad.id,
        status: 'ENABLED',
      }
      await PlanApi.updateAds(plan.id, param)
      createSuccess('Your Ad was successfully edited.')
      handleClose()
    } catch (e) {
      console.log(e)
      createNotificationFromError(`An error occurred due to ${e}.`)
    } finally {
      setIsLoading(false)
    }
  }

  const addKeyword = async e => {
    if (!keyword) {
      createError('Keyword is required')
      return null
    }

    try {
      setIsLoading(true)
      const param = {
        keyword,
        status: 'CREATE',
        keywordMatchType: 'EXACT',
      }
      await PlanApi.updateKeywords(plan.id, param)
      createSuccess('Your keyword was successfully added.')
      setKeyword('')
      await getKeywords()
    } catch (e) {
      console.log(e)
      createNotificationFromError(`An error occurred due to ${e}.`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      aria-labelledby="edit-adgroups-dialog-title"
      open={isOpen}
      scroll="body"
      disableBackdropClick
      className={classes.dialog}
    >
      <Grid container spacing={0} className={classes.container}>
        {isLoading || isKeywordsLoading || isAdsLoading ? (
          <Typography>Loading campaign data...</Typography>
        ) : (
          <>
            <Grid item sm={12} className={classes.center}>
              <DialogTitle
                id="edit-adgroups-dialog-title"
                className={classes.dialogTitle}
                disableTypography
              >
                Edit Ad Group
              </DialogTitle>
            </Grid>
            <Grid item sm={12} className={classes.inputGrid}>
              <Typography className={classes.itemLabel}>
                Keywords
                <CustomTooltip
                  disableFocusListener
                  disableTouchListener
                  title="List of Keywords"
                  placement="top"
                  className={classes.tooltip}
                >
                  <InfoOutlinedIcon className={classes.icon} />
                </CustomTooltip>
              </Typography>
            </Grid>
            {keywords.length > 0 &&
              keywords.map(item => {
                return (
                  <Grid container className={classes.itemBorder} key={item.id}>
                    <Grid item xs={9}>
                      <Typography>{item.name}</Typography>
                    </Grid>
                    <Grid container item xs={3}>
                      <Grid item xs={4}>
                        <CustomTooltip
                          disableFocusListener
                          disableTouchListener
                          title="Enable"
                          placement="top"
                          className={classes.customToolTip}
                        >
                          <PlayCircleIcon
                            style={{
                              color: '#00FF00',
                            }}
                            onClick={() => handleEnable(item)}
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTooltip
                          disableFocusListener
                          disableTouchListener
                          title="Pause"
                          placement="top"
                          className={classes.customToolTip}
                        >
                          <PauseCircleIcon
                            style={{
                              color: '#FFCC00',
                            }}
                            onClick={() => handlePause(item)}
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTooltip
                          disableFocusListener
                          disableTouchListener
                          title="Remove"
                          placement="top"
                          className={classes.customToolTip}
                        >
                          <DeleteIcon
                            style={{
                              color: '#FF0000',
                            }}
                            onClick={() => handleRemove(item)}
                          />
                        </CustomTooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            <Grid item sm={12} className={classes.inputGridKeywordAdd}>
              <InputTextField
                fullWidth
                label="Add Keyword"
                type="text"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={addKeyword}>
                      <AddCircle />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item sm={12} className={classes.inputGrid}>
              <Typography className={cx(classes.itemLabel, classes.itemPadding)}>
                Ads
                <CustomTooltip
                  disableFocusListener
                  disableTouchListener
                  title="List of Ads"
                  placement="top"
                  className={classes.tooltip}
                >
                  <InfoOutlinedIcon className={classes.icon} />
                </CustomTooltip>
              </Typography>
            </Grid>
            {ads.length > 0 &&
              ads.map(item => {
                return (
                  <Grid container className={classes.itemBorder} key={item.id}>
                    <Grid item xs={9}>
                      <Typography>{item.name}</Typography>
                    </Grid>
                    <Grid container item xs={3}>
                      <Grid item xs={4}>
                        <CustomTooltip
                          disableFocusListener
                          disableTouchListener
                          title="Enable"
                          placement="top"
                          className={classes.customToolTip}
                        >
                          <PlayCircleIcon
                            style={{
                              color: '#00FF00',
                            }}
                            onClick={() => handleAdEnable(item)}
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTooltip
                          disableFocusListener
                          disableTouchListener
                          title="Pause"
                          placement="top"
                          className={classes.customToolTip}
                        >
                          <PauseCircleIcon
                            style={{
                              color: '#FFCC00',
                            }}
                            onClick={() => handleAdPause(item)}
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTooltip
                          disableFocusListener
                          disableTouchListener
                          title="Remove"
                          placement="top"
                          className={classes.customToolTip}
                        >
                          <DeleteIcon
                            style={{
                              color: '#FF0000',
                            }}
                            onClick={() => handleAdRemove(item)}
                          />
                        </CustomTooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            <Grid item sm={12} className={classes.center}>
              <Button size="small" className={classes.cancelButton} onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
    schedule: state.schedule,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess, createError}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(EditAdgroupDialog)
