import React, {useState} from 'react'
import {Link} from 'react-router'
import {Button, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import moment from 'moment'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import PlanApi from '../redux/api.js'
import CustomerReportMetrics from './CustomerReportMetrics'
import styles from './CustomerReport.styles'
import Select from '../../common/Select'
import {CustomTooltip} from '../GrowthPlan.styles.js'
import BiddingStrategyReport from './BiddingStrategyReport'

function CustomerReport({params, classes}) {
  const [customerReport, setCustomerReport] = useState({})
  const [biddingStrategyReport, setBiddingStrategyReport] = useState(null)
  const [isLoadingCustomerReport, setIsLoadingCustomerReport] = useState(false)
  const [isLoadingBiddingStrategyReport, setIsLoadingBiddingStrategyReport] = useState(false)

  const [date, setDate] = useState(
    moment()
      .subtract(7, 'days')
      .format('YYYY-MM-DD'),
  )
  // generate options for the dropdown menu last week, last month, last 3 months, last 6 months, last year
  const options = [
    {
      label: 'Last Week',
      value: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
    },
    {
      label: 'Last Month',
      value: moment()
        .subtract(1, 'months')
        .format('YYYY-MM-DD'),
    },
    {
      label: 'Last 3 Months',
      value: moment()
        .subtract(3, 'months')
        .format('YYYY-MM-DD'),
    },
    {
      label: 'Last 6 Months',
      value: moment()
        .subtract(6, 'months')
        .format('YYYY-MM-DD'),
    },
    {
      label: 'Last Year',
      value: moment()
        .subtract(1, 'years')
        .format('YYYY-MM-DD'),
    },
  ]

  const handleChange = event => {
    setDate(event.target.value)
  }

  React.useEffect(() => {
    setIsLoadingCustomerReport(true)
    setIsLoadingBiddingStrategyReport(true)
    PlanApi.getBiddingStrategyReport(date)
      .then(setBiddingStrategyReport)
      .finally(() => setIsLoadingBiddingStrategyReport(false))

    PlanApi.getCustomerReport(date)
      .then(setCustomerReport)
      .finally(() => setIsLoadingCustomerReport(false))
  }, [date])

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Typography className={classes.title}>Google Ads Dashboard</Typography>
        <Select options={options} className={classes.select} value={date} onChange={handleChange} />
      </div>
      <div className={classes.row} style={{justifyContent: 'flex-start'}}>
        <Typography className={classes.subtitle}>Account Metrics</Typography>
        <CustomTooltip
          disableFocusListener
          disableTouchListener
          title="Metrics based on the Google Ads Campaigns
that are linked to a Finjoy Plan"
          placement="top"
          className={classes.tooltip}
        >
          <InfoOutlinedIcon className={classes.icon} />
        </CustomTooltip>
      </div>
      <CustomerReportMetrics report={customerReport} isLoading={isLoadingCustomerReport} />
      <Typography className={classes.subtitle} style={{margin: '32px 0'}}>
        Metrics by Bidding Strategy
      </Typography>
      <BiddingStrategyReport
        report={biddingStrategyReport}
        isLoading={isLoadingBiddingStrategyReport}
      />
    </div>
  )
}

export default withStyles(styles)(CustomerReport)
