import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/styles'

import {Helmet} from 'react-helmet/es/Helmet'
import {CircularProgress, Paper, TablePagination, Grid, Typography} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import {withRouter} from 'react-router'
import {updateHeaderTitle} from '../common/redux/actions.header'
import {createNotificationFromError} from '../common/redux/actions.notifications'
import PlanHistoryApi from './api'

const formatNumber = value => numeral(value).format('0,0')

const styles = theme => ({
  container: {
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    padding: '0 20px',
  },
  titleText: {
    fontFamily: 'Source Sans Pro',
    marginBottom: '30px',
    fontSize: '36px',
    fontWeight: '600',
    letterSpacing: '0.35px',
    lineHeight: '45px',
  },
  textContainer: {margin: '23px 0'},
  planPaper: {
    '&:hover': {
      cursor: 'pointer',
    },
    margin: '13px 0',
    padding: '29px 35px',
    borderRadius: '3px',
    height: '274px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 15px 0 rgba(32,25,100,0.1)',
  },
  planMainText: {
    color: 'rgba(0,0,0,0.78)',
    fontFamily: 'Source Sans Pro',
    fontSize: '14px',
    fontWeight: '300',
    letterSpacing: '-0.09px',
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  planSubText: {
    color: '#2B2C2E',
    fontFamily: 'Source Sans Pro',
    fontSize: '21px',
    letterSpacing: '-0.13px',
    lineHeight: '25px',
    textTransform: 'capitalize',
  },
})

function PlanHistoryList({classes, createNotificationFromError, router}) {
  const [plansHistory, setPlansHistory] = useState([])
  const [plansHistoryCount, setPlansHistoryCount] = useState(0)
  const [fetchingPlansHistory, setFetchingPlansHistory] = useState(false)
  const [page, setPage] = React.useState(0)

  useEffect(() => {
    updateHeaderTitle('History')

    return () => {
      updateHeaderTitle('History')
    }
  }, [])

  useEffect(() => {
    getPlansHistory()
  }, [page])
  const handlePlanClicked = id => {
    router.push(`/plan/${id}`)
  }
  async function getPlansHistory() {
    try {
      setFetchingPlansHistory(true)
      const response = await PlanHistoryApi.getPlanHistory({
        page: page + 1,
        order_by: '-modified',
      })
      if (response.count > 0) {
        setPlansHistory(response.results)
        setPlansHistoryCount(response.count)
      }
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setFetchingPlansHistory(false)
    }
  }

  if (!plansHistory || fetchingPlansHistory) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    )
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  function SearchPlan({plan, classes}) {
    const locations = []
    for (let i = 0; i < plan.locations.length; i++) {
      locations.push(plan.locations[i].name)
    }
    return (
      <Paper
        className={classes.planPaper}
        onClick={() => {
          handlePlanClicked(plan.id)
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Grid item xs={12}>
              <Typography className={classes.planMainText}>Keywords</Typography>
              <Typography className={classes.planSubText}>{plan.keywords}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.textContainer}>
              <Typography className={classes.planMainText}>Locations</Typography>
              <Typography className={classes.planSubText}>{locations.join(', ')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container>
              <Grid item xs={7}>
                <Typography className={classes.planMainText}>DATE SEARCHED:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.planMainText}>
                  <b>{moment(plan.modified).format('MMMM D, YYYY')}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <Typography className={classes.planMainText}>TOTAL IMPRESSIONS</Typography>
              <Typography className={classes.planSubText}>
                {formatNumber(plan.totalImpressions)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <Typography className={classes.planMainText}>TOTAL MONTHLY REVENUE</Typography>
              <Typography className={classes.planSubText}>
                ${formatNumber(plan.totalMonthlyProfit)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <Typography className={classes.planMainText}>TOTAL MONTHLY PROFIT</Typography>
              <Typography className={classes.planSubText}>
                ${formatNumber(plan.totalMonthlyRevenue)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <React.Fragment>
      <Helmet title="History" />
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={8}>
            <Typography className={classes.titleText}>History</Typography>
          </Grid>
        </Grid>
        {plansHistory.map((plan, index) => {
          return <SearchPlan classes={classes} key={index} plan={plan} />
        })}
      </div>
      <TablePagination
        component="div"
        count={plansHistoryCount}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[]}
        onChangePage={handleChangePage}
      />
    </React.Fragment>
  )
}
function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
    schedule: state.schedule,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({updateHeaderTitle, createNotificationFromError}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(PlanHistoryList)
