import _ from 'lodash'
import React, {useState, useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
  withStyles,
  Grid,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  IconButton,
  FormControlLabel,
  Button,
  Divider,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import clsx from 'clsx'
import {loadStripe} from '@stripe/stripe-js'
import {Elements, ElementsConsumer, CardElement} from '@stripe/react-stripe-js'
import InputValidator from '../../common/InputValidator'
import ValidatedComponent from '../../common/ValidatedComponent.jsx'
import {createProfile, getProfile, getProfileSuccess} from '../../profile/redux/actions'
import {styles} from './styles'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import * as Actions from '../redux/actions'
import BillingApi from '../../billing/api'
import ProfileApi from '../../profile/api'
import {updateHeaderTitle} from '../../common/redux/actions.header'
import {createNotificationFromError, createSuccess} from '../../common/redux/actions.notifications'
import PlanCard from './PlanCard'
import settings from '../../../../config/settings.json'
import PaymentMethod from './PaymentMethod'

export function Signup({
  errors,
  classes,
  createProfile,
  createNotificationFromError,
  createSuccess,
  isComponentValid,
  router,
  profile,
  params,
  getProfile,
}) {
  const stripePromise = loadStripe(settings?.stripeKey)
  const theme = useTheme()
  const isMediumOrGreater = useMediaQuery(theme.breakpoints.up('md'))
  const STEPS = 3
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [customer, setCustomer] = useState(null)
  const [isMonthly, setIsMonthly] = useState(true)
  const [selectedPlan, setSelectedPlan] = useState()
  const [tierPlans, setTierPlans] = useState()
  const [error, setError] = useState(null)
  const [saveDisccount, setSaveDisccount] = useState(0)
  const [state, setState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false,
    showPassword: false,
    showConfirmPassword: false,
  })

  const [billingDetails, setBillingDetails] = useState({
    city: '',
    country: '',
    line1: '',
    line2: '',
    postal_code: '',
    state: '',
    name: '',
    expirationDate: null,
    ccv: '',
    last4: '',
  })

  useEffect(() => {
    setIsLoading(true)
    if (currentStep === 1) {
      fetchTierPlans()
      getCustomer()
    }
    setIsLoading(false)
  }, [currentStep])

  useEffect(() => {
    if (tierPlans) {
      getPlanByRoute()
    }
  }, [tierPlans])

  useEffect(() => {
    if (selectedPlan) {
      const {yearlyPrice, monthlyPrice} = selectedPlan
      const disc = Math.floor(100 - (Number(yearlyPrice) * 100) / (Number(monthlyPrice) * 12))
      setSaveDisccount(disc)
    }
  }, [selectedPlan])

  useEffect(() => {
    if (profile) {
      if (_.get(profile, 'ownerAccount.tierPlan')) return router.push('/')
      setCurrentStep(1)
    }
  }, [profile])

  function getPlanByRoute() {
    const routePlan = _.get(params, 'plan')
    const routeFreq = _.get(params, 'frequency')

    if (routePlan) {
      const plan = tierPlans.find(plan => plan.name.toLowerCase() === routePlan.toLowerCase())
      if (plan) {
        setSelectedPlan(plan)
      }
    }

    if (routeFreq && routeFreq.toLowerCase() === 'yearly') {
      setIsMonthly(false)
    }
  }

  async function createUser(event) {
    event.preventDefault()
    setIsLoading(true)
    const {firstName, lastName, email, password} = state
    if (isComponentValid()) {
      createProfile({
        firstName,
        lastName,
        email,
        password,
      })
    }
    setIsLoading(false)
  }

  async function fetchTierPlans() {
    try {
      setIsLoading(true)
      const response = await BillingApi.getTierPlans({is_active: true})
      setTierPlans(response.results)
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsLoading(false)
    }
  }

  async function getCustomer() {
    try {
      setIsLoading(true)
      const response = await BillingApi.getCustomer()
      setCustomer(response)
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleSubmit(stripe, elements) {
    try {
      setIsLoading(true)
      const billingDetailsFinal = {
        address: {
          city: billingDetails.city,
          line1: billingDetails.line1,
          postal_code: billingDetails.postal_code,
          state: billingDetails.state,
        },
        name: billingDetails.name,
      }

      const sourcePayload = await stripe.createSource(elements.getElement(CardElement), {
        type: 'card',
        currency: 'usd',
        owner: {
          email: profile.email,
        },
      })

      const paymentMethodPayload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: billingDetailsFinal,
      })

      await BillingApi.addPaymentMethod({
        payment_method_id: paymentMethodPayload.paymentMethod.id,
        source_id: sourcePayload.source.id,
      })

      handleSubscribe()
    } catch (e) {
      createNotificationFromError(e)
    }
  }

  const nextStep = () => {
    if (currentStep === STEPS - 1) return router.push('/')
    setCurrentStep(currentStep + 1)
  }

  const handleChange = prop => event => {
    setState({...state, [prop]: event.target.value})
  }

  const handleAcceptTerms = event => {
    setState({...state, acceptTerms: event.target.checked})
  }

  const handleClickShowPassword = () => {
    setState({...state, showPassword: !state.showPassword})
  }

  const handleClickShowConfirmPassword = () => {
    setState({...state, showConfirmPassword: !state.showConfirmPassword})
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const showStepsCircles = numberOfSteps => {
    const steps = [...Array(numberOfSteps).keys()]
    return (
      <div className={classes.stepsContainer}>
        {steps.map((_, index) => (
          <div
            key={index}
            className={classes.stepCircle}
            style={{
              backgroundColor:
                currentStep === index ? theme.palette.Blue[500] : theme.palette.Gray[500],
            }}
          />
        ))}
      </div>
    )
  }

  const validateConfirmPassword = () => {
    const {password, confirmPassword} = state
    if (password && confirmPassword && password !== confirmPassword) {
      return 'Both passwords must be equal'
    }
  }

  const showAccountForm = () => {
    return (
      <form className={classes.form} autoComplete="off" noValidate>
        <Grid item xs={12}>
          <InputValidator errors={errors.email} shouldValidateOnBlur>
            <OutlinedInput
              autoComplete="off"
              variant="outlined"
              className={classes.inputField}
              type="email"
              name="email"
              placeholder="Email"
              required
              onChange={handleChange('email')}
              value={state.email}
            />
          </InputValidator>
        </Grid>
        <Grid container justify="space-between" item xs={12}>
          <Grid item xs={6}>
            <InputValidator
              style={{marginRight: '8px'}}
              shouldValidateOnValueChange
              shouldValidateOnBlur
            >
              <OutlinedInput
                autoComplete="off"
                variant="outlined"
                className={classes.inputField}
                style={{marginRight: '8px'}}
                type="text"
                name="firstName"
                required
                placeholder="First name"
                value={state.firstName}
                onChange={handleChange('firstName')}
              />
            </InputValidator>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              paddingLeft: '8px',
            }}
          >
            <InputValidator shouldValidateOnValueChange shouldValidateOnBlur>
              <OutlinedInput
                autoComplete="off"
                variant="outlined"
                className={classes.inputField}
                type="text"
                name="lastName"
                required
                placeholder="Last Name"
                value={state.lastName}
                onChange={handleChange('lastName')}
              />
            </InputValidator>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputValidator errors={errors.password} shouldValidateOnValueChange shouldValidateOnBlur>
            <OutlinedInput
              autoComplete="off"
              variant="outlined"
              className={classes.inputField}
              type={state.showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              required
              onChange={handleChange('password')}
              value={state.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    style={{color: theme.palette.Gray[state.showPassword ? 600 : 500]}}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </InputValidator>
        </Grid>

        <Grid item xs={12}>
          <InputValidator
            shouldValidateOnValueChange
            shouldValidateOnBlur
            customValidation={() => {
              return validateConfirmPassword()
            }}
          >
            <OutlinedInput
              autoComplete="off"
              variant="outlined"
              className={classes.inputField}
              type={state.showConfirmPassword ? 'text' : 'password'}
              name="password-confirm"
              placeholder="Confirm Password"
              required
              onChange={handleChange('confirmPassword')}
              value={state.confirmPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    className={classes.iconButton}
                    size="small"
                    style={{
                      color: theme.palette.Gray[state.showConfirmPassword ? 600 : 500],
                    }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </InputValidator>
        </Grid>
        <Grid item xs={12}>
          <InputValidator
            canShowLabel={false}
            shouldValidateOnValueChange
            shouldValidateOnBlur
            errors={errors.acceptTerms}
          >
            <FormControlLabel
              control={
                <Checkbox
                  required
                  id="terms"
                  name="terms-and-conditions"
                  checked={state.acceptTerms}
                  className={classes.checkbox}
                  onChange={handleAcceptTerms}
                  value="acceptTerms"
                />
              }
              label={
                <span className={classes.text}>
                  I agree to the{' '}
                  <a
                    className={classes.link}
                    href="/equity-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </span>
              }
            />
          </InputValidator>
        </Grid>
      </form>
    )
  }

  const handleSelectPlan = plan => setSelectedPlan(plan)

  const showPlanCards = () => {
    return (
      <>
        {tierPlans &&
          !isLoading &&
          tierPlans.map((tierPlan, index) => (
            <PlanCard
              key={index}
              tierPlan={tierPlan}
              selectedPlan={selectedPlan}
              onSelectPlan={handleSelectPlan}
              classes={classes}
              theme={theme}
              isMonthly={isMonthly}
            />
          ))}
      </>
    )
  }

  async function handleSubscribe() {
    try {
      setIsLoading(true)
      await BillingApi.subscribe({
        plan: isMonthly ? selectedPlan.monthlyNickname : selectedPlan.yearlyNickname,
      })
      createSuccess('Subscription was successfully created.')
      await getProfile()
      if (_.get(profile, 'ownerAccount.tierPlan')) return router.push('/')
      nextStep()
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const showPaymentMethodForm = () => {
    return (
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({stripe, elements}) => (
            <PaymentMethod
              stripe={stripe}
              setBillingDetails={setBillingDetails}
              billingDetails={billingDetails}
              setError={setError}
              error={error}
              isLoading={isLoading}
              isComponentValid={isComponentValid}
              handleSubmit={handleSubmit}
              elements={elements}
            />
          )}
        </ElementsConsumer>
      </Elements>
    )
  }

  return (
    <>
      {currentStep === 0 && (
        <article className={classes.page}>
          <section className={classes.titleWrapper}>
            {showStepsCircles(3)}
            <h1 className={classes.title}>
              <strong>Step 1. </strong>Create an Account
            </h1>
            <span className={classes.subtitle}>Validate your ad spend with FinJoy!</span>
          </section>
          <section className={classes.formWrapper}>{showAccountForm()}</section>
          <section className={classes.buttonWrapper}>
            <Grid item xs={12} style={{flex: '0 0 0%'}}>
              <ButtonWithProgress
                disabled={!state.acceptTerms}
                type="submit"
                name="btn-create"
                fullWidth
                variant="contained"
                className={classes.submitButton}
                onClick={createUser}
                isLoading={isLoading || !!profile}
                loadingStyle={{color: theme.palette.Gray[200]}}
              >
                {!isLoading && 'Create Account'}
              </ButtonWithProgress>
              <span className={classes.text2}>
                Already have an account?{' '}
                <Link className={classes.link2} to="/login" rel="noopener noreferrer">
                  Login
                </Link>
              </span>
            </Grid>
          </section>
        </article>
      )}

      {currentStep === 1 && (
        <article className={clsx(classes.page, classes.page2)}>
          <section className={clsx(classes.titleWrapper, classes.wrapperPage2)}>
            <section style={{display: 'flex', flexDirection: 'column'}}>
              {showStepsCircles(3)}
              <h1 className={classes.title}>
                <strong>Step 2. </strong>Choose your Plan
              </h1>
              <span className={classes.subtitle}>Clear pricing. No contracts. No hidden fees.</span>
            </section>
            <section className={classes.toggleGroup}>
              <Button onClick={() => setIsMonthly(!isMonthly)} className={classes.toggle}>
                <div className={clsx(classes.text, classes.option, isMonthly && classes.active)}>
                  Monthly
                </div>
                <div className={clsx(classes.text, classes.option, !isMonthly && classes.active)}>
                  Yearly
                </div>
              </Button>
              <Button
                onClick={() => setIsMonthly(!isMonthly)}
                className={clsx(classes.save, !isMonthly && classes.saveOn)}
              >
                Save {saveDisccount}%
              </Button>
            </section>
          </section>
          <section className={clsx(classes.plansWrapper, classes.wrapperPage2)}>
            {showPlanCards()}
          </section>
          {isMediumOrGreater && <Divider className={classes.dividerPage} />}
          <section
            className={isMediumOrGreater ? classes.buttonWrapperPage2 : classes.buttonWrapper}
          >
            <Grid item xs={12} style={{flex: '0 0 0%'}}>
              <ButtonWithProgress
                disabled={!selectedPlan}
                type="submit"
                name="btn-create"
                fullWidth
                variant="contained"
                className={classes.submitButton}
                onClick={nextStep}
                isLoading={isLoading}
                loadingStyle={{color: theme.palette.Gray[200]}}
              >
                Choose Plan
              </ButtonWithProgress>
            </Grid>
          </section>
        </article>
      )}

      {currentStep === 2 && (
        <article className={classes.page}>
          <section className={classes.titleWrapper}>
            {showStepsCircles(3)}
            <h1 className={classes.title}>
              <strong>Step 3. </strong>Enter your Credit Card
            </h1>
            <span className={classes.subtitle}>You will be charged upon confirmation.</span>
          </section>
          {showPaymentMethodForm()}
        </article>
      )}
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...Actions,
      createProfile,
      getProfileSuccess,
      createNotificationFromError,
      createSuccess,
      updateHeaderTitle,
      getProfile,
    },
    dispatch,
  )
}

function mapStateToProps(state) {
  const {oauthToken, oauthTokenSecret, isNoEmailProvidedError, provider} = state.auth
  return {
    ...state.signup,
    oauthToken,
    oauthTokenSecret,
    isNoEmailProvidedError,
    provider,
    profile: _.get(state.profile, 'profile', null),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
  ValidatedComponent,
)(Signup)
