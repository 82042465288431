import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Paper, Typography, Grid, Container, Link} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import PropTypes from 'prop-types'
import autobind from 'autobind-decorator'
import _ from 'lodash'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded'
import {withRouter} from 'react-router'
import ButtonWithProgress from '../common/ButtonWithProgress.jsx'
import {submitPlan, updateLocal} from './redux/actions.js'
import PlanApi from './redux/api.js'
import InputValidator from '../common/InputValidator'
import ValidatedComponent from '../common/ValidatedComponent.jsx'
import styles, {CustomTooltip} from './GrowthPlan.styles.js'
import InputTextField from '../common/styles/InputTextField.jsx'
import Autocomplete from '../common/Autocomplete.jsx'
import NumberFormatInput from '../common/NumberFormatInput'
import {keywordMatchTypeOpptions} from './GrowthPlan.const'
import LocationsSelect from './LocationsSelect'

class GrowthPlan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cacheOptions: false,
      isLoadingLanguages: false,
      showAdvanced: false,
      languages: [],
    }
  }

  async componentDidMount() {
    await this.loadLanguages()
  }

  componentDidUpdate(prevProps, prevState) {
    const {profile} = this.props
    if (profile && !_.get(profile, 'ownerAccount.tierPlan')) {
      this.props.router.push('/signup')
    }
  }

  onChange(fieldName, e) {
    this.props.updateLocal({[fieldName]: e.target.value}, 'plan')
  }

  @autobind
  toggleAdvanced() {
    this.setState(prevState => ({...prevState, showAdvanced: !prevState.showAdvanced}))
  }

  @autobind
  onLocationChange(value) {
    this.props.updateLocal({locations: value}, 'plan')
    return value
  }

  @autobind
  onLanguageChange(value) {
    this.props.updateLocal({language: value}, 'plan')
    return value
  }

  @autobind
  onMatchTypeChange(value) {
    this.props.updateLocal({keywordMatchType: value}, 'plan')
    return value
  }

  @autobind
  handlePlanSubmit(e, callback) {
    e.preventDefault()
    if (this.props.isComponentValid()) {
      const plan = {
        ...this.props.plan,
        locations: this.props.plan.locations.map(location => location.value),
        language: _.get(this.props.plan, 'language.value'),
        keywordMatchType: _.get(this.props.plan, 'keywordMatchType.value'),
      }
      this.props.submitPlan(plan, callback)
    } else if (typeof callback === 'function') {
      callback(false)
    }
  }

  async loadLanguages() {
    this.setState({isLoadingLanguages: true})
    const response = await PlanApi.getLanguages()
    const languages = response.map(item => ({
      label: item.languageName,
      value: item.criterionId,
    }))
    this.setState({languages})
    this.setState({isLoadingLanguages: false})
  }

  render() {
    const {classes, plan, profile} = this.props
    const {isLoadingLanguages} = this.state
    const {errors} = plan

    return (
      <React.Fragment>
        <div className={classes.background}>
          <Container>
            <Paper className={classes.paper}>
              <Typography variant="h5" className={classes.growthPlanHeader}>
                Growth Plan Engine
              </Typography>
              <form noValidate className={classes.growthPlanForm}>
                <Grid container spacing={0}>
                  <Grid item className={classes.alignLeft} sm={6} xs={12}>
                    <Grid item xs={12}>
                      <div className={classes.titleContainer}>
                        <Typography className={classes.titles}>Keywords</Typography>
                        <CustomTooltip
                          disableFocusListener
                          disableTouchListener
                          title="Enter keywords reflecting your primary products or services. Think like your consumer."
                          placement="top"
                        >
                          <InfoOutlinedIcon className={classes.icon} />
                        </CustomTooltip>
                      </div>
                    </Grid>
                    <Grid item xs={12} className={classes.inputGrid}>
                      <InputTextField
                        withValidator
                        className={classes.inputField}
                        shouldValidateOnBlur={true}
                        errors={_.get(errors, 'keywords', [])}
                        type="text"
                        required
                        name="keywords"
                        value={plan.keywords}
                        onChange={this.onChange.bind(this, 'keywords')}
                        placeholder="Keywords"
                        inputProps={{'aria-label': 'naked'}}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.alignLeft} sm={6} xs={12}>
                    <Grid item xs={12}>
                      <div className={classes.titleContainer}>
                        <Typography className={classes.titles}>Location</Typography>
                        <CustomTooltip
                          disableFocusListener
                          disableTouchListener
                          title="Start broad at the DMA (Designated Market Area) or county level to see comparative search volume by region."
                          placement="top"
                        >
                          <InfoOutlinedIcon className={classes.icon} />
                        </CustomTooltip>
                      </div>
                    </Grid>

                    <Grid
                      id="autocompleteContainer"
                      style={{marginLeft: 8}}
                      item
                      xs={12}
                      className={classes.inputGrid}
                    >
                      <InputValidator
                        required
                        shouldValidateOnValueChange={true}
                        shouldValidateOnBlur={true}
                        style={{marginLeft: 8}}
                        errors={_.get(errors, 'location', [])}
                      >
                        <LocationsSelect
                          required
                          className={classes.inputField}
                          value={plan.locations}
                          onChange={this.onLocationChange}
                        />
                      </InputValidator>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.alignLeft} md={3} sm={6} xs={12}>
                    <div className={classes.titleContainer}>
                      <Typography className={classes.titles}>Lead Conversion Rate</Typography>
                      <CustomTooltip
                        disableFocusListener
                        disableTouchListener
                        title="If you don’t know your overall conversion rate, be conservative. This number will very rarely be double digits."
                        placement="top"
                      >
                        <InfoOutlinedIcon className={classes.icon} />
                      </CustomTooltip>
                    </div>
                    <Grid item xs={12} className={classes.inputGrid}>
                      <InputTextField
                        withValidator
                        className={classes.inputField}
                        required
                        shouldValidateOnBlur={true}
                        errors={_.get(errors, 'conversionRate', [])}
                        type="text"
                        name="conversionRate"
                        placeholder="%"
                        value={plan.conversionRate}
                        onChange={this.onChange.bind(this, 'conversionRate')}
                        inputComponent={NumberFormatInput}
                        inputProps={{
                          suffix: '%',
                          maxLength: 18,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.alignLeft} md={3} sm={6} xs={12}>
                    <div className={classes.titleContainer}>
                      <Typography className={classes.titles}>Closing Rate</Typography>
                      <CustomTooltip
                        disableFocusListener
                        disableTouchListener
                        title="If you don’t know your overall closing rate, be conservative. This number will very rarely be double digits."
                        placement="top"
                      >
                        <InfoOutlinedIcon className={classes.icon} />
                      </CustomTooltip>
                    </div>
                    <Grid item xs={12} className={classes.inputGrid}>
                      <InputTextField
                        withValidator
                        required
                        shouldValidateOnBlur={true}
                        errors={_.get(errors, 'closingRate', [])}
                        className={classes.inputField}
                        type="text"
                        name="closingRate"
                        placeholder="%"
                        value={plan.closingRate}
                        onChange={this.onChange.bind(this, 'closingRate')}
                        inputComponent={NumberFormatInput}
                        inputProps={{
                          suffix: '%',
                          maxLength: 18,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.alignLeft} md={3} sm={6} xs={12}>
                    <div className={classes.titleContainer}>
                      <Typography className={classes.titles}>Average Sale Value</Typography>
                      <CustomTooltip
                        disableFocusListener
                        disableTouchListener
                        title="This is the price of your product or service on average."
                        placement="top"
                      >
                        <InfoOutlinedIcon className={classes.icon} />
                      </CustomTooltip>
                    </div>
                    <Grid item xs={12} className={classes.inputGrid}>
                      <InputTextField
                        withValidator
                        className={classes.inputField}
                        shouldValidateOnBlur={true}
                        errors={_.get(errors, 'revenuePerContract', [])}
                        type="text"
                        inputComponent={NumberFormatInput}
                        required
                        name="revenuePerContract"
                        value={plan.revenuePerContract}
                        onChange={this.onChange.bind(this, 'revenuePerContract')}
                        placeholder="$"
                        inputProps={{
                          'aria-label': 'bare',
                          prefix: '$',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.alignLeft} md={3} sm={6} xs={12}>
                    <div className={classes.titleContainer}>
                      <Typography className={classes.titles}>Estimated Profit Margin</Typography>
                      <CustomTooltip
                        disableFocusListener
                        disableTouchListener
                        title="This is the percentage earned on each sale."
                        placement="top"
                      >
                        <InfoOutlinedIcon className={classes.icon} />
                      </CustomTooltip>
                    </div>
                    <Grid item xs={12} className={classes.inputGrid}>
                      <InputTextField
                        withValidator
                        shouldValidateOnBlur={true}
                        className={classes.inputField}
                        errors={_.get(errors, 'estimatedProfitMargin', [])}
                        type="text"
                        inputComponent={NumberFormatInput}
                        required
                        name="estimatedProfitMargin"
                        value={plan.estimatedProfitMargin}
                        onChange={this.onChange.bind(this, 'estimatedProfitMargin')}
                        placeholder="%"
                        inputProps={{
                          'aria-label': 'bare',
                          suffix: '%',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={11} xs={12} />
                  <Grid item sm={1} xs={12} alignItems="flex-end">
                    <Link
                      href="#"
                      onClick={this.toggleAdvanced}
                      className={classes.advancedOptions}
                      underline="none"
                    >
                      Advanced
                    </Link>
                  </Grid>
                  {this.state.showAdvanced && (
                    <>
                      <Grid item className={classes.alignLeft} sm={3} xs={12}>
                        <Grid item xs={12}>
                          <div className={classes.titleContainer}>
                            <Typography className={classes.titles}>Negative Keywords</Typography>
                            <CustomTooltip
                              disableFocusListener
                              disableTouchListener
                              title="Enter negative keywords reflecting your primary products or services. Think like your consumer."
                              placement="top"
                            >
                              <InfoOutlinedIcon className={classes.icon} />
                            </CustomTooltip>
                          </div>
                        </Grid>
                        <Grid item xs={12} className={classes.inputGrid}>
                          <InputTextField
                            withValidator
                            className={classes.inputField}
                            shouldValidateOnBlur={true}
                            errors={_.get(errors, 'negativeKeywords', [])}
                            type="text"
                            name="negativeKeywords"
                            value={plan.negativeKeywords}
                            onChange={this.onChange.bind(this, 'negativeKeywords')}
                            placeholder="Negative Keywords"
                            inputProps={{'aria-label': 'naked'}}
                          />
                        </Grid>
                      </Grid>

                      <Grid item className={classes.alignLeft} sm={3} xs={12}>
                        <Grid item xs={12}>
                          <div className={classes.titleContainer}>
                            <Typography className={classes.titles}>Language</Typography>
                            <CustomTooltip
                              disableFocusListener
                              disableTouchListener
                              title="Select specific language."
                              placement="top"
                            >
                              <InfoOutlinedIcon className={classes.icon} />
                            </CustomTooltip>
                          </div>
                        </Grid>

                        <Grid
                          id="autocompleteContainer"
                          style={{marginLeft: 8}}
                          item
                          xs={12}
                          className={classes.inputGrid}
                        >
                          <InputValidator
                            shouldValidateOnValueChange={true}
                            shouldValidateOnBlur={true}
                            style={{marginLeft: 8}}
                            errors={_.get(errors, 'language', [])}
                          >
                            <Autocomplete
                              className={classes.inputField}
                              value={plan.language}
                              options={this.state.languages}
                              placeholder="language"
                              onChange={this.onLanguageChange}
                              isLoading={isLoadingLanguages}
                            />
                          </InputValidator>
                        </Grid>
                      </Grid>

                      <Grid item className={classes.alignLeft} sm={3} xs={12}>
                        <Grid item xs={12}>
                          <div className={classes.titleContainer}>
                            <Typography className={classes.titles}>Max CPC</Typography>
                            <CustomTooltip
                              disableFocusListener
                              disableTouchListener
                              title="Enter max CPC."
                              placement="top"
                            >
                              <InfoOutlinedIcon className={classes.icon} />
                            </CustomTooltip>
                          </div>
                        </Grid>
                        <Grid item xs={12} className={classes.inputGrid}>
                          <InputTextField
                            withValidator
                            className={classes.inputField}
                            shouldValidateOnBlur={true}
                            errors={_.get(errors, 'maxCpc', [])}
                            type="text"
                            inputComponent={NumberFormatInput}
                            name="maxCpc"
                            value={plan.maxCpc}
                            onChange={this.onChange.bind(this, 'maxCpc')}
                            placeholder="$"
                            inputProps={{
                              'aria-label': 'bare',
                              prefix: '$',
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid item className={classes.alignLeft} sm={3} xs={12}>
                        <Grid item xs={12}>
                          <div className={classes.titleContainer}>
                            <Typography className={classes.titles}>Match Type</Typography>
                            <CustomTooltip
                              disableFocusListener
                              disableTouchListener
                              title="Select keyword match type."
                              placement="top"
                            >
                              <InfoOutlinedIcon className={classes.icon} />
                            </CustomTooltip>
                          </div>
                        </Grid>

                        <Grid
                          id="autocompleteContainer"
                          style={{marginLeft: 8}}
                          item
                          xs={12}
                          className={classes.inputGrid}
                        >
                          <InputValidator
                            shouldValidateOnValueChange={true}
                            shouldValidateOnBlur={true}
                            style={{marginLeft: 8}}
                            errors={_.get(errors, 'keywordMatchType', [])}
                          >
                            <Autocomplete
                              className={classes.inputField}
                              value={plan.keywordMatchType}
                              options={keywordMatchTypeOpptions}
                              placeholder="keyword match type"
                              onChange={this.onMatchTypeChange}
                            />
                          </InputValidator>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Paper>
            <Grid container spacing={0}>
              <Grid item sm={8} xs={12} />
              <Grid item md={4} sm={12} xs={12} className={classes.textAlignRight}>
                <ButtonWithProgress
                  className={classes.submitButton}
                  isLoading={plan.isSubmitting}
                  onClick={this.handlePlanSubmit}
                  variant="contained"
                >
                  TEST MY IDEA
                  <ArrowForwardRoundedIcon className={classes.submitButtonArrow} />
                </ButtonWithProgress>
              </Grid>
            </Grid>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

GrowthPlan.displayName = 'GrowthPlan'

GrowthPlan.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({submitPlan, updateLocal}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.growthPlan,
    profile: _.get(state.profile, 'profile', null),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true}),
  ValidatedComponent,
  withRouter,
)(GrowthPlan)
