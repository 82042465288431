import settings from '../../../../config/settings'
import axios from '../../api/axios'

export default class PlanApi {
  static submitPlan(payload) {
    return axios.post(`${settings.apiBaseURL}/plans`, payload)
  }

  static submitPlanEdit(id, payload) {
    return axios.patch(`${settings.apiBaseURL}/plans/${id}`, payload)
  }

  static getPlan(id, params) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}`, {params})
  }

  static getResults(id) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/results`)
  }

  static getKeywords(id) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/campaign-keywords-list`)
  }

  static getAds(id) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/campaign-ads-list`)
  }

  static getOpportunity(id, config) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/opportunity`, config)
  }

  static getLocations(params) {
    return axios.get(`${settings.apiBaseURL}/locations`, {params})
  }

  static getForecast(id, forecast) {
    return axios.post(`${settings.apiBaseURL}/plans/${id}/forecast`, forecast)
  }

  static updateKeywords(id, params) {
    return axios.post(`${settings.apiBaseURL}/plans/${id}/campaign-keywords-update`, params)
  }

  static getKeywordsReport(id) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/campaign-keywords-view`)
  }

  static getAdsReport(id) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/campaign-ads-view`)
  }

  static getSearchTermsReport(id) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/campaign-search-terms-view`)
  }

  static updateAds(id, params) {
    return axios.post(`${settings.apiBaseURL}/plans/${id}/campaign-ads-update`, params)
  }

  static getResultLocationKeywordDrilldown(id) {
    return axios.get(`${settings.apiBaseURL}/plan-location-results/${id}/keyword-drilldown`)
  }

  static getResultLocationDrilldown(id) {
    return axios.get(`${settings.apiBaseURL}/plan-location-results/${id}/location-drilldown`)
  }

  static getCampaign(id) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/get-campaign`)
  }

  static createCampaign(id, params) {
    return axios.post(`${settings.apiBaseURL}/plans/${id}/create-campaign`, params)
  }

  static removeCampaign(id) {
    return axios.post(`${settings.apiBaseURL}/plans/${id}/remove-campaign`)
  }

  static updateCampaign(id, params) {
    return axios.patch(`${settings.apiBaseURL}/plans/${id}/update-campaign`, params)
  }

  static createAd(id, params) {
    return axios.post(`${settings.apiBaseURL}/plans/${id}/create-ad`, params)
  }

  static createBidModifier(id, params) {
    return axios.post(`${settings.apiBaseURL}/plans/${id}/create-bid-modifier`, params)
  }

  static createSiteLink(id, params) {
    return axios.post(`${settings.apiBaseURL}/plans/${id}/create-site-link`, params)
  }

  static createConversionAction(id, params) {
    return axios.post(`${settings.apiBaseURL}/plans/${id}/create-conversion-action`, params)
  }

  static updateConversionAction(id, params) {
    return axios.patch(`${settings.apiBaseURL}/plans/${id}/update-conversion-action`, params)
  }

  static getConversionAction(id) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/get-conversion-action`)
  }

  static getLanguages() {
    return axios.get(`${settings.apiBaseURL}/languages`)
  }

  static getCampaignReport(id) {
    return axios.get(`${settings.apiBaseURL}/plans/${id}/get-campaign-report`)
  }

  static getCustomerReport(date) {
    return axios.get(`${settings.apiBaseURL}/plans/get-customer-report`, {params: {date}})
  }

  static getBiddingStrategyReport(date) {
    return axios.get(`${settings.apiBaseURL}/plans/get-bidding-strategy-report`, {params: {date}})
  }
}
