import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/styles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import {withRouter} from 'react-router'
import {Grid, Typography, Button} from '@material-ui/core'
import {loadStripe} from '@stripe/stripe-js'
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js'
import moment from 'moment'
import {SwitchButton, styles} from './styles'
import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications'
import TierPlanCard from './TierPlanCard/TierPlanCard'
import settings from '../../../config/settings'
import {updateHeaderTitle} from '../common/redux/actions.header'
import {getProfileSuccess} from '../profile/redux/actions'
import PaymentMethod from './PaymentMethod/PaymentMethod'
import CancelModal from './CancelModal/CancelModal'
import SubscriptionModal from './SubscriptionModal/SubscriptionModal'
import UpdateModal from './UpdateModal/UpdateModal'
import BillingApi from './api'
import ProfileApi from '../profile/api'

function Billing({
  classes,
  updateHeaderTitle,
  createNotificationFromError,
  createSuccess,
  getProfileSuccess,
}) {
  const stripePromise = loadStripe(settings?.stripeKey)
  const [isYearly, setIsYearly] = React.useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [profile, setProfile] = useState(null)
  const [tierPlans, setTierPlans] = useState(null)
  const [chosenPlan, setChosenPlan] = useState(null)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false)
  const [customer, setCustomer] = useState(null)
  const [fetchingCustomer, setFetchingCustomer] = useState(false)
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(null)
  const [fetchingCustomerPaymentMethods, setFetchingCustomerPaymentMethods] = useState(false)
  const [addPaymentMethodOpen, setAddPaymentMethodOpen] = useState(false)
  const [hasSubscriptionChanged, setHasSubscriptionChanged] = useState(false)
  const [hasPaymentMethodChanged, setHasPaymentMethodChanged] = useState(false)
  const [error, setError] = useState(null)

  const [editPaymentMethod, setEditPaymentMethod] = useState(null)
  const [billingDetails, setBillingDetails] = useState({
    city: '',
    country: '',
    line1: '',
    line2: '',
    postal_code: '',
    state: '',
    name: '',
    expirationDate: null,
    ccv: '',
    last4: '',
  })

  const handleChange = event => {
    setIsYearly(event.target.checked)
  }

  useEffect(() => {
    updateHeaderTitle('')
    fetchTierPlans()
    getCustomer()
    getProfile()
    if (hasSubscriptionChanged) {
      setHasSubscriptionChanged(false)
    }
    return () => {
      updateHeaderTitle('')
    }
  }, [hasSubscriptionChanged])

  useEffect(() => {
    updateHeaderTitle('')
    if (hasPaymentMethodChanged) {
      getCustomer()
      setHasPaymentMethodChanged(false)
    }
    return () => {
      updateHeaderTitle('')
    }
  }, [hasPaymentMethodChanged])

  async function getProfile() {
    try {
      setIsLoading(true)
      const response = await ProfileApi.getProfile()
      setProfile(response)
      getProfileSuccess(response)
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsLoading(false)
    }
  }
  async function fetchTierPlans() {
    try {
      setIsLoading(true)
      const response = await BillingApi.getTierPlans({is_active: true})
      setTierPlans(response.results)
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsLoading(false)
    }
  }
  async function getCustomer() {
    try {
      setFetchingCustomer(true)
      const response = await BillingApi.getCustomer()
      setCustomer(response)
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setFetchingCustomer(false)
      getCustomerPaymentMethods()
    }
  }
  async function getCustomerPaymentMethods() {
    try {
      setFetchingCustomerPaymentMethods(true)
      const response = await BillingApi.getCustomerPaymentMethods()
      setCustomerPaymentMethods(response.data)
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setFetchingCustomerPaymentMethods(false)
    }
  }
  // use for Customer Card part
  function handleAddPayment() {
    setIsSubscriptionModalOpen(false)
    setAddPaymentMethodOpen(true)
    setError(null)
    setBillingDetails({
      city: '',
      country: '',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
      name: '',
      expirationDate: null,
      ccv: '',
      last4: '',
    })
  }
  function handleClosePaymentMethod() {
    setError(null)
    setAddPaymentMethodOpen(false)
    setEditPaymentMethod(null)
    setBillingDetails({
      city: '',
      country: '',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
      name: '',
      expirationDate: null,
      ccv: '',
      last4: '',
    })
  }
  function handleCancelClick() {
    setIsCancelModalOpen(true)
  }

  function onCancelCloseModal() {
    setIsCancelModalOpen(false)
  }

  function handleChosenClick(plan) {
    setChosenPlan(plan)
    setIsSubscriptionModalOpen(true)
  }

  function onChosenCloseModal() {
    setIsSubscriptionModalOpen(false)
    setChosenPlan(null)
  }

  function haveSubscription() {
    return _.get(profile, 'ownerAccount.tierPlan', false)
  }

  async function handleCancelSubscription() {
    try {
      setIsLoading(true)
      await BillingApi.cancelSubscription()
      createSuccess(
        'You have successfully cancelled your subscription. ' +
          'Your full plan access will continue til the end of your billing cycle ' +
          'at which time your access will be limited and will no longer be able to create new Growth Plans.',
      )
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsLoading(false)
      setIsCancelModalOpen(false)
      setHasSubscriptionChanged(true)
    }
  }

  async function handleSubscribe(plan) {
    try {
      setIsSubscribing(true)
      await BillingApi.subscribe({
        plan: isYearly ? plan.yearlyNickname : plan.monthlyNickname,
      })
      createSuccess('Subscription was successfully created.')
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsSubscribing(false)
      setIsSubscriptionModalOpen(false)
      setHasSubscriptionChanged(true)
    }
  }

  async function handleUpdate(plan) {
    const nextPlan = isYearly ? plan.yearlyNickname : plan.monthlyNickname
    const payload = {nextPlan}
    const updateSubscription = () => {
      return BillingApi.updateSubscription(payload)
    }
    try {
      setIsSubscribing(true)
      const response = await updateSubscription()
      createSuccess(response.msg)
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsSubscribing(false)
      setIsSubscriptionModalOpen(false)
      setHasSubscriptionChanged(true)
    }
  }

  const getCurrentPlan = () => _.get(profile, 'ownerAccount.tierPlan.name')
  const getCurrentSubscription = () =>
    _.get(profile, 'ownerAccount.subscription.items[0].price.nickname')

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <Grid item style={{marginTop: '20px'}}>
          <Typography className={classes.title} component="div">
            Current Plan
          </Typography>
        </Grid>
        <Grid container item direction="column" justify="center" alignItems="center">
          <Typography component="div" style={{textAlign: 'center'}}>
            {_.get(profile, 'ownerAccount.tierPlan')
              ? `${getCurrentPlan()}`
              : "You don't have a Plan yet!"}
          </Typography>
          {_.get(profile, 'ownerAccount.subscription.cancelAt') && (
            <Typography component="div" style={{textAlign: 'center'}}>
              {'Your plan will be canceled at ' +
                moment(Date.parse(_.get(profile, 'ownerAccount.subscription.cancelAt'))).format(
                  'MM/DD/YYYY',
                )}
            </Typography>
          )}
          {!_.get(profile, 'ownerAccount.subscription.cancelAt') &&
            _.get(profile, 'ownerAccount.subscription') && (
              <Button
                onClick={handleCancelClick}
                className={classes.cancelButton}
                disableRipple
                variant="text"
                size="small"
                color="inherit"
              >
                Cancel
              </Button>
            )}
        </Grid>

        <Grid item>
          <Typography className={classes.title} style={{marginTop: '20px'}} component="div">
            Available Plans
          </Typography>
        </Grid>

        <Grid item>
          <Typography component="div" className={classes.switch}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item className={classes.switchLabel}>
                monthly
              </Grid>
              <Grid item>
                <SwitchButton checked={isYearly} onChange={handleChange} name="isYearly" />
              </Grid>
              <Grid item className={classes.switchLabel}>
                yearly
              </Grid>
            </Grid>
          </Typography>
        </Grid>

        <Grid item container direction="row" justify="center">
          {tierPlans &&
            tierPlans.map((plan, index) => (
              <Grid item key={index}>
                <TierPlanCard
                  tierPlan={plan}
                  isYearly={isYearly}
                  onClickChosen={handleChosenClick}
                  onClickCancel={handleCancelClick}
                  currentPlan={getCurrentPlan()}
                  currentSubscriptionName={getCurrentSubscription()}
                />
              </Grid>
            ))}
        </Grid>
        {isCancelModalOpen && (
          <CancelModal
            currentPlan={getCurrentPlan()}
            isOpen={isCancelModalOpen}
            onClose={onCancelCloseModal}
            onClickCancel={handleCancelSubscription}
          />
        )}
        {isSubscriptionModalOpen && !haveSubscription() && (
          <SubscriptionModal
            isOpen={isSubscriptionModalOpen}
            onClose={onChosenCloseModal}
            isYearly={isYearly}
            chosenPlan={chosenPlan}
            onClickSubscribe={handleSubscribe}
            customer={customer}
            handleAddPayment={handleAddPayment}
            isSubscribing={isSubscribing}
          />
        )}
        {isSubscriptionModalOpen && haveSubscription() && (
          <UpdateModal
            currentPlan={getCurrentPlan()}
            isOpen={isSubscriptionModalOpen}
            onClose={onChosenCloseModal}
            isYearly={isYearly}
            chosenPlan={chosenPlan}
            onClickConfirm={handleUpdate}
            customer={customer}
            handleAddPayment={handleAddPayment}
            isSubscribing={isSubscribing}
          />
        )}

        <Grid item container>
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({stripe, elements}) => (
                <PaymentMethod
                  handleAddPayment={handleAddPayment}
                  handleClosePaymentMethod={handleClosePaymentMethod}
                  editPaymentMethod={editPaymentMethod}
                  setEditPaymentMethod={setEditPaymentMethod}
                  setBillingDetails={setBillingDetails}
                  billingDetails={billingDetails}
                  addPaymentMethodOpen={addPaymentMethodOpen}
                  setAddPaymentMethodOpen={setAddPaymentMethodOpen}
                  stripe={stripe}
                  elements={elements}
                  customer={customer}
                  customerPaymentMethods={customerPaymentMethods}
                  setHasSubscriptionChanged={setHasSubscriptionChanged}
                  profile={profile}
                  setHasPaymentMethodChanged={setHasPaymentMethodChanged}
                  fetchingCustomer={fetchingCustomer}
                  fetchingCustomerPaymentMethods={fetchingCustomerPaymentMethods}
                  setError={setError}
                  error={error}
                  isSignup={false}
                />
              )}
            </ElementsConsumer>
          </Elements>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {getProfileSuccess, createNotificationFromError, createSuccess, updateHeaderTitle},
    dispatch,
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(Billing)
