import React, {useState, useEffect} from 'react'
import {
  Dialog,
  DialogTitle,
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Link,
} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {withRouter} from 'react-router'
import _ from 'lodash'
import AutoComplete from '../common/Autocomplete'
import InputTextField from '../common/styles/InputTextField.jsx'
import {CustomTooltip} from './GrowthPlan.styles.js'
import {BiddingStrategyType} from './CreateCampaignDialog.const'
import NumberFormatInput from '../common/NumberFormatInput.jsx'
import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications'
import {styles} from './CreateCampaignDialog.styles'
import {useAxios} from '../api/axios'

function PortfolioBiggingStrategy({
  classes,
  biddingStrategy,
  setBiddingStrategy,
  manualTargetCPA,
  setManualTargetCPA,
  manualTargetROAS,
  setManualTargetROAS,
  targetCPA,
  setTargetCPA,
  targetROAS,
  setTargetROAS,
  isShopping,
  isDisplay,
  onBiddingStrategyChange,
  portfolioStrategy,
  setPortfolioStrategy,
  newPortfolioStrategyName,
  setNewPortfolioStrategyName,
  createNewPortfolioBidding,
  setCreateNewPortfolioBidding,
}) {
  const [{data: strategies, loading, error}] = useAxios(`/campaigns/bidding-strategies`, {
    useCache: false,
  })

  function handleRadioChange(event) {
    setCreateNewPortfolioBidding(event.target.value)
  }

  if (loading) {
    return (
      <Grid item sm={12} className={classes.inputGrid}>
        Loading...
      </Grid>
    )
  }

  if (error) {
    console.error(error)
    return (
      <Grid item sm={12} className={classes.inputGrid}>
        Error loading the portfolio strategies
      </Grid>
    )
  }

  const strategiesOptions = strategies.map(strategy => ({value: strategy.id, label: strategy.name}))

  return (
    <React.Fragment>
      <RadioGroup
        fullWidth
        value={createNewPortfolioBidding}
        onChange={handleRadioChange}
        className={classes.portfolioSource}
      >
        <Grid item sm={12} className={classes.inputGrid}>
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="Use existing portfolio strategy"
          />
        </Grid>

        {createNewPortfolioBidding === 'false' && (
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label} id="BiddingStrategyConfiguration">
              Portfolio Strategies
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip Text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <AutoComplete
              required
              className={classes.inputField}
              value={portfolioStrategy}
              options={strategiesOptions}
              placeholder="Choose existing Portfolio Strategy"
              onChange={value => setPortfolioStrategy(value)}
            />
          </Grid>
        )}

        <FormControlLabel value="true" control={<Radio />} label="Create new portfolio strategy" />

        {createNewPortfolioBidding === 'true' && (
          <React.Fragment>
            <Grid item sm={6} className={classes.inputGrid}>
              <Typography className={classes.label}>Portfolio Strategy Name *</Typography>
              <InputTextField
                withValidator
                required
                shouldValidateOnBlur={true}
                type="text"
                value={newPortfolioStrategyName}
                onChange={e => setNewPortfolioStrategyName(e.target.value)}
                inputProps={{
                  maxLength: 40,
                  'aria-label': 'name',
                }}
              />
            </Grid>

            {biddingStrategy.value === 'TARGET_CPA' && (
              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>Target CPA *</Typography>
                <InputTextField
                  required
                  withValidator
                  shouldValidateOnBlur={true}
                  type="string"
                  value={targetCPA}
                  onChange={e => setTargetCPA(e.target.value)}
                  placeholder="$"
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    prefix: '$',
                    maxLength: 18,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                />
              </Grid>
            )}

            {biddingStrategy.value === 'TARGET_ROAS' && (
              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>Target ROAS *</Typography>

                <InputTextField
                  required
                  withValidator
                  shouldValidateOnBlur={true}
                  type="string"
                  name="targetROAS"
                  value={targetROAS}
                  onChange={e => setTargetROAS(e.target.value)}
                  placeholder="%"
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    sufix: '%',
                    maxLength: 18,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                />
              </Grid>
            )}
          </React.Fragment>
        )}
      </RadioGroup>
    </React.Fragment>
  )
}

function StandardBiggingStrategy({
  classes,
  biddingStrategy,
  setBiddingStrategy,
  manualTargetCPA,
  setManualTargetCPA,
  manualTargetROAS,
  setManualTargetROAS,
  targetCPA,
  setTargetCPA,
  targetROAS,
  setTargetROAS,
  isShopping,
  isDisplay,
  onBiddingStrategyChange,
}) {
  return (
    <React.Fragment>
      {biddingStrategy?.value === 'TARGET_ROAS' && (
        <Grid item sm={12} className={classes.inputGrid}>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                value={manualTargetROAS}
                checked={manualTargetROAS}
                onChange={e => setManualTargetROAS(!manualTargetROAS)}
              />
            }
            label={
              <Typography className={classes.checkboxLabel}>
                Set a target return on ad spend (optional)
              </Typography>
            }
            labelPlacement="start"
          />
        </Grid>
      )}

      {biddingStrategy?.value === 'TARGET_CPA' && (
        <Grid item sm={12} className={classes.inputGrid}>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                value={manualTargetCPA}
                checked={manualTargetCPA}
                onChange={e => setManualTargetCPA(!manualTargetCPA)}
              />
            }
            label={
              <Typography className={classes.checkboxLabel}>
                Set a target cost per action (optional)
              </Typography>
            }
            labelPlacement="start"
          />
        </Grid>
      )}

      {manualTargetCPA && (
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>
            Custom Target CPA
            <CustomTooltip
              disableFocusListener
              disableTouchListener
              title="Tooltip Text"
              placement="top"
              className={classes.tooltip}
            >
              <InfoOutlinedIcon className={classes.icon} />
            </CustomTooltip>
          </Typography>

          <InputTextField
            withValidator
            shouldValidateOnBlur={true}
            type="string"
            name="targetCPA"
            value={targetCPA}
            onChange={e => setTargetCPA(e.target.value)}
            placeholder="$"
            inputComponent={NumberFormatInput}
            inputProps={{
              prefix: '$',
              maxLength: 18,
              fixedDecimalScale: true,
              decimalScale: 2,
            }}
          />
        </Grid>
      )}

      {manualTargetROAS && (
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>
            Custom Target ROAS
            <CustomTooltip
              disableFocusListener
              disableTouchListener
              title="Tooltip Text"
              placement="top"
              className={classes.tooltip}
            >
              <InfoOutlinedIcon className={classes.icon} />
            </CustomTooltip>
          </Typography>

          <InputTextField
            withValidator
            shouldValidateOnBlur={true}
            type="string"
            name="targetROAS"
            value={targetROAS}
            onChange={e => setTargetROAS(e.target.value)}
            placeholder="%"
            inputComponent={NumberFormatInput}
            inputProps={{
              sufix: '%',
              maxLength: 18,
              fixedDecimalScale: true,
              decimalScale: 2,
            }}
          />
        </Grid>
      )}
    </React.Fragment>
  )
}

function CreateCampaignBiddingConfiguration(props) {
  const {
    classes,
    biddingStrategy,
    setBiddingStrategy,
    manualTargetCPA,
    setManualTargetCPA,
    manualTargetROAS,
    setManualTargetROAS,
    targetCPA,
    setTargetCPA,
    targetROAS,
    setTargetROAS,
    isShopping,
    isDisplay,
    onBiddingStrategyChange,
    portfolioStrategy,
    setPortfolioStrategy,
  } = props

  useEffect(() => {
    if (biddingStrategy?.value === 'TARGET_ROAS') {
      usePortfolioStrategy()
    }

    if (biddingStrategy?.value === 'MAXIMIZE_CONVERSIONS') {
      setManualTargetCPA(true)
      setManualTargetROAS(false)
      useStandardStrategy()
    }
  }, [biddingStrategy])

  const [strategy, setStrategy] = useState('standard')

  function usePortfolioStrategy() {
    setStrategy('portfolio')
  }

  function useStandardStrategy() {
    setStrategy('standard')
  }

  return (
    <React.Fragment>
      <Grid item sm={12} className={classes.inputGrid}>
        <Typography className={classes.label} id="BiddingStrategyConfiguration">
          Bidding Strategy Configuration *
          <CustomTooltip
            disableFocusListener
            disableTouchListener
            title="Tooltip Text"
            placement="top"
            className={classes.tooltip}
          >
            <InfoOutlinedIcon className={classes.icon} />
          </CustomTooltip>
        </Typography>
        <AutoComplete
          required
          className={classes.inputField}
          value={biddingStrategy}
          options={
            isShopping() || isDisplay()
              ? BiddingStrategyType.filter(
                  i =>
                    i.value !== 'TARGET_CPA' &&
                    i.value !== 'TARGET_ROAS' &&
                    i.value !== 'MAXIMIZE_CONVERSIONS',
                )
              : BiddingStrategyType
          }
          placeholder="Bidding Strategy"
          onChange={onBiddingStrategyChange}
        />
      </Grid>

      {strategy === 'standard' && <StandardBiggingStrategy {...props} />}
      {strategy === 'portfolio' && <PortfolioBiggingStrategy {...props} />}

      {biddingStrategy?.value === 'TARGET_CPA' && (
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>
            {strategy === 'standard' && (
              <Link onClick={usePortfolioStrategy}>Use a portfolio strategy</Link>
            )}
            {strategy === 'portfolio' && (
              <Link onClick={useStandardStrategy}>Use a standard strategy</Link>
            )}
          </Typography>
        </Grid>
      )}
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
    schedule: state.schedule,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(CreateCampaignBiddingConfiguration)
