import React from 'react'
import {
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Paper,
  TableContainer,
  Divider,
  Typography,
} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import Loading from '../../common/Loading.jsx'
import styles from './GrowthReportsCampaign.styles'

function GrowthSearchTermsCampaign({classes, report, isLoading}) {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1)
  }
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer component={Paper} className={classes.reportsTable}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Search Term</TableCell>
                <TableCell align="center">Match type</TableCell>
                <TableCell align="center">Clicks</TableCell>
                <TableCell align="center">Cost</TableCell>
                <TableCell align="center">Impr.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {report.length > 0
                ? report.map(row => (
                    <TableRow key={row.name}>
                      <TableCell
                        align="center"
                        className={classes.reportsCellHeader}
                        component="th"
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {capitalize(row.searchTermMatchType.toLowerCase())}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {row.metricClick}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {row.metricCostMicros}
                      </TableCell>
                      <TableCell align="center" className={classes.reportsCellContent}>
                        {row.metricConversions}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default withStyles(styles)(GrowthSearchTermsCampaign)
