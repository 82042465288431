import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

class ButtonWithProgress extends React.Component {
  render() {
    const {isLoading, children, classes, loadingSize = 24, loadingStyle, ...others} = this.props
    return (
      <Button disabled={isLoading} {...others}>
        {children}
        {isLoading && (
          <CircularProgress
            size={loadingSize}
            style={loadingStyle}
            className={classes.buttonProgress}
          />
        )}
      </Button>
    )
  }
}

ButtonWithProgress.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

const styles = () => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

export default withStyles(styles)(ButtonWithProgress)
