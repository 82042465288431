import React, {useMemo, useEffect, useState} from 'react'
import {CircularProgress, Grid, Modal, Typography, withWidth} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {withRouter} from 'react-router'
import moment from 'moment'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import cx from 'classnames'
import {CardElement, useElements} from '@stripe/react-stripe-js'
import {createNotificationFromError, createSuccess} from '../../common/redux/actions.notifications'
import BillingApi from '../api'
import {CustomTextField, styles} from './styles'
import PaymentMethods from './PaymentMethods'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import SubmitButton from '../common/SubmitButton'

function PaymentMethod({
  classes,
  stripe,
  isSignup,
  handleAddPayment,
  handleSubscribe,
  handleClosePaymentMethod,
  addPaymentMethodOpen,
  setAddPaymentMethodOpen,
  setHasSubscriptionChanged,
  profile,
  customer,
  setEditPaymentMethod,
  setBillingDetails,
  billingDetails,
  editPaymentMethod,
  setHasPaymentMethodChanged,
  fetchingCustomer,
  error,
  setError,
  fetchingCustomerPaymentMethods,
  createSuccess,
  createNotificationFromError,
  customerPaymentMethods,
}) {
  const [processing, setProcessing] = useState(false)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  useEffect(() => {
    if (editPaymentMethod) {
      const {expMonth, expYear, last4} = editPaymentMethod.card
      const expDate = expMonth.toString() + '/' + expYear.toString().slice(-2)
      setPaymentMethodId(editPaymentMethod.id)
      setBillingDetails({
        ...billingDetails,
        city: editPaymentMethod.billingDetails.address.city,
        country: editPaymentMethod.billingDetails.address.country,
        line1: editPaymentMethod.billingDetails.address.line1,
        line2: editPaymentMethod.billingDetails.address.line2,
        postal_code: editPaymentMethod.billingDetails.address.postalCode,
        state: editPaymentMethod.billingDetails.address.state,
        name: editPaymentMethod.billingDetails.name,
        expirationDate: expDate,
        last4: '•••• •••• •••• ' + last4,
      })
    }
  }, [editPaymentMethod])

  const useOptions = () => {
    const fontSize = 12
    return useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#000000',
          },
        },
      }),
      [fontSize],
    )
  }
  const elements = useElements()
  const options = useOptions()

  if (!elements || !stripe) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return <CircularProgress />
  }

  function confirmDeleteCloseModal() {
    setConfirmDeleteModalOpen(false)
    setEditPaymentMethod(null)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      setProcessing(true)
      const billingDetailsFinal = {
        address: {
          city: billingDetails.city,
          country: billingDetails.country,
          line1: billingDetails.line1,
          line2: billingDetails.line2,
          postal_code: billingDetails.postal_code,
          state: billingDetails.state,
        },
        name: billingDetails.name,
      }

      const sourcePayload = await stripe.createSource(elements.getElement(CardElement), {
        type: 'card',
        currency: 'usd',
        owner: {
          email: profile.email,
        },
      })

      const paymentMethodPayload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: billingDetailsFinal,
      })
      await BillingApi.addPaymentMethod({
        payment_method_id: paymentMethodPayload.paymentMethod.id,
        source_id: sourcePayload.source.id,
      })
      if (isSignup) {
        handleSubscribe('PRO')
      }
      createSuccess('Card Added')
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setProcessing(false)
      setAddPaymentMethodOpen(false)
      setEditPaymentMethod(null)
      setHasSubscriptionChanged(true)
    }
  }

  function handleOpenConfirmDeleteModal() {
    setAddPaymentMethodOpen(false)
    setConfirmDeleteModalOpen(true)
  }

  const handleEdit = async event => {
    event.preventDefault()
    try {
      setProcessing(true)
      const expMonth = parseInt(billingDetails.expirationDate.split('/')[0], 10)
      const expYear = parseInt(
        moment()
          .year()
          .toString()
          .substring(0, 2) + billingDetails.expirationDate.split('/')[1],
        10,
      )

      const payload = {
        payment_method_id: paymentMethodId,
        city: billingDetails.city,
        country: billingDetails.country,
        line1: billingDetails.line1,
        line2: billingDetails.line2,
        postal_code: billingDetails.postal_code,
        state: billingDetails.state,
        name: billingDetails.name,
        exp_month: expMonth,
        exp_year: expYear,
      }
      await BillingApi.editPaymentMethod(payload)

      createSuccess('Payment Method Updated')
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setProcessing(false)
      setAddPaymentMethodOpen(false)
      setEditPaymentMethod(null)
      setPaymentMethodId(null)
      setHasPaymentMethodChanged(true)
    }
  }

  const handleDelete = async paymentMethod => {
    try {
      setIsDeleting(true)
      const payload = {
        payment_method_id: paymentMethod.id,
      }
      await BillingApi.deletePaymentMethod(payload)

      createSuccess('Payment Method Deleted')
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsDeleting(false)
      setConfirmDeleteModalOpen(false)
      setEditPaymentMethod(null)
      setHasPaymentMethodChanged(true)
    }
  }
  const handleMakePrimaryPaymentMethod = async () => {
    try {
      setProcessing(true)
      const payload = {
        payment_method_id: paymentMethodId,
      }
      await BillingApi.makePrimaryPaymentMethod(payload)

      createSuccess('Payment Method set as Primary')
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setProcessing(false)
      setAddPaymentMethodOpen(false)
      setEditPaymentMethod(null)
      setHasPaymentMethodChanged(true)
    }
  }

  const ErrorMessage = ({children}) => (
    <div className="ErrorMessage" role="alert">
      <svg width="16" height="16" viewBox="0 0 17 17">
        <path
          fill="#FFF"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        />
        <path
          fill="#6772e5"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        />
      </svg>
      {children}
    </div>
  )

  function renderAddPaymentMethodButton() {
    return (
      <Grid
        item
        xs={12}
        container
        className={isSignup ? classes.paymentButtonSignupDiv : null}
        alignItems="stretch"
      >
        <SubmitButton
          submit
          onClick={handleAddPayment}
          disabled={addPaymentMethodOpen}
          variant="contained"
        >
          Add a Credit Card
        </SubmitButton>
      </Grid>
    )
  }

  const addPaymentMethod = (
    <div className={classes.paymentPaper}>
      <form onSubmit={editPaymentMethod ? handleEdit : handleSubmit}>
        <div className={classes.formRow}>Card Information</div>
        {!editPaymentMethod ? (
          <fieldset className={classes.formGroup}>
            <CardElement
              options={options}
              onChange={e => {
                setError(e.error)
              }}
            />
          </fieldset>
        ) : null}

        <Grid container className={classes.textField} spacing={2}>
          {editPaymentMethod ? (
            <React.Fragment>
              <Grid item xs={6}>
                <div className={classes.formRowGrid}>Card Number</div>
                <CustomTextField
                  id="cardNumber"
                  variant="outlined"
                  placeholder="•••• •••• •••• ••••"
                  size="small"
                  name="cardNumber"
                  value={billingDetails.last4}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <div className={classes.formRowGrid}>Expiration date</div>
                <CustomTextField
                  id="expirationDate"
                  variant="outlined"
                  placeholder="MM/DD"
                  size="small"
                  name="expirationDate"
                  inputProps={{maxLength: 5}}
                  onChange={e => {
                    setBillingDetails({...billingDetails, expirationDate: e.target.value})
                  }}
                  value={billingDetails.expirationDate}
                  required
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid item xs={12}>
            <div className={classes.formRowGrid}>Name on Card</div>
            <CustomTextField
              id="Name"
              variant="outlined"
              placeholder="John Smith"
              size="small"
              name="name"
              onChange={e => {
                setBillingDetails({...billingDetails, name: e.target.value})
              }}
              value={billingDetails.name}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.formRowGrid}>Billing Address</div>
            <CustomTextField
              id="Country"
              variant="outlined"
              inputProps={{maxLength: 3}}
              placeholder="Country *US*"
              size="small"
              name="country"
              onChange={e => {
                setBillingDetails({...billingDetails, country: e.target.value})
              }}
              value={billingDetails.country}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextField
              id="line1"
              variant="outlined"
              placeholder="Address line 1"
              size="small"
              name="line1"
              onChange={e => {
                setBillingDetails({...billingDetails, line1: e.target.value})
              }}
              value={billingDetails.line1}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              id="line2"
              variant="outlined"
              placeholder="Address line 2"
              size="small"
              name="line2"
              onChange={e => {
                setBillingDetails({...billingDetails, line2: e.target.value})
              }}
              value={billingDetails.line2}
              fullWidth
            />
          </Grid>
          <Grid item xs={!editPaymentMethod ? 12 : 6}>
            <CustomTextField
              id="city"
              variant="outlined"
              placeholder="City"
              size="small"
              name="city"
              onChange={e => {
                setBillingDetails({...billingDetails, city: e.target.value})
              }}
              value={billingDetails.city}
              required
              fullWidth
            />
          </Grid>
          {editPaymentMethod && (
            <Grid item xs={6}>
              <CustomTextField
                id="postal_code"
                variant="outlined"
                placeholder="Postal Code"
                size="small"
                inputProps={{maxLength: 5}}
                name="postal_code"
                onChange={e => {
                  setBillingDetails({...billingDetails, postal_code: e.target.value})
                }}
                value={billingDetails.postal_code}
                required
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CustomTextField
              id="state"
              variant="outlined"
              placeholder="State"
              size="small"
              name="state"
              onChange={e => {
                setBillingDetails({...billingDetails, state: e.target.value})
              }}
              value={billingDetails.state}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        {editPaymentMethod &&
          customer &&
          customer.defaultPaymentMethod.id !== editPaymentMethod.id && (
            <Grid item xs={12} className={classes.buttonField}>
              <SubmitButton
                variant="contained"
                className={classes.makePrimaryButton}
                onClick={() => handleMakePrimaryPaymentMethod()}
              >
                Make Primary Card
              </SubmitButton>
            </Grid>
          )}
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <Grid item xs={12} className={classes.buttonField}>
          <SubmitButton processing={processing} error={error} submit={true}>
            {editPaymentMethod ? 'Update Card' : 'Add Card'}
          </SubmitButton>
        </Grid>
        {editPaymentMethod && (
          <Grid item xs={12} className={classes.buttonField}>
            <SubmitButton
              variant="contained"
              className={classes.deleteButton}
              onClick={() => handleOpenConfirmDeleteModal()}
            >
              Delete Card
            </SubmitButton>
          </Grid>
        )}
      </form>
    </div>
  )
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={isSignup ? classes.paymentContainerSignup : classes.paymentContainer}
    >
      {!isSignup ? (
        <React.Fragment>
          <Grid item container xs={12} alignItems="center" justify="center">
            <Typography className={classes.selectPlanText} style={{marginTop: '20px'}}>
              Payment Methods
            </Typography>
          </Grid>

          {fetchingCustomer || fetchingCustomerPaymentMethods ? (
            <Grid container item xs={12} justify="center">
              {' '}
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container item xs={12} alignItems="center" justify="center">
              <PaymentMethods
                addPaymentMethodOpen={addPaymentMethodOpen}
                setAddPaymentMethodOpen={setAddPaymentMethodOpen}
                customer={customer}
                setEditPaymentMethod={setEditPaymentMethod}
                customerPaymentMethods={customerPaymentMethods}
                setHasSubscriptionChanged={setHasSubscriptionChanged}
              />
            </Grid>
          )}
        </React.Fragment>
      ) : null}
      {renderAddPaymentMethodButton()}
      <Modal
        open={addPaymentMethodOpen}
        onClose={handleClosePaymentMethod}
        className={classes.addPaymentMethodModal}
      >
        {addPaymentMethod}
      </Modal>
      {confirmDeleteModalOpen && (
        <ConfirmDeleteModal
          isOpen={confirmDeleteModalOpen}
          onClose={confirmDeleteCloseModal}
          isDeleting={isDeleting}
          paymentMethod={editPaymentMethod}
          onDelete={handleDelete}
          customer={customer}
        />
      )}
    </Grid>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
  }
}

PaymentMethod.propTypes = {}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess}, dispatch)
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withRouter,
  withStyles(styles),
)(PaymentMethod)
