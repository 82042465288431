export const GET_PLAN = 'GET_PLAN'
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS'
export const GET_PLAN_FAILURE = 'GET_PLAN_FAILURE'
export const CLEAR_PLAN = 'CLEAR_PLAN'

export const SUBMIT_PLAN = 'SUBMIT_PLAN'
export const SUBMIT_PLAN_SUCCESS = 'SUBMIT_PLAN_SUCCESS'
export const SUBMIT_PLAN_FAILURE = 'SUBMIT_PLAN_FAILURE'

export const UPDATE_LOCAL = 'UPDATE_LOCAL'
