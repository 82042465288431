import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Grid, Typography, Link} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {withRouter} from 'react-router'

import autobind from 'autobind-decorator'
import {blue} from '@material-ui/core/colors'
import numeral from 'numeral'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import EditConfirmationDialog from './EditConfirmationDialog'
import styles from './GrowthEditPlanBar.styles.js'
import Autocomplete from '../common/Autocomplete'
import InputValidator from '../common/InputValidator'
import PlanApi from './redux/api'
import InputTextField from '../common/styles/InputTextField'
import NumberFormatInput from '../common/NumberFormatInput'
import ButtonWithProgress from '../common/ButtonWithProgress'
import {submitPlan} from './redux/actions'
import ValidatedComponent from '../common/ValidatedComponent'
import {keywordMatchTypeOpptions} from './GrowthPlan.const'
import LocationsSelect from './LocationsSelect'

const formatNumber = value => {
  if (!value) return ''
  return numeral(value).format('0,0.[00]')
}

class GrowthEditPlanBar extends React.Component {
  constructor(props) {
    super(props)
    const {plan} = props
    this.state = {
      isExpanded: true,
      plan: {
        id: plan.id,
        locations: plan.locations.features.map(location => ({
          ...location.properties,
          value: location.id,
          label: location.properties.name,
        })),
        keywords: plan.keywords,
        negativeKeywords: plan.negativeKeywords || '',
        conversionRate: plan.conversionRate,
        closingRate: plan.closingRate,
        revenuePerContract: plan.revenuePerContract,
        estimatedProfitMargin: plan.estimatedProfitMargin,
        managementFee: plan.managementFee || '',
        language: plan.language || '',
        maxCpc: plan.maxCpc || '',
        keywordMatchType: plan.keywordMatchType,
      },
      isSubmitting: false,
      isLoadingLanguages: false,
      isOpenEditConfDialog: false,
      showAdvanced: false,
      languages: [],
    }
  }

  async componentDidMount() {
    await this.loadLanguages()
    this.loadPlanCurrentMatchType()
    this.loadPlanCurrentLanguage()
  }

  loadPlanCurrentLanguage() {
    const {plan} = this.props
    this.setState(state => ({
      plan: {
        ...state.plan,
        language: plan.language
          ? state.languages.find(language => plan.language == language.value)
          : null,
      },
    }))
  }

  loadPlanCurrentMatchType() {
    const {plan} = this.props
    this.setState(state => ({
      plan: {
        ...state.plan,
        keywordMatchType: plan.keywordMatchType
          ? keywordMatchTypeOpptions.find(option => plan.keywordMatchType == option.value)
          : null,
      },
    }))
  }

  @autobind
  onLocationChange(value) {
    this.setState(state => ({
      plan: {
        ...state.plan,
        locations: value,
      },
    }))
    return value
  }

  @autobind
  onLanguageChange(value) {
    this.setState(state => ({
      plan: {
        ...state.plan,
        language: value,
      },
    }))
    return value
  }

  @autobind
  onMatchTypeChange(value) {
    this.setState(state => ({
      plan: {
        ...state.plan,
        keywordMatchType: value,
      },
    }))
    return value
  }

  willCreateNewPlan() {
    const {plan} = this.props
    const {keywords, negativeKeywords, language, maxCpc, keywordMatchType} = plan
    const locations = plan.locations.features.map(location => ({
      value: location.id,
    }))
    const newLocations = this.state.plan.locations.map(({value}) => ({value}))
    if (keywords !== this.state.plan.keywords) return true
    if ((negativeKeywords || '') !== this.state.plan.negativeKeywords) return true
    if (locations.length !== newLocations.length) return true
    if ((maxCpc || '') !== this.state.plan.maxCpc) return true
    if (language != _.get(this.state.plan.language, 'value')) return true
    if (keywordMatchType !== this.state.plan.keywordMatchType.value) return true
    return !_.isEqual(locations.sort(), newLocations.sort())
  }

  @autobind
  handlePlanSubmit(e) {
    e.preventDefault()
    if (this.willCreateNewPlan()) {
      this.setState({isOpenEditConfDialog: true})
    } else {
      this.handleContinue()
    }
  }

  @autobind
  handleCancel() {
    this.setState({isOpenEditConfDialog: false})
  }

  @autobind
  handleContinue() {
    if (this.props.isComponentValid()) {
      this.setState({isSubmitting: true, isOpenEditConfDialog: false}, async () => {
        const payload = {
          ...this.state.plan,
          locations: this.state.plan.locations.map(location => location.value),
          language: _.get(this.state.plan, 'language.value'),
          keywordMatchType: _.get(this.state.plan, 'keywordMatchType.value'),
        }
        this.props.submitPlan(payload, this.onSubmitted, true)
      })
    }
  }

  @autobind
  onSubmitted(success, plan) {
    this.setState({isSubmitting: false}, () => {
      if (success) {
        this.props.router.push(`/plan/${plan.id}`)
        this.toggleExpanded()
        window.location.reload()
      }
    })
  }

  async loadLanguages() {
    this.setState({isLoadingLanguages: true})
    const response = await PlanApi.getLanguages()
    const languages = response.map(item => ({
      label: item.languageName,
      value: item.criterionId,
    }))
    this.setState({languages})
    this.setState({isLoadingLanguages: false})
  }

  onChange(fieldName, e) {
    const {value} = e.target
    this.setState(state => ({
      plan: {
        ...state.plan,
        [fieldName]: value,
      },
    }))
  }

  @autobind
  toggleExpanded() {
    this.setState(state => ({isExpanded: !state.isExpanded}))
  }

  @autobind
  toggleAdvanced() {
    this.setState(prevState => ({...prevState, showAdvanced: !prevState.showAdvanced}))
  }

  render() {
    const {classes} = this.props
    const {isExpanded, plan, isSubmitting, isLoadingLanguages} = this.state
    const {
      errors,
      locations,
      keywords,
      conversionRate,
      closingRate,
      revenuePerContract,
      estimatedProfitMargin,
      managementFee,
      negativeKeywords,
      language,
      maxCpc,
      keywordMatchType,
    } = plan

    return (
      <React.Fragment>
        <Grid container className={classes.planContainer} spacing={0}>
          <Grid
            container
            className={classes.headerRow}
            spacing={0}
            style={!isExpanded ? {height: 70} : {}}
          >
            <Grid item xs={5} />
            <Grid item sm={2} style={{textAlign: 'center', position: 'relative', bottom: 25}}>
              <Grid item sm={12}>
                {isExpanded && (
                  <ExpandLessIcon
                    style={{color: blue[500], cursor: 'pointer'}}
                    onClick={this.toggleExpanded}
                  />
                )}
                {!isExpanded && (
                  <ExpandMoreIcon
                    style={{color: blue[500], cursor: 'pointer'}}
                    onClick={this.toggleExpanded}
                  />
                )}
              </Grid>
              <Grid item sm={12}>
                <Typography onClick={this.toggleExpanded} className={classes.editCampaign}>
                  Edit Plan
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={5} />
          </Grid>
          <Grid
            container
            style={isExpanded ? {display: 'none'} : {paddingLeft: 30, paddingRight: 30}}
            spacing={0}
          >
            <Grid item md={2} xl={2} />
            <Grid container xs={12} item spacing={0}>
              <Grid item sm={6} xs={12}>
                <FormGroup row>
                  <InputLabel className={classes.label} htmlFor="keywords" style={{marginLeft: 15}}>
                    Keywords
                  </InputLabel>
                  <InputTextField
                    withValidator
                    id="keywords"
                    classes={{
                      validator: classes.validator,
                      input: classes.input,
                    }}
                    style={{minHeight: 60}}
                    shouldValidateOnBlur={true}
                    errors={_.get(errors, 'keywords', [])}
                    type="text"
                    required
                    name="keywords"
                    value={keywords}
                    onChange={this.onChange.bind(this, 'keywords')}
                    placeholder="Keywords"
                    inputProps={{'aria-label': 'naked'}}
                  />
                </FormGroup>
              </Grid>
              <Grid item sm={6} xs={12} style={{paddingLeft: 15}}>
                <InputLabel className={classes.label} htmlFor="location">
                  Region
                </InputLabel>
                <InputValidator
                  required
                  shouldValidateOnValueChange={true}
                  shouldValidateOnBlur={true}
                  errors={_.get(errors, 'location', [])}
                >
                  <LocationsSelect
                    required
                    id="locations"
                    value={locations}
                    selectProps={{classes}}
                    classes={{
                      input: classes.input,
                      textInput: classes.autoCompleteTextInput,
                      interimText: classes.autoCompleteinterImText,
                      valueContainer: classes.valueContainer,
                      singleValue: classes.singleValue,
                      paper: classes.paper,
                    }}
                    onChange={this.onLocationChange}
                  />
                </InputValidator>
              </Grid>
            </Grid>

            <Grid sm={12} md={6} xl={6} item spacing={0} className={classes.numberFieldsContainer}>
              <div className={classes.numberField}>
                <InputLabel
                  className={classes.label}
                  htmlFor="conversionRate"
                  style={{marginLeft: 16}}
                >
                  Conversion Rate
                </InputLabel>
                <InputTextField
                  withValidator
                  required
                  id="conversionRate"
                  classes={{
                    input: classes.input,
                  }}
                  shouldValidateOnBlur={true}
                  errors={_.get(errors, 'conversionRate', [])}
                  type="text"
                  name="conversionRate"
                  placeholder="%"
                  value={formatNumber(conversionRate)}
                  onChange={this.onChange.bind(this, 'conversionRate')}
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    suffix: '%',
                    maxLength: 18,
                  }}
                />
              </div>
              <div className={classes.numberField}>
                <InputLabel
                  className={classes.label}
                  htmlFor="closingRate"
                  style={{marginLeft: 16}}
                >
                  Closing Rate
                </InputLabel>
                <InputTextField
                  withValidator
                  id="closingRate"
                  required
                  classes={{
                    input: classes.input,
                  }}
                  shouldValidateOnBlur={true}
                  errors={_.get(errors, 'closingRate', [])}
                  type="text"
                  name="closingRate"
                  placeholder="%"
                  value={formatNumber(closingRate)}
                  onChange={this.onChange.bind(this, 'closingRate')}
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    suffix: '%',
                    maxLength: 18,
                  }}
                />
              </div>
              <div className={classes.numberField}>
                <InputLabel
                  className={classes.label}
                  htmlFor="revenuePerContract"
                  style={{marginLeft: 16}}
                >
                  Average Sale
                </InputLabel>
                <InputTextField
                  id="revenuePerContract"
                  withValidator
                  shouldValidateOnBlur={true}
                  classes={{
                    input: classes.input,
                  }}
                  errors={_.get(errors, 'revenuePerContract', [])}
                  type="text"
                  inputComponent={NumberFormatInput}
                  required
                  name="revenuePerContract"
                  value={formatNumber(revenuePerContract)}
                  onChange={this.onChange.bind(this, 'revenuePerContract')}
                  placeholder="$"
                  inputProps={{
                    'aria-label': 'bare',
                    prefix: '$',
                  }}
                />
              </div>
              <div className={classes.numberField}>
                <InputLabel
                  className={classes.label}
                  htmlFor="estimatedProfit"
                  style={{marginLeft: 16}}
                >
                  Profit Margin
                </InputLabel>
                <InputTextField
                  withValidator
                  id="estimatedProfit"
                  shouldValidateOnBlur={true}
                  classes={{
                    input: classes.input,
                  }}
                  errors={_.get(errors, 'estimatedProfitMargin', [])}
                  type="text"
                  inputComponent={NumberFormatInput}
                  required
                  name="estimatedProfitMargin"
                  value={formatNumber(estimatedProfitMargin)}
                  onChange={this.onChange.bind(this, 'estimatedProfitMargin')}
                  placeholder="%"
                  inputProps={{
                    'aria-label': 'bare',
                    suffix: '%',
                  }}
                />
              </div>
              <div className={classes.numberField}>
                <InputLabel
                  className={classes.label}
                  htmlFor="managementFee"
                  style={{marginLeft: 16}}
                >
                  Management Fee
                </InputLabel>
                <InputTextField
                  withValidator
                  id="managementFee"
                  shouldValidateOnBlur={true}
                  classes={{
                    input: classes.input,
                  }}
                  errors={_.get(errors, 'managementFee', [])}
                  type="text"
                  inputComponent={NumberFormatInput}
                  name="managementFee"
                  value={formatNumber(managementFee)}
                  onChange={this.onChange.bind(this, 'managementFee')}
                  placeholder="0"
                  inputProps={{
                    'aria-label': 'bare',
                  }}
                />
              </div>
            </Grid>

            <Grid item md={6} sm={12} xl={6} xs={12} className={classes.textAlignRight}>
              <ButtonWithProgress
                className={classes.submitButton}
                isLoading={isSubmitting}
                onClick={this.handlePlanSubmit}
                variant="contained"
                fullWidth
                style={{textTransform: 'capitalize'}}
              >
                Update Results
              </ButtonWithProgress>
            </Grid>
            <Grid item sm={12} md={6} xl={6} />
            <Grid
              item
              justify="flex-end"
              md={6}
              sm={12}
              xl={6}
              xs={12}
              className={classes.textAlignRight}
            >
              <Link
                href="#"
                onClick={this.toggleAdvanced}
                className={classes.advancedOptions}
                underline="none"
              >
                Advanced
              </Link>
            </Grid>
            <Grid container xs={12} item spacing={0}>
              {this.state.showAdvanced && (
                <>
                  <Grid item sm={3} xs={12}>
                    <FormGroup row>
                      <InputLabel
                        className={classes.label}
                        htmlFor="keywords"
                        style={{marginLeft: 15}}
                      >
                        Negative Keywords
                      </InputLabel>
                      <InputTextField
                        withValidator
                        id="keywords"
                        classes={{
                          validator: classes.validator,
                          input: classes.input,
                        }}
                        style={{minHeight: 60}}
                        shouldValidateOnBlur={true}
                        errors={_.get(errors, 'keywords', [])}
                        type="text"
                        name="negativeKeywords"
                        value={negativeKeywords}
                        onChange={this.onChange.bind(this, 'negativeKeywords')}
                        placeholder="Negative Keywords"
                        inputProps={{'aria-label': 'naked'}}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item sm={3} xs={12}>
                    <FormGroup row>
                      <InputLabel className={classes.label} htmlFor="language">
                        Language
                      </InputLabel>
                      <InputValidator
                        shouldValidateOnValueChange={true}
                        shouldValidateOnBlur={true}
                        errors={_.get(errors, 'language', [])}
                        classes={{
                          validator: classes.validator,
                        }}
                      >
                        <Autocomplete
                          classes={{
                            input: classes.input,
                            textInput: classes.autoCompleteTextInput,
                            interimText: classes.autoCompleteinterImText,
                            valueContainer: classes.valueContainer,
                            singleValue: classes.singleValue,
                            paper: classes.paper,
                          }}
                          selectProps={{classes}}
                          style={{marginLeft: 16}}
                          value={language}
                          options={this.state.languages}
                          placeholder=""
                          onChange={this.onLanguageChange}
                          isLoading={isLoadingLanguages}
                        />
                      </InputValidator>
                    </FormGroup>
                  </Grid>

                  <Grid item sm={3} xs={12}>
                    <FormGroup row>
                      <InputLabel
                        className={classes.label}
                        htmlFor="maxCpc"
                        style={{marginLeft: 16}}
                      >
                        Max CPC
                      </InputLabel>
                      <InputTextField
                        id="maxCpc"
                        withValidator
                        shouldValidateOnBlur={true}
                        classes={{
                          validator: classes.validator,
                          input: classes.input,
                        }}
                        style={{height: '60px'}}
                        errors={_.get(errors, 'maxCpc', [])}
                        type="text"
                        inputComponent={NumberFormatInput}
                        name="maxCpc"
                        value={formatNumber(maxCpc)}
                        onChange={this.onChange.bind(this, 'maxCpc')}
                        placeholder="$"
                        inputProps={{
                          'aria-label': 'bare',
                          prefix: '$',
                        }}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item sm={3} xs={12}>
                    <FormGroup row>
                      <InputLabel className={classes.label} htmlFor="language">
                        Match Type
                      </InputLabel>
                      <InputValidator
                        shouldValidateOnValueChange={true}
                        shouldValidateOnBlur={true}
                        errors={_.get(errors, 'keywordMatchType', [])}
                        classes={{
                          validator: classes.validator,
                        }}
                      >
                        <Autocomplete
                          classes={{
                            input: classes.input,
                            textInput: classes.autoCompleteTextInput,
                            interimText: classes.autoCompleteinterImText,
                            valueContainer: classes.valueContainer,
                            singleValue: classes.singleValue,
                            paper: classes.paper,
                          }}
                          selectProps={{classes}}
                          value={keywordMatchType}
                          options={keywordMatchTypeOpptions}
                          placeholder=""
                          onChange={this.onMatchTypeChange}
                        />
                      </InputValidator>
                    </FormGroup>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <EditConfirmationDialog
          isOpen={this.state.isOpenEditConfDialog}
          onClickContinue={this.handleContinue}
          onClickCancel={this.handleCancel}
        />
      </React.Fragment>
    )
  }
}

GrowthEditPlanBar.displayName = 'GrowthEditPlanBar'

GrowthEditPlanBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({submitPlan}, dispatch)
}

function mapStateToProps() {
  return {}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true}),
  withRouter,
  ValidatedComponent,
)(GrowthEditPlanBar)
