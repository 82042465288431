import React, {useState} from 'react'
import {Button, DialogTitle, Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {withRouter} from 'react-router'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import {connect} from 'react-redux'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {styles} from './CreateCampaignDialog.styles'
import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications'
import PlanApi from './redux/api'
import InputTextField from '../common/styles/InputTextField'
import {CustomTooltip} from './GrowthPlan.styles'

const CreateSiteLink = ({classes, onSkip, plan, createSuccess, createNotificationFromError}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [slToAdd, setSlToAdd] = useState([])
  const [linkText, setLinkText] = useState('')
  const [description1, setDescription1] = useState('')
  const [description2, setDescription2] = useState('')
  const [finalUrl, setFinalUrl] = useState('')

  const onSiteLinkChange = e => setLinkText(e.target.value)
  const onDescription1Change = e => setDescription1(e.target.value)
  const onDescription2Change = e => setDescription2(e.target.value)
  const onFinalUrlChange = e => setFinalUrl(e.target.value)

  const handleSkip = () => {
    onSkip()
  }

  const canAdd = () => {
    return !_.isEmpty(linkText) && !_.isEmpty(finalUrl)
  }

  const createNewSl = () => {
    const body = {
      link_text: linkText,
      description_1: description1,
      description_2: description2,
      finalUrl,
    }
    if (finalUrl) {
      body.final_url =
        finalUrl.trim().startsWith('http://') || finalUrl.trim().startsWith('https://')
          ? finalUrl.trim()
          : `https://${finalUrl.trim()}`
    }
    return body
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await PlanApi.createSiteLink(plan.plan.id, slToAdd)
      createSuccess('Site Link created successfully.')
      handleSkip()
    } catch (e) {
      createNotificationFromError(`An Error occurred when creating Site Link: ${e}`)
    } finally {
      setIsLoading(false)
    }
  }

  const resetParameters = () => {
    setLinkText('')
    setDescription1('')
    setDescription2('')
    setFinalUrl('')
  }

  const handleAdd = () => {
    const newSl = createNewSl()
    setSlToAdd([...slToAdd, newSl])
    resetParameters()
  }

  const removeSlToAdd = index => {
    const newSlToAdd = [...slToAdd]
    newSlToAdd.splice(index, 1)
    setSlToAdd(newSlToAdd)
  }

  const renderSlToAdd = () => {
    return slToAdd.map((sl, index) => (
      <Grid container sm={12} style={{marginTop: '16px'}}>
        <Grid item sm={11}>
          <Typography className={classes.label}>{sl.link_text.toUpperCase()}</Typography>
        </Grid>
        <Grid item sm={1}>
          <DeleteForeverIcon onClick={() => removeSlToAdd(index)} />
        </Grid>
      </Grid>
    ))
  }

  return (
    <>
      <Grid item sm={12} className={classes.center}>
        <DialogTitle
          id="edit-confirmation-dialog-title"
          className={classes.dialogTitle}
          disableTypography
        >
          Create Site Links
        </DialogTitle>
      </Grid>
      <Grid container spacing={1} style={{marginLeft: '-14px'}}>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>Site Link Text *</Typography>
          <InputTextField
            withValidator
            required
            shouldValidateOnBlur={true}
            type="text"
            name="linkText"
            value={linkText}
            onChange={onSiteLinkChange}
            inputProps={{
              'aria-label': 'linkText',
            }}
          />
        </Grid>{' '}
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>Description 1</Typography>
          <InputTextField
            withValidator
            shouldValidateOnBlur={true}
            type="text"
            name="description1"
            value={description1}
            onChange={onDescription1Change}
            inputProps={{
              'aria-label': 'description1',
            }}
          />
        </Grid>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>Description 2</Typography>
          <InputTextField
            withValidator
            shouldValidateOnBlur={true}
            type="text"
            name="description2"
            value={description2}
            onChange={onDescription2Change}
            inputProps={{
              'aria-label': 'description2',
            }}
          />
        </Grid>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>
            Final Url *
            <CustomTooltip
              disableFocusListener
              disableTouchListener
              title="Ex: https://example.com"
              placement="top"
              className={classes.tooltip}
            >
              <InfoOutlinedIcon className={classes.icon} />
            </CustomTooltip>
          </Typography>

          <InputTextField
            withValidator
            required
            shouldValidateOnBlur={true}
            type="text"
            name="finalUrl"
            value={finalUrl}
            onChange={onFinalUrlChange}
            inputProps={{
              'aria-label': 'finalUrl',
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={12} className={classes.center} style={{marginBottom: '16px'}}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.bmAdd}
          onClick={handleAdd}
          disabled={!canAdd() || isLoading}
        >
          Add Site Link
        </Button>
      </Grid>
      {renderSlToAdd()}
      <Grid item sm={12} className={classes.buttons}>
        <Grid item sm={12} className={classes.center}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.bmCreate}
            onClick={handleSubmit}
            disabled={!slToAdd.length || isLoading}
          >
            Create Site Link
          </Button>
        </Grid>
        <Grid item sm={12} className={classes.center}>
          <Button size="small" className={classes.cancelButton} onClick={handleSkip}>
            Skip
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(CreateSiteLink)
