import React from 'react'
import {IndexRoute, Route} from 'react-router'
import {authenticateOnEnter} from './utils/RoutingUtils'
import App from './App.jsx'
import NotFound from './common/NotFound.Page.jsx'
import About from './about/About.jsx'
import Help from './terms/Help.jsx'
import Login from './auth/Login.jsx'
import Signup from './auth/Signup/Signup.jsx'
import ForgotPasswordRequest from './auth/ForgotPasswordRequest.jsx'
import ForgotPasswordConsume from './auth/ForgotPasswordConsume.jsx'
import Profile from './profile/Profile.jsx'
import ProfileInfo from './profile/ProfileInfo.jsx'
import ProfileSecurity from './profile/ProfileSecurity.jsx'
import ConfirmEmail from './profile/ConfirmEmail.jsx'
import ChangeEmailConfirm from './profile/ChangeEmailConfirm.jsx'
import ChangeEmailVerify from './profile/ChangeEmailVerify.jsx'
import PublicUser from './publicUser/PublicUser.jsx'
import StyleGuide from './styleguide/styleguide.jsx'
import Support from './terms/Support.jsx'
import Use from './terms/Use.jsx'
import Privacy from './terms/Privacy.jsx'
import GrowthPlan from './growthPlan/GrowthPlan.jsx'
import GrowthResults from './growthPlan/GrowthResults.jsx'
import GrowthResultsTable from './growthPlan/GrowthResultsTable.jsx'
import ForecastResultsTable from './growthPlan/ForecastResultsTable.jsx'
import PlansHistory from './plansHistory/PlanHistory.jsx'
import PlansHistoryList from './plansHistory/PlanHistoryList.jsx'
import Billing from './billing/Billing'
import GrowthReports from './growthPlan/reports/GrowthReports'
import CustomerReport from './growthPlan/reports/CustomerReport'

export default function routing(store) {
  const authenticate = authenticateOnEnter.bind(this, store)

  return (
    <Route path="/" component={App}>
      <IndexRoute component={GrowthPlan} onEnter={authenticate} />
      <Route path="/plan/:id" component={GrowthResults} onEnter={authenticate}>
        <IndexRoute component={GrowthResultsTable} onEnter={authenticate} />
        <Route path="forecast" component={ForecastResultsTable} onEnter={authenticate} />
      </Route>
      <Route path="/plan/:id/reports" component={GrowthReports} onEnter={authenticate} />
      <Route path="/dashboard" component={CustomerReport} onEnter={authenticate} />
      <Route path="/billing" component={Billing} onEnter={authenticate} />
      <Route path="/history" component={PlansHistory} onEnter={authenticate}>
        <IndexRoute component={PlansHistoryList} />
      </Route>
      <Route path="/about" component={About} />
      <Route path="/styleguide" component={StyleGuide} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/signup/:plan" component={Signup} />
      <Route path="/signup/:plan/:frequency" component={Signup} />
      <Route path="/forgot" component={ForgotPasswordRequest} />
      <Route path="/forgot-password/:token" component={ForgotPasswordConsume} />
      <Route path="/profile" component={Profile} onEnter={authenticate}>
        <IndexRoute component={ProfileInfo} />
        <Route path="security" component={ProfileSecurity} />
      </Route>
      <Route path="/confirm-email/:id/:token" component={ConfirmEmail} />
      <Route path="/change-email/:id/:token" component={ChangeEmailConfirm} />
      <Route path="/change-email-verify/:id/:token" component={ChangeEmailVerify} />
      <Route path="/users/:id" component={PublicUser} />
      <Route path="/help" component={Help} />
      <Route path="/support" component={Support} />
      <Route path="/terms" component={Use} />
      <Route path="/privacy" component={Privacy} />
      {/* should always be at the end */}
      <Route path="*" component={NotFound} />
    </Route>
  )
}
