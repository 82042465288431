const styles = theme => ({
  title: {
    color: '#747474',
    fontSize: 34,
    fontWeight: 200,
    lineHeight: '45px',
    textAlign: 'center',
    marginTop: 64,
    '& > span > strong': {
      color: '#00235e',
      fontWeight: 700,
    },
  },
  dashboardContainer: {
    borderTop: '1px solid #A2A2A2',
    borderBottom: '1px solid #A2A2A2',
    marginTop: theme.spacing(3),
    marginBottom: 32,
  },
  dashboardItem: {
    height: 285,
    borderLeft: '1px solid #A2A2A2',
    textAlign: 'center',
  },
  dashboardMapItem: {
    borderLeft: 'none',
  },
  totalImpressionsNumber: {
    fontSize: 56,
    fontWeight: 300,
    lineHeight: '70px',
    color: '#040D14',
    display: 'block',
    marginTop: theme.spacing(5),
  },
  totalImpressionsLabel: {
    fontSize: 28,
    fontWeight: 300,
    lineHeight: '36px',
    color: '#040D14',
    textTransform: 'uppercase',
  },
  dashboardImpressionsBar: {
    width: `calc(100% - ${theme.spacing(8)})`,
    margin: theme.spacing(4, 4, 0, 4),
  },
  dashboardImpressionsBarItem: {
    height: 48,
    display: 'inline-block',
  },
  totalRevenueNumber: {
    display: 'block',
    fontSize: 28,
    color: '#040D14',
    fontWeight: 300,
    lineHeight: '36px',
    marginTop: theme.spacing(5),
  },
  totalRevenueLabel: {
    display: 'block',
    fontSize: 16,
    color: '#3A3A3A',
    fontWeight: 600,
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  totalProfitNumber: {
    display: 'block',
    fontSize: 56,
    color: '#47B258',
    fontWeight: 600,
    lineHeight: '70px',
    marginTop: theme.spacing(2),
  },
  totalProfitLabel: {
    display: 'block',
    fontSize: 16,
    color: '#47B258',
    fontWeight: 600,
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  baseOnSpendLabel: {
    display: 'block',
    color: '#BBBBBB',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    textTransform: 'uppercase',
    marginTop: theme.spacing(6),
  },
})
export default styles
