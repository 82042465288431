import React from 'react'
import {Paper, Grid, Modal} from '@material-ui/core'

import {BodyText, Title, useStyles} from './styles'
import SubmitButton from '../common/SubmitButton'

export default function CancelModal({isOpen, onClose, onClickCancel, currentPlan}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Modal className={classes.modal} open={isOpen} onBackdropClick={onClose}>
        <Paper className={classes.paper}>
          <Grid item xs={12} align="center">
            <Title>Subscription Cancellation</Title>
          </Grid>
          <Grid item xs={12}>
            <BodyText style={{fontSize: '.8em'}}>Current Plan:</BodyText>

            <BodyText style={{fontWeight: 700}}>{currentPlan}</BodyText>
          </Grid>
          <Grid item xs={12}>
            <BodyText style={{padding: '8px'}}>
              Your Current plan will be cancelled at the end of your billing period. Your full plan
              access will continue till the end of your billing cycle at which time your access will
              be limited and will no longer be able to create new Growth Plans.
            </BodyText>
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="stretch"
            className={classes.containerButton}
            alignContent="flex-end"
          >
            <SubmitButton submit variant="contained" color="primary" onClick={onClickCancel}>
              Cancel Subscription
            </SubmitButton>
          </Grid>
        </Paper>
      </Modal>
    </div>
  )
}
