import React, {useState, useEffect} from 'react'
import {DialogTitle, Grid, Button, Typography, Slider} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {withRouter} from 'react-router'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import {connect} from 'react-redux'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AutoComplete from '../common/Autocomplete'
import InputTextField from '../common/styles/InputTextField.jsx'
import {CustomTooltip} from './GrowthPlan.styles.js'
import {Devices, Level} from './CreateCampaignDialog.const'
import {styles} from './CreateCampaignDialog.styles'
import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications'
import PlanApi from './redux/api'
import NumberFormatInput from '../common/NumberFormatInput.jsx'

const CreateBidModifier = ({
  classes,
  onSkip,
  plan,
  response,
  createSuccess,
  createNotificationFromError,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [bmToAdd, setBmToAdd] = useState([])
  const [bidModifierValue, setBidModifierValue] = useState(0)
  const [device, setDevice] = useState('')
  const [level, setLevel] = useState('')

  const onBidModifierChange = (event, newValue) => setBidModifierValue(newValue)
  const onDeviceChange = value => setDevice(value)
  const onLevelChange = value => setLevel(value)

  const handleSkip = () => {
    onSkip()
    // resetInput()
  }

  const canAdd = () => {
    return !_.isEmpty(device) && !_.isEmpty(level)
  }

  const createNewBm = () => {
    const body = {
      bid_modifier_value: bidModifierValue,
      device: device.value,
      level: level.value,
    }
    if (body.level === 'campaign') {
      body.campaign_id = response.campaignId
    }
    if (body.level === 'ad_group') {
      body.ad_group_id = response.adGroupId
    }
    return body
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await PlanApi.createBidModifier(plan.plan.id, bmToAdd)
      createSuccess('Bid Modifiers created successfully.')
      handleSkip()
    } catch (e) {
      createNotificationFromError(`An Error ocurred when creating Bid Modifiers: ${e}`)
    } finally {
      setIsLoading(false)
    }
  }

  const resetParameters = () => {
    setDevice('')
    setLevel('')
  }

  const handleAdd = () => {
    const newBm = createNewBm()
    setBmToAdd([...bmToAdd, newBm])
    resetParameters()
  }

  const removeBmToAdd = index => {
    const newBmToAdd = [...bmToAdd]
    newBmToAdd.splice(index, 1)
    setBmToAdd(newBmToAdd)
  }

  const renderBmToAdd = () => {
    return bmToAdd.map((bm, index) => (
      <Grid container sm={12} style={{marginTop: '16px'}}>
        <Grid item sm={11}>
          <Typography className={classes.label}>
            {bm.level.toUpperCase()} {bm.device} {bm.bid_modifier_value}%
          </Typography>
        </Grid>
        <Grid item sm={1}>
          <DeleteForeverIcon onClick={() => removeBmToAdd(index)} />
        </Grid>
      </Grid>
    ))
  }

  return (
    <>
      <Grid item sm={12} className={classes.center}>
        <DialogTitle
          id="edit-confirmation-dialog-title"
          className={classes.dialogTitle}
          disableTypography
        >
          Create Devices Bid Modifiers
        </DialogTitle>
      </Grid>
      <Grid container spacing={1} style={{marginLeft: '-14px'}}>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>
            Bid Modifier %
            <CustomTooltip
              disableFocusListener
              disableTouchListener
              title="Values between -90% and 900%"
              placement="top"
              className={classes.tooltip}
            >
              <InfoOutlinedIcon className={classes.icon} />
            </CustomTooltip>
          </Typography>
          <Grid item sm={12} style={{padding: '24px'}}>
            <Slider
              style={{marginLeft: '14px'}}
              value={bidModifierValue}
              getAriaValueText={() => bidModifierValue + '%'}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              onChange={onBidModifierChange}
              step={1}
              marks
              min={-90}
              max={900}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>
            Device
            <CustomTooltip
              disableFocusListener
              disableTouchListener
              title="Tooltip Text"
              placement="top"
              className={classes.tooltip}
            >
              <InfoOutlinedIcon className={classes.icon} />
            </CustomTooltip>
          </Typography>
          <AutoComplete
            required
            className={classes.inputField}
            value={device}
            options={Devices}
            placeholder="Device"
            onChange={onDeviceChange}
          />
        </Grid>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label}>
            Level
            <CustomTooltip
              disableFocusListener
              disableTouchListener
              title="Tooltip Text"
              placement="top"
              className={classes.tooltip}
            >
              <InfoOutlinedIcon className={classes.icon} />
            </CustomTooltip>
          </Typography>
          <AutoComplete
            required
            className={classes.inputField}
            value={level}
            options={Level}
            placeholder="Level"
            onChange={onLevelChange}
          />
        </Grid>
      </Grid>
      <Grid item sm={12} className={classes.center} style={{marginBottom: '16px'}}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.bmAdd}
          onClick={handleAdd}
          disabled={!canAdd() || isLoading}
        >
          Add Bid Modifier
        </Button>
      </Grid>
      {renderBmToAdd()}
      <Grid item sm={12} className={classes.buttons}>
        <Grid item sm={12} className={classes.center}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.bmCreate}
            onClick={handleSubmit}
            disabled={!bmToAdd.length || isLoading}
          >
            Create Bid Modifiers
          </Button>
        </Grid>
        <Grid item sm={12} className={classes.center}>
          <Button size="small" className={classes.cancelButton} onClick={handleSkip}>
            Skip
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(CreateBidModifier)
