import React, {useState, useEffect} from 'react'
import {DialogTitle, Grid, Button, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {withRouter} from 'react-router'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import {connect} from 'react-redux'
import AutoComplete from '../common/Autocomplete'
import InputTextField from '../common/styles/InputTextField.jsx'
import {CustomTooltip} from './GrowthPlan.styles.js'
import {AdCreationType as AdCreationTypeOptions, AdCreationEnum} from './CreateCampaignDialog.const'
import {styles} from './CreateCampaignDialog.styles'
import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications'
import PlanApi from './redux/api'
import NumberFormatInput from '../common/NumberFormatInput.jsx'

const AdCreationForm = ({
  classes,
  onClose,
  plan,
  adGroup,
  createSuccess,
  createNotificationFromError,
}) => {
  const [adCreationType, setAdCreationType] = useState('')
  const [finalURLs, setFinalURLs] = useState('')
  const [finalURLsMobile, setFinalURLsMobile] = useState('')
  const onAdCreationChange = value => setAdCreationType(value)
  const [isLoading, setIsLoading] = useState(false)

  // ExpandedTextAd
  const [headlinePart1, setHeadlinePart1] = useState('')
  const [headlinePart2, setHeadlinePart2] = useState('')
  const [headlinePart3, setHeadlinePart3] = useState('')
  const [description, setDescription] = useState('') //Also used for ExpandedDynamicSearchAd and CallAdInfo
  const [description2, setDescription2] = useState('') //Also used for ExpandedDynamicSearchAd

  const onHeadlinePart1Change = e => setHeadlinePart1(e.target.value)
  const onHeadlinePart2Change = e => setHeadlinePart2(e.target.value)
  const onHeadlinePart3Change = e => setHeadlinePart3(e.target.value)
  const onDescriptionChange = e => setDescription(e.target.value)
  const onDescription2Change = e => setDescription2(e.target.value)

  // CallAdInfo
  const [businessName, setBusinessName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState()
  const onBusinessNameChange = e => setBusinessName(e.target.value)
  const onPhoneNumberChange = e => setPhoneNumber(e.target.value)

  useEffect(() => {
    if (adGroup.value !== 'SEARCH_STANDARD' && adGroup.value !== 'SEARCH_DYNAMIC_ADS') {
      onClose()
    }
  }, [adGroup])

  const adCreationOptions = {
    ExpandedTextAd: 'ExpandedTextAd',
    ExpandedDynamicSearchAd: 'ExpandedDynamicSearchAd',
    CallAdInfo: 'CallAdInfo',
  }

  const filteredAdCreationTypeOptions = () => {
    switch (adGroup.value) {
      case 'SEARCH_STANDARD':
        return AdCreationTypeOptions.filter(opt => opt.value !== 'ExpandedDynamicSearchAd')
      case 'SEARCH_DYNAMIC_ADS' /* ExpandedDynamicSearchAd is available only for SEARCH_DYNAMIC_ADS */:
        return AdCreationTypeOptions.filter(opt => opt.value === 'ExpandedDynamicSearchAd')
      default:
        break
    }
  }

  const expandedTextAd = () => {
    return (
      <>
        <Grid container spacing={1} style={{marginLeft: '-14px'}}>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Final URL(s) *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="The final URL(s) represents the actual landing page(s) for your ad, keyword, or sitelink. (Separated by commas ,)"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              shouldValidateOnBlur={true}
              type="text"
              name="finalURLs"
              value={finalURLs}
              onChange={e => setFinalURLs(e.target.value)}
              inputProps={{
                maxLength: 150,
                'aria-label': 'final URLs',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Final URL(s) Mobile
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="If you want to send users to a different landing page(s) on mobile devices you can provide a separate mobile-preferred final URL. (Separated by commas ,)"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              shouldValidateOnBlur={true}
              type="text"
              name="finalURLsMobile"
              value={finalURLsMobile}
              onChange={e => setFinalURLsMobile(e.target.value)}
              inputProps={{
                maxLength: 150,
                'aria-label': 'final URLs',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Headline Part 1 *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              required
              shouldValidateOnBlur={true}
              type="text"
              name="headlinePart1"
              value={headlinePart1}
              onChange={onHeadlinePart1Change}
              inputProps={{
                maxLength: 150,
                'aria-label': 'headlinePart1',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Headline Part 2 *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              required
              shouldValidateOnBlur={true}
              type="text"
              name="headlinePart2"
              value={headlinePart2}
              onChange={onHeadlinePart2Change}
              inputProps={{
                maxLength: 150,
                'aria-label': 'headlinePart2',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Headline Part 3 *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              required
              shouldValidateOnBlur={true}
              type="text"
              name="headlinePart3"
              value={headlinePart3}
              onChange={onHeadlinePart3Change}
              inputProps={{
                maxLength: 150,
                'aria-label': 'headlinePart3',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Description *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              required
              shouldValidateOnBlur={true}
              type="text"
              name="description"
              value={description}
              onChange={onDescriptionChange}
              inputProps={{
                maxLength: 150,
                'aria-label': 'description',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Description2
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              shouldValidateOnBlur={true}
              type="text"
              name="description"
              value={description2}
              onChange={onDescription2Change}
              inputProps={{
                maxLength: 150,
                'aria-label': 'description',
              }}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const expandedDynamicSearchAd = () => {
    return (
      <>
        <Grid container spacing={1} style={{marginLeft: '-14px'}}>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Description 1 *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              required
              shouldValidateOnBlur={true}
              type="text"
              name="description"
              value={description}
              onChange={onDescriptionChange}
              inputProps={{
                maxLength: 150,
                'aria-label': 'description',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Description 2
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              shouldValidateOnBlur={true}
              type="text"
              name="description2"
              value={description2}
              onChange={onDescription2Change}
              inputProps={{
                maxLength: 150,
                'aria-label': 'description2',
              }}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const callAdInfo = () => {
    return (
      <>
        <Grid container spacing={1} style={{marginLeft: '-14px'}}>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Business Name *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              required
              shouldValidateOnBlur={true}
              type="text"
              name="businessName"
              value={businessName}
              onChange={onBusinessNameChange}
              inputProps={{
                maxLength: 150,
                'aria-label': 'businessName',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Description *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Tooltip text"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              required
              shouldValidateOnBlur={true}
              type="text"
              name="description"
              value={description}
              onChange={onDescriptionChange}
              inputProps={{
                maxLength: 150,
                'aria-label': 'description',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Phone Number *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="Phone Number"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              shouldValidateOnBlur={true}
              required
              type="string"
              name="phoneNumber"
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              inputComponent={NumberFormatInput}
              inputProps={{
                maxLength: 18,
                thousandSeparator: false,
                format: '+1 (###) ###-####',
                mask: '_',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Final URL(s) *
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="The final URL(s) represents the actual landing page(s) for your ad, keyword, or business website. (Separated by commas ,)"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              required
              withValidator
              shouldValidateOnBlur={true}
              type="text"
              name="finalURLs"
              value={finalURLs}
              onChange={e => setFinalURLs(e.target.value)}
              inputProps={{
                maxLength: 150,
                'aria-label': 'final URLs',
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.inputGrid}>
            <Typography className={classes.label}>
              Final URL(s) Mobile
              <CustomTooltip
                disableFocusListener
                disableTouchListener
                title="If you want to send users to a different landing page(s) on mobile devices you can provide a separate mobile-preferred final URL. (Separated by commas ,)"
                placement="top"
                className={classes.tooltip}
              >
                <InfoOutlinedIcon className={classes.icon} />
              </CustomTooltip>
            </Typography>
            <InputTextField
              withValidator
              shouldValidateOnBlur={true}
              type="text"
              name="finalURLsMobile"
              value={finalURLsMobile}
              onChange={e => setFinalURLsMobile(e.target.value)}
              inputProps={{
                maxLength: 150,
                'aria-label': 'final URLs',
              }}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const resetInput = () => {
    setHeadlinePart1('')
    setHeadlinePart2('')
    setHeadlinePart3('')
    setDescription('')
    setDescription2('')
    setBusinessName('')
    setPhoneNumber('')
  }

  const handleClose = () => {
    onClose()
    resetInput()
  }

  const createPayload = () => {
    const type = adCreationType.value
    let body = {}
    switch (type) {
      case AdCreationEnum.ExpandedTextAd:
        body = {
          type,
          headlinePart_1: headlinePart1,
          headlinePart_2: headlinePart2,
          headlinePart_3: headlinePart3,
          description,
          description_2: description2,
        }
        break
      case AdCreationEnum.ExpandedDynamicSearchAd:
        body = {
          type,
          description,
          description_2: description2,
        }
        break
      case AdCreationEnum.CallAdInfo:
        body = {
          type,
          businessName,
          description,
          phoneNumber,
        }
        break
      default:
        break
    }

    if (finalURLs)
      body.final_urls = finalURLs.split(',').map(url => {
        const trimURL = url.trim()
        return trimURL.startsWith('http://') || trimURL.startsWith('https://')
          ? trimURL
          : `https://${trimURL}`
      })
    if (finalURLsMobile)
      body.final_urls_mobile = finalURLsMobile.split(',').map(url => {
        const trimURL = url.trim()
        return trimURL.startsWith('http://') || trimURL.startsWith('https://')
          ? trimURL
          : `https://${trimURL}`
      })

    return body
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    const payload = createPayload()

    try {
      await PlanApi.createAd(plan.plan.id, payload)
      createSuccess('Your Ad has been successfully created.')
      handleClose()
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const canSubmit = () => {
    const type = adCreationType.value
    let cond
    switch (type) {
      case AdCreationEnum.ExpandedTextAd:
        cond = !!headlinePart1 && !!headlinePart2 && !!headlinePart3 && !!description
        break
      case AdCreationEnum.ExpandedDynamicSearchAd:
        cond = !!description
        break
      case AdCreationEnum.CallAdInfo:
        cond = !!businessName && !!description && !!phoneNumber
        break
      default:
        break
    }
    return cond
  }

  return (
    <>
      <Grid item sm={12} className={classes.center}>
        <DialogTitle
          id="edit-confirmation-dialog-title"
          className={classes.dialogTitle}
          disableTypography
        >
          Ad Creation
        </DialogTitle>
      </Grid>
      <Grid container spacing={1} style={{marginLeft: '-14px'}}>
        <Grid item sm={12} className={classes.inputGrid}>
          <Typography className={classes.label} id="BiddingStrategyConfiguration">
            Ad Creation Type
            <CustomTooltip
              disableFocusListener
              disableTouchListener
              title="Tooltip text"
              placement="top"
              className={classes.tooltip}
            >
              <InfoOutlinedIcon className={classes.icon} />
            </CustomTooltip>
          </Typography>
          <AutoComplete
            required
            className={classes.inputField}
            value={adCreationType}
            options={filteredAdCreationTypeOptions()}
            placeholder="Ad Creation Type"
            onChange={onAdCreationChange}
          />
        </Grid>
      </Grid>
      {adCreationType.value === adCreationOptions.ExpandedTextAd && expandedTextAd()}
      {adCreationType.value === adCreationOptions.ExpandedDynamicSearchAd &&
        expandedDynamicSearchAd()}
      {adCreationType.value === adCreationOptions.CallAdInfo && callAdInfo()}
      <Grid item sm={12} className={classes.buttons}>
        <Grid item sm={12} className={classes.center}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.continueButton}
            onClick={handleSubmit}
            disabled={!canSubmit() || isLoading}
          >
            Create Ad
          </Button>
        </Grid>
        <Grid item sm={12} className={classes.center}>
          <Button size="small" className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

function mapStateToProps(state) {
  return {
    profile: _.get(state.profile, 'profile', null),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(AdCreationForm)
