import React from 'react'
import {
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Paper,
  TableContainer,
  Divider,
} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import Loading from '../../common/Loading.jsx'
import styles from './GrowthReportsCampaign.styles'

function BiddingStrategyReport({classes, report, isLoading}) {
  function displayType(s) {
    return s.replace('_', ' ')
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer component={Paper} className={classes.reportsTable}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Clicks</TableCell>
                <TableCell align="center">Cost</TableCell>
                <TableCell align="center">Cost per Conv.</TableCell>
                <TableCell align="center">Impr.</TableCell>
                <TableCell align="center">Conversions</TableCell>
                <TableCell align="center">Avg. CPC</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {report &&
                Object.entries(report).map(value => {
                  return (
                    <TableRow key={value[1].type}>
                      <TableCell
                        align="center"
                        className={classes.reportsCellHeader}
                        component="th"
                        scope="row"
                      >
                        {displayType(value[1].type)}
                      </TableCell>
                      <TableCell align="center">{value[1].status}</TableCell>
                      <TableCell align="center">{value[1].clicks}</TableCell>
                      <TableCell align="center">{value[1].costMicros}</TableCell>
                      <TableCell align="center">{value[1].costPerConversion}</TableCell>
                      <TableCell align="center">{value[1].impressions}</TableCell>
                      <TableCell align="center">{value[1].conversions}</TableCell>
                      <TableCell align="center">{value[1].averageCpc}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Divider />
    </>
  )
}

export default withStyles(styles)(BiddingStrategyReport)
