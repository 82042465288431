import React from 'react'
import {Typography, Divider} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import Loading from '../../common/Loading.jsx'
import styles from './CustomerReportMetrics.styles'

function CustomerReportMetrics({classes, report, isLoading}) {
  const {allConversions, clicks, conversions, costMicros: cost, impressions} = report

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classes.reportsTable}>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Clicks</Typography>
            <Typography className={classes.reportsCellContent}>{clicks || 0}</Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Cost</Typography>
            <Typography className={classes.reportsCellContent}>
              {`$${(Number(cost || 0) * 1000000).toFixed(2)}`}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Impressions</Typography>
            <Typography className={classes.reportsCellContent}>{impressions || 0}</Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Conversions</Typography>
            <Typography className={classes.reportsCellContent}>
              {Number(conversions || 0).toFixed(2)}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>All Conversions</Typography>
            <Typography className={classes.reportsCellContent}>
              {Number(allConversions || 0).toFixed(2)}
            </Typography>
          </div>
        </div>
      )}
      <Divider />
    </>
  )
}

export default withStyles(styles)(CustomerReportMetrics)
