const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 18%',
    marginTop: 32, //header height (80px) + padding (32px)
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerBtn: {
    color: theme.palette.Blue[600],
    borderColor: theme.palette.Blue[600],
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
    padding: '12px 17px 12px 20px',
  },
  title: {
    fontSize: 24,
    fontWeight: 400,
    color: theme.palette.Gray[800],
    paddingTop: 20,
    paddingBottom: 20,
  },
})
export default styles
