import {Grid, withStyles} from '@material-ui/core'

export const GridContainerPage = withStyles({
  'spacing-xs-4': {
    width: '100%',
    margin: 0,
  },
})(Grid)

export const GridItemPage = withStyles(theme => {
  return {
    // item: {
    //   width: '100%',
    //   height: '100%',
    //   flexGrow: 1,
    //   '&.MuiGrid-item': {
    //     padding: 0,
    //   },
    // },
    container: {
      backgroundColor: theme.palette.Gray[300],
      flex: 1,
    },
  }
})(Grid)
