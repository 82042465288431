const styles = theme => ({
  planContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: '#f3f3f3',
    position: 'fixed',
    bottom: 0,
    zIndex: 600,
    maxWidth: 1209,
  },
  headerRow: {
    height: 10,
  },
  inputGrid: {
    marginLeft: theme.spacing(3),
  },
  submitButton: {
    color: '#fff',
    fontFamily: 'Source Sans Pro',
    fontSize: `16px`,
    fontWeight: 600,
    lineHeight: `20px`,
    textAlign: `center`,
    height: `42px`,
    width: `160px`,
    borderRadius: `5px`,
    background: `#e3c05b`,
  },
  editCampaign: {
    color: '#9c9c9c',
    fontSize: 12,
    fontFamily: 'Source Sans Pro',
    cursor: 'pointer',
  },
  textAlignRight: {
    textAlign: `right`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  paper: {
    minWidth: 450,
    bottom: 80,
  },
  singleValue: {
    fontSize: 14,
    marginLeft: 3,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 100,
    marginTop: 0,
    marginBottom: 0,
    padding: 0,
  },
  valueContainer: {
    overflow: 'visible',
    flexWrap: 'wrap',
  },
  label: {
    fontFamily: 'Source Sans Pro',
    fontSize: `11px`,
    height: '20px',
    textAlign: 'left',
    justifySelf: 'center',
    fontWeight: 600,
    wordSpacing: -1,
    color: `#040D14`,
  },
  validator: {
    marginRight: 0,
  },
  input: {
    color: `#040D14`,
    height: 'auto',
    minHeight: 40,
    margin: '3px 0',
    padding: 0,
    fontFamily: 'Source Sans Pro',
    fontSize: `14px`,
    lineHeight: `16px`,
    textAlign: `center`,
    fontWeight: 400,
    border: 'black solid 1px',
    borderRadius: `1px`,
    backgroundColor: '#F7F7F7',
    marginLeft: props => (props.withValidator ? 0 : theme.spacing(1)),
    marginRight: props => (props.withValidator ? 0 : theme.spacing(1)),
  },
  autoCompleteTextInput: {
    paddingTop: 3,
    minHeight: 46,
  },
  autoCompleteinterImText: {
    fontSize: `14px`,
    lineHeight: `16px`,
    top: 0,
    paddingLeft: 3,
  },
  numberFieldsContainer: {
    display: 'flex',
    paddingTop: '8px',
  },
  advancedOptions: {
    width: '100%',
    margin: '0 10px',
    color: `#040D14`,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5,
    },
    '&:active': {
      opacity: 0.7,
    },
    fontSize: `16px`,
  },
})
export default styles
