import * as Types from './actions.types'

export function updateLocal(patch, rootKey) {
  return {type: Types.UPDATE_LOCAL, patch, rootKey}
}

export function submitPlan(payload, callback, isEdit) {
  return {type: Types.SUBMIT_PLAN, payload, callback, isEdit}
}

export function submitPlanSuccess(plan) {
  return {type: Types.SUBMIT_PLAN_SUCCESS, plan}
}

export function submitPlanFailure(error) {
  return {type: Types.SUBMIT_PLAN_FAILURE, error}
}

export function getPlan(planId, params) {
  return {type: Types.GET_PLAN, planId, params}
}

export function getPlanSuccess(plan) {
  return {type: Types.GET_PLAN_SUCCESS, plan}
}

export function getPlanFailure(error) {
  return {type: Types.GET_PLAN_FAILURE, error}
}

export function clearPlan() {
  return {type: Types.CLEAR_PLAN}
}
