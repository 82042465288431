import {Tooltip} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import ButtonWithProgress from '../common/ButtonWithProgress.jsx'

const styles = theme => ({
  background: {
    padding: '50px',
    width: `100%`,
    height: `auto`,
  },
  paper: {
    margin: `20px auto 40px`,
    padding: `15px`,
    textAlign: 'center',
  },
  titleContainer: {
    display: `inline-block`,
    padding: `15px 10px 0`,
    marginBottom: '-5px',
  },
  icon: {
    margin: `-3px 10px 0px`,
    width: '25px',
    position: 'absolute',
    color: '#979797',
    cursor: `pointer`,
    verticalAlign: `top`,
  },
  underline: {
    position: 'relative',
    textDecoration: 'none',
    '&:after': {
      content: '""',
      position: 'absolute',
      height: '3px',
      bottom: '-7px',
      margin: '0 auto',
      left: '0',
      right: '0',
      maxWidth: '6em',
      background: theme.palette.primary.dark,
    },
  },
  growthPlanHeader: {
    height: `49px`,
    color: '#00235e',
    margin: `0 auto`,
    fontFamily: 'Source Sans Pro',
    fontSize: '39px',
    fontWeight: 600,
    lineHeight: '49px',
    textAlign: 'center',
  },
  growthPlanForm: {
    marginTop: 8,
  },
  growthPlanFormContainer: {
    width: '90%',
    margin: '0 auto',
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: `right`,
  },
  advancedOptions: {
    width: '100%',
    margin: '0 10px',
    color: `#040D14`,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5,
    },
    '&:active': {
      opacity: 0.7,
    },
    fontSize: `16px`,
  },
  titles: {
    color: `#040D14`,
    display: `inline-block`,
    fontFamily: 'Source Sans Pro',
    fontSize: `18px`,
    lineHeight: `23px`,
    textAlign: `center`,
  },
  submitButton: {
    color: '#fff',
    fontFamily: 'Source Sans Pro',
    fontSize: `36px`,
    fontWeight: 600,
    lineHeight: `45px`,
    textAlign: `center`,
    height: `60px`,
    width: '100%',
    maxWidth: `361px`,
    borderRadius: `5px`,
    background: `#e3c05b`,
    '&:hover': {
      background: '#d0ad4a',
    },
  },
  submitButtonArrow: {
    color: `#fff`,
    fontSize: '25px',
    fontWeight: 600,
  },
  inputGrid: {
    padding: '15px 20px',
    textAlign: 'left',
    paddingLeft: 4,
  },
  inputField: {
    textAlign: 'left',
    minHeight: 68,
  },
  alignLeft: {
    textAlign: 'left',
  },
})

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: 14,
    color: '#040D14',
    backgroundColor: 'white',
    boxShadow: `0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)`,
  },
})(Tooltip)
export const GrowthPlanButtonWithProgress = withStyles({
  buttonProgress: {
    color: 'white',
    backgroundColor: 'green',
    boxShadow: `0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)`,
  },
})(ButtonWithProgress)

export default styles
