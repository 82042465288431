import React from 'react'
import {compose} from 'redux'
import {
  Grid,
  Paper,
  NoSsr,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Slider,
} from '@material-ui/core'
import LoopIcon from '@material-ui/icons/Loop'
import {withStyles} from '@material-ui/styles'
import PropTypes from 'prop-types'
import {ResponsiveContainer, LineChart, Line, ReferenceDot, XAxis, Label} from 'recharts'
import autobind from 'autobind-decorator'
import cls from 'classnames'
import numeral from 'numeral'
import qs from 'qs'
import PlanApi from './redux/api'

const formatNumber = value => numeral(value).format('0,0.[00]')
const formatCurrencyHeader = value => numeral(value).format('$0,0.[00]')
const formatCurrencyTable = value => numeral(value).format('$0,0')

const PrettoSlider = withStyles({
  root: {
    marginTop: 20,
    marginBottom: 20,
  },
  container: {
    padding: '34px',
  },
  track: {
    backgroundColor: '#D8D8D8',
    height: 13,
    borderRadius: '7px',
  },
  trackAfter: {
    opacity: 1,
  },
  thumb: {
    backgroundColor: '#F9F9F9',
    boxShadow: '0 1px 6px -1px rgba(0,0,0,0.5)',
    width: 30,
    height: 30,
  },
})(Slider)

class LocationOpportunity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: {
        monthlySpend: 0,
        revenue: 0,
      },
      activeItemIndex: 0,
      minMonthlyAdSpend: 0,
      maxMonthlyAdSpend: 0,
      sliderStep: 0,
      isLoading: true,
      page: 1,
      data: [],
      isFormulaShown: false,
    }
  }

  componentDidMount() {
    this.getOpportunity(this.props.selected)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.getOpportunity(this.props.selected)
    }
  }

  @autobind
  showFormula() {
    this.setState({isFormulaShown: true})
  }

  @autobind
  hideFormula() {
    this.setState({isFormulaShown: false})
  }

  @autobind
  changeMonthlyAdSpend(e, index) {
    this.setState(state => {
      const item = state.data[index]
      if (item) {
        return {activeItem: item, activeItemIndex: index}
      }
    })
  }

  @autobind
  togglePage() {
    this.setState(state => ({
      page: state.page === 1 ? 2 : 1,
    }))
  }

  getOpportunity(selected) {
    this.setState({isLoading: true}, () => {
      PlanApi.getOpportunity(this.props.planResults.plan.id, {
        params: {selected},
        paramsSerializer(params) {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        },
      })
        .then(response => {
          this.setState(response)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.setState({isLoading: false})
        })
    })
  }

  render() {
    const {classes} = this.props
    const {activeItem, activeItemIndex, page, data, isLoading} = this.state

    if (isLoading || !data) {
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )
    }

    const {plan} = this.props.planResults

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <div className={classes.revenueTitle}>Revenue Generated</div>
            <NoSsr>
              <ResponsiveContainer height={500} width="100%" className={classes.chartResponsive}>
                <LineChart data={data} className={classes.lineChart}>
                  <defs>
                    <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" stopColor="#F1FACF" />
                      <stop offset="50%" stopColor="#6FB65D" />
                      <stop offset="100%" stopColor="#E24D4D" />
                    </linearGradient>
                    <filter
                      id="dropshadow"
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                      filterUnits="userSpaceOnUse"
                    >
                      <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.3" />
                    </filter>
                  </defs>
                  <Line
                    type="monotone"
                    dataKey="revenue"
                    stroke="url(#colorUv)"
                    strokeWidth={5}
                    dot={false}
                    activeDot={false}
                  />
                  <XAxis dataKey="monthlySpend" hide={true} />
                  <ReferenceDot
                    x={activeItem.monthlySpend}
                    y={activeItem.revenue}
                    r={7}
                    fill="white"
                    stroke="none"
                    style={{filter: 'url(#dropshadow)'}}
                    label={
                      <Label
                        position="right"
                        className={classes.referenceDotLabel}
                        value={formatCurrencyHeader(Math.round(activeItem.revenue))}
                        content={props => {
                          const {
                            offset,
                            className,
                            value,
                            viewBox: {x, y},
                          } = props
                          return (
                            <React.Fragment>
                              {this.state.isFormulaShown && (
                                <foreignObject width="250" height="400" x={x} y={y + 36}>
                                  <div className={classes.formulaRect}>
                                    <div className={classes.formulaContainer}>
                                      &nbsp;&nbsp;{Math.round(activeItem.impressionsPerMonth)}&nbsp;
                                      <span className={classes.formulaLabel}>
                                        &nbsp;(impressions per month)
                                      </span>{' '}
                                      *&nbsp;{(plan.conversionRate / 100).toFixed(2)}&nbsp;
                                      <span className={classes.formulaLabel}>
                                        (conversion rate)
                                      </span>{' '}
                                      *&nbsp;{(plan.closingRate / 100).toFixed(2)}&nbsp;
                                      <span className={classes.formulaLabel}>(closing rate)</span>
                                    </div>
                                    <div className={classes.formulaContainer}>
                                      *&nbsp;{activeItem.customersPerMonth.toFixed(2)}&nbsp;
                                      <span className={classes.formulaLabel}>
                                        (customers per month)
                                      </span>{' '}
                                      *&nbsp;{parseFloat(plan.revenuePerContract).toFixed(2)}&nbsp;
                                      <span className={classes.formulaLabel}>
                                        (revenue per contract)
                                      </span>
                                    </div>
                                  </div>
                                </foreignObject>
                              )}
                              <text
                                offset={offset}
                                x={x}
                                y={y + 7}
                                className={className}
                                textAnchor="start"
                              >
                                <tspan
                                  x={x + 21}
                                  dy="0.355em"
                                  onMouseEnter={this.showFormula}
                                  onMouseLeave={this.hideFormula}
                                >
                                  {value}
                                </tspan>
                              </text>
                            </React.Fragment>
                          )
                        }}
                      />
                    }
                  />
                </LineChart>
              </ResponsiveContainer>
            </NoSsr>
            <div className={classes.chartBottom}>
              <Typography align="center" className={classes.monthlyTitle}>
                Monthly Ad Spend
              </Typography>
              <PrettoSlider
                value={activeItemIndex}
                step={1}
                min={0}
                max={data.length - 1}
                onChange={this.changeMonthlyAdSpend}
              />
              <Typography align="center" className={classes.monthlySpendTitle}>
                {formatCurrencyHeader(Math.round(activeItem.monthlySpend))}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5} className={classes.totalGrid}>
            <Typography align="right">
              <IconButton className={classes.loopButton} onClick={this.togglePage}>
                <LoopIcon />
              </IconButton>
            </Typography>
            {page === 1 ? (
              <React.Fragment>
                <div className={classes.totalWrap}>
                  <span>Total Monthly Revenue</span>
                  <strong>{formatCurrencyTable(Math.round(activeItem.revenue))}</strong>
                </div>
                <div className={cls(classes.totalWrap, classes.totalWrapProfit)}>
                  <span>Total Monthly Profit</span>
                  <strong>{formatCurrencyTable(Math.round(activeItem.totalMonthlyProfit))}</strong>
                </div>
                <div className={cls(classes.totalWrap, classes.totalWrapInvestiment)}>
                  <span>Return on Investment</span>
                  <strong>{formatNumber(activeItem.returnOfInvestment * 100)}%</strong>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={cls(classes.totalWrap, classes.totalWrapSmall)}>
                  <span>Total Monthly Impressions</span>
                  <strong>{formatNumber(Math.round(activeItem.totalImpressions))}</strong>
                </div>
                <div className={cls(classes.totalWrap, classes.totalWrapSmall)}>
                  <span>Impressions captured</span>
                  <strong>{formatNumber(Math.round(activeItem.impressionsCaptured))}%</strong>
                </div>
                <div className={cls(classes.totalWrap, classes.totalWrapSmall)}>
                  <span>Cost Per Lead</span>
                  <strong>{formatCurrencyTable(Math.round(activeItem.costPerLead))}</strong>
                </div>
                <div className={cls(classes.totalWrap, classes.totalWrapSmall)}>
                  <span>Revenue Per Lead</span>
                  <strong>{formatCurrencyTable(Math.round(activeItem.revenuePerLead))}</strong>
                </div>
                <div className={classes.editButtonWrapper}>
                  <Button variant="contained" color="secondary">
                    Edit
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

LocationOpportunity.displayName = 'LocationOpportunity'

LocationOpportunity.propTypes = {
  classes: PropTypes.object.isRequired,
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  dot: {
    boxShadow: '0 1px 6px -1px rgba(0,0,0,0.5)',
  },
  chartResponsive: {
    borderBottom: '1px dashed #999',
    borderLeft: '1px dashed #999',
    marginLeft: 45,
    marginBottom: 10,
  },
  revenueTitle: {
    transformOrigin: '11px 22px',
    transform: 'rotate(90deg)',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    color: '#3A3A3A',
    height: 22,
    width: 500,
  },
  monthlyTitle: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    color: '#3A3A3A',
  },
  monthlySpendTitle: {
    color: '#212223',
    fontSize: 42,
    fontWeight: 600,
  },
  chartBottom: {
    width: '100%',
    marginLeft: 45,
    marginBottom: 15,
  },
  referenceDotLabel: {
    color: '#040D14',
    fontSize: 42,
    fontWeight: 600,
  },
  totalGrid: {
    paddingTop: '30px !important',
    paddingLeft: '75px !important',
    paddingRight: '35px !important',
  },
  totalWrap: {
    borderBottom: '1px solid #9E9E9E',
    marginBottom: 73,
    '& span': {
      display: 'inline-block',
      width: '50%',
    },
    '& strong': {
      display: 'inline-block',
      width: '50%',
      fontSize: 48,
      fontWeight: 600,
      lineHeight: '70px',
      textAlign: 'right',
    },
  },
  totalWrapSmall: {
    marginBottom: 25,
    '& strong': {
      fontSize: 32,
      fontWeight: 'normal',
    },
  },
  totalWrapProfit: {
    color: '#55B85C',
  },
  totalWrapInvestiment: {
    color: '#DAA520',
  },
  forecastButtonWrapper: {
    textAlign: 'right',
    marginTop: 115,
  },
  loopButton: {
    marginBottom: 86,
  },
  editButtonWrapper: {
    textAlign: 'right',
    marginTop: 115,
  },
  loading: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  lineChart: {
    '& > svg': {
      overflow: 'visible',
    },
    zIndex: 5,
  },
  formulaContainer: {
    fontSize: 16,
    fontWeight: 'normal',
  },
  formulaLabel: {
    color: '#999',
  },
  formulaRect: {
    background: 'rgba(239, 239, 239, 0.86)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
})

export default compose(withStyles(styles, {withTheme: true}))(LocationOpportunity)
