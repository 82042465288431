import {Typography, Button} from '@material-ui/core'

import {makeStyles, withStyles} from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    borderRadius: '10px',
    height: 407,
    width: 457,
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    color: 'white',
    fontWeight: '600',
    fontSize: ' 1em',
    lineHeight: '25px',
    backgroundColor: '#D88A48',
  },
}))

export const Title = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1.3em',
    lineHeight: '29px',
    textAlign: 'center',
  },
})(Typography)

export const BodyText = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1.1em',
    lineHeight: '25.2px',
    textAlign: 'center',
    flex: '1',
    flexGrow: 1,
  },
})(Typography)

export const StyledButton = withStyles({
  root: {
    width: '100%',
    textAlign: 'center',
    margin: '0 auto',
    textTransform: 'none',
    height: '37px',
    fontSize: '1em',
  },
  disabled: {
    backgroundColor: '#ffffff',
  },
})(Button)
