import React from 'react'
import {TableCell as MUITableCell} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'

const styles = theme => ({
  loading: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  tableSearchPercent: {
    color: '#AEAEAE',
    fontSize: '0.775rem',
    display: 'inline-block',
    minWidth: 55,
  },
  tablePaper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  forecastButton: {
    textTransform: 'capitalize',
    marginTop: 15,
    marginRight: 20,
    fontSize: '24px',
    fontWeight: 600,
    minWidth: 210,
    '&:last-child': {
      marginRight: 0,
    },
  },
  executeButton: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 400,
    minWidth: 130,
    maxWidth: 180,
    padding: 12,
    borderRadius: 8,
    '* + &': {
      marginLeft: 12,
    },
  },
  planKeywords: {
    fontFamily: 'Source Sans Pro,Arial, sans-serif',
    fontWeight: 700,
    fontSize: 18,
    textTransform: 'capitalize',
  },
  titlePlan: {
    fontFamily: 'Source Sans Pro,Arial, sans-serif',
    fontSize: 18,
  },
  currentPlan: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1280,
    textAlign: 'left',
    zIndex: 2000,
    marginBottom: 10,
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '0px 32px',
  },
  currentSearch: {
    marginTop: 48,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    display: 'flex',
  },
  forecastMapGripItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  forecastMapPaper: {
    flexGrow: 1,
    display: 'flex',
  },
})
export default styles

export const TableCellNormal = withStyles(theme => ({
  root: {
    fontSize: 16,
  },
  head: {
    color: '#777777',
    fontSize: 21,
    lineHeight: '25px',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '&:last-child': {
      paddingLeft: '30px',
    },
  },
}))(MUITableCell)

export const TableCellRegion = withStyles(theme => ({
  root: {
    fontSize: 14,
  },
  head: {
    color: '#777777',
    fontSize: 21,
    lineHeight: '25px',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  body: {
    paddingLeft: 0,
  },
}))(MUITableCell)

export const TableCellHeadPadding = withStyles(theme => ({
  head: {
    color: '#777777',
    fontSize: 21,
    lineHeight: '25px',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: '42px',
  },
}))(MUITableCell)

export const Dot = withStyles({
  root: {
    height: 7,
    width: 7,
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 16,
  },
})(({classes, color}) => <span className={classes.root} style={{backgroundColor: color}} />)
