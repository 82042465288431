import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Button, Grid, Dialog, DialogTitle, DialogContent, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import PropTypes from 'prop-types'
import autobind from 'autobind-decorator'
import numeral from 'numeral'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ValidatedComponent from '../common/ValidatedComponent.jsx'
import InputTextField from '../common/styles/InputTextField.jsx'
import {CustomTooltip} from './GrowthPlan.styles.js'
import NumberFormatInput from '../common/NumberFormatInput.jsx'
import {updateLocal} from './redux/actions.js'

const formatInteger = value => numeral(value).format('0,0')
class ForecastDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.props.updateLocal({locations: this.props.selected}, 'forecast')
    }
  }

  onChange(fieldName, e) {
    this.props.updateLocal({[fieldName]: e.target.value}, 'forecast')
  }

  @autobind
  handleOpen() {
    const {planResults} = this.props
    this.setState({isOpen: true})
    this.props.updateLocal(
      {
        revenuePerContract: planResults.plan.revenuePerContract,
        rateOfGrowth: '',
        lifetimeSpend: '',
        year1: '',
        year2: '',
        year3: '',
        initialInvestment: '',
      },
      'forecast',
    )
  }

  @autobind
  handleClose() {
    this.setState({isOpen: false})
  }

  @autobind
  handleCancel() {
    this.setState({
      isOpen: false,
    })
  }

  @autobind
  onGenerate() {
    if (this.props.isComponentValid()) {
      this.props.router.push(
        `/plan/${this.props.planResults.plan.id}/forecast?rpc=${this.props.forecast.revenuePerContract}&rog=${this.props.forecast.rateOfGrowth}&lts=${this.props.forecast.lifetimeSpend}&lts1=${this.props.forecast.lifetimeSpendYear1}&lts2=${this.props.forecast.lifetimeSpendYear2}&lts3=${this.props.forecast.lifetimeSpendYear3}&ii=${this.props.forecast.initialInvestment}&locs=${this.props.forecast.locations}`,
      )
    }
  }

  render() {
    const {classes, selected, forecast} = this.props
    const {isOpen} = this.state
    const {
      revenuePerContract,
      rateOfGrowth,
      lifetimeSpend,
      lifetimeSpendYear1,
      lifetimeSpendYear2,
      lifetimeSpendYear3,
      initialInvestment,
    } = forecast

    return (
      <React.Fragment>
        <Button
          className={classes.forecastButton}
          onClick={this.handleOpen}
          variant="contained"
          color="secondary"
          disabled={selected.length === 0}
        >
          Forecast
        </Button>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="forecast-dialog-title"
          open={isOpen}
          scroll="body"
          disableBackdropClick
        >
          <DialogTitle id="forecast-dialog-title" className={classes.dialogTitle} disableTypography>
            Forecast
          </DialogTitle>
          <Typography variant="subtitle1" className={classes.center}>
            Forecast your breakeven point and year 1-3 profitability.
          </Typography>
          <Typography variant="body1" className={classes.center}>
            &nbsp;
          </Typography>
          <DialogContent>
            <Grid container spacing={2} style={{marginLeft: '-14px'}}>
              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Average Sale Value{' '}
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="What is the average price of your products or services"
                    placement="top"
                    style={{position: 'relative', top: 6, fill: '#979797'}}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>

                <InputTextField
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="text"
                  name="revenuePerContract"
                  placeholder="$"
                  value={formatInteger(revenuePerContract)}
                  onChange={this.onChange.bind(this, 'revenuePerContract')}
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    prefix: '$',
                    maxLength: 18,
                    'aria-label': 'bare',
                  }}
                />
              </Grid>
              <Grid item sm={6} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Rate of Growth (%){' '}
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="How much do you expect your gross revenue to grow in 1 year"
                    placement="top"
                    style={{position: 'relative', top: 6, fill: '#979797'}}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  withValidator
                  required
                  shouldValidateOnBlur={true}
                  type="text"
                  name="rateOfGrowth"
                  placeholder="%"
                  value={rateOfGrowth}
                  onChange={this.onChange.bind(this, 'rateOfGrowth')}
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    suffix: '%',
                    maxLength: 18,
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Lifetime Spend (optional){' '}
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="On average, how much will a customer spend with you over the course of his/her entire life"
                    placement="top"
                    style={{position: 'relative', top: 6, fill: '#979797'}}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="lifetimeSpend"
                  value={lifetimeSpend}
                  onChange={this.onChange.bind(this, 'lifetimeSpend')}
                  placeholder="$"
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    prefix: '$',
                    maxLength: 18,
                  }}
                />
              </Grid>
              <Grid item sm={12} style={{paddingBottom: 0}} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Lifetime Spend (%) (optional){' '}
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="On average, how much will a customer spend with you over the course of his/her entire life"
                    placement="top"
                    style={{position: 'relative', top: 6, fill: '#979797'}}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
              </Grid>
              <Grid item sm={4} style={{paddingTop: 0}} className={classes.inputGrid}>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="lifetimeSpendYear1"
                  value={lifetimeSpendYear1}
                  onChange={this.onChange.bind(this, 'lifetimeSpendYear1')}
                  placeholder="%"
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    suffix: '%',
                    maxLength: 18,
                  }}
                />
                <Typography className={classes.labelBellow}>Year 1</Typography>
              </Grid>
              <Grid item sm={4} style={{paddingTop: 0}} className={classes.inputGrid}>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="lifetimeSpendYear2"
                  value={lifetimeSpendYear2}
                  onChange={this.onChange.bind(this, 'lifetimeSpendYear2')}
                  placeholder="%"
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    suffix: '%',
                    maxLength: 18,
                  }}
                />
                <Typography className={classes.labelBellow}>Year 2</Typography>
              </Grid>
              <Grid item sm={4} style={{paddingTop: 0}} className={classes.inputGrid}>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="lifetimeSpendYear3"
                  value={lifetimeSpendYear3}
                  onChange={this.onChange.bind(this, 'lifetimeSpendYear3')}
                  placeholder="%"
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    suffix: '%',
                    maxLength: 18,
                  }}
                />
                <Typography className={classes.labelBellow}>Year 3</Typography>
              </Grid>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>
                  Initial Investment (optional){' '}
                  <CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title="How much it will cost to start this product or service line"
                    placement="top"
                    style={{position: 'relative', top: 6, fill: '#979797'}}
                  >
                    <InfoOutlinedIcon className={classes.icon} />
                  </CustomTooltip>
                </Typography>
                <InputTextField
                  withValidator
                  shouldValidateOnBlur={true}
                  type="text"
                  name="initialInvestment"
                  value={initialInvestment}
                  onChange={this.onChange.bind(this, 'initialInvestment')}
                  placeholder="$"
                  inputComponent={NumberFormatInput}
                  inputProps={{
                    prefix: '$',
                    maxLength: 18,
                  }}
                />
              </Grid>
              <Grid item sm={12} className={classes.center}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.generateButton}
                  onClick={this.onGenerate}
                >
                  Generate Report
                </Button>
              </Grid>
              <Grid item sm={12} className={classes.center}>
                <Button size="small" onClick={this.handleCancel} className={classes.cancelButton}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

ForecastDialog.displayName = 'ForecastDialog'

ForecastDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

const styles = {
  forecastButton: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 400,
    minWidth: 130,
    maxWidth: 150,
    padding: 12,
    borderRadius: 8,
  },
  inputGrid: {
    paddingLeft: '4 px !important',
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: 48,
    fontWeight: '600',
    color: '#00235e',
    paddingBottom: 0,
  },
  center: {
    textAlign: 'center',
  },
  label: {
    fontWeight: '600',
    paddingLeft: 8,
    fontSize: 18,
  },
  labelBellow: {
    textAlign: 'center',
  },
  generateButton: {
    textTransform: 'capitalize',
    fontWeight: '600',
  },
  cancelButton: {
    textTransform: 'capitalize',
  },
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({updateLocal}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.growthPlan,
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true}),
  ValidatedComponent,
)(ForecastDialog)
