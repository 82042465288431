import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import {bindActionCreators, compose} from 'redux'
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Container,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import PropTypes from 'prop-types'
import EditAdGroupDialog from './adGroupKeywords/EditAdgroupDialog'
import styles, {TableCellRegion, TableCellNormal} from './GrowthResults.styles.js'
import GrowthResultsHeader from './GrowthResultsHeader'
import {arrayRemoveAt} from '../utils/ReduxUtils'
import LocationOpportunity from './LocationOpportunity.jsx'
import ForecastDialog from './ForecastDialog'
import GrowthEditPlanBar from './GrowthEditPlanBar'
import GrowthResultsTableRow from './GrowthResultsTableRow'
import CreateCampaignDialog from './CreateCampaignDialog'
import EditCampaignDialog from './EditCampaignDialog.jsx'
import PlanApi from './redux/api.js'
import ConversionTrackingDialog from './ConversionTrackingDialog'

function GrowthResultsTable({
  classes,
  planResults,
  getResults,
  createNotificationFromError,
  router,
}) {
  const [showOpportunityGraph, setShowOpportunityGraph] = useState(false)
  const [isOpenCreateCampaign, setIsOpenCreateCampaign] = useState(false)
  const [isOpenAdGroup, setIsOpenAdGroup] = useState(false)
  const [isOpenConversionTracking, setIsOpenConversionTracking] = useState(false)
  const [selected, setSelected] = useState([])
  const toggleLocation = value => {
    if (!showOpportunityGraph) {
      const index = selected.indexOf(value)
      if (index >= 0) {
        setSelected(arrayRemoveAt(selected, index))
      }
    }
    setSelected(value)
  }

  const handleExecute = () => {
    setShowOpportunityGraph(true)
  }

  const handleBackToResultsTable = () => {
    setShowOpportunityGraph(false)
  }

  const handleOpenDialog = () => {
    setIsOpenCreateCampaign(true)
  }

  const handleOpenAdGroupDialog = () => {
    setIsOpenAdGroup(true)
  }

  const handleOpenConversionTrackingDialog = () => {
    setIsOpenConversionTracking(true)
  }

  const handleCloseDialog = () => {
    setIsOpenCreateCampaign(false)
    setIsOpenAdGroup(false)
    setIsOpenConversionTracking(false)
    getResults(planResults?.plan?.id)
  }

  return (
    <React.Fragment>
      <GrowthResultsHeader planResults={planResults} selected={selected} />
      {planResults && (
        <Grid container spacing={0} direction="row" className={classes.currentPlan}>
          <Grid item xs={7} align="left">
            {planResults?.plan?.campaignId && (
              <>
                <Button
                  style={{border: '1px solid #00235e'}}
                  component={Link}
                  to={`/plan/${planResults?.plan?.id}/reports`}
                  variant="contained"
                  color="primary"
                  className={classes.executeButton}
                >
                  <DescriptionOutlinedIcon fontSize="inherit" style={{marginRight: 10}} /> Reports
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.executeButton}
                  onClick={handleOpenConversionTrackingDialog}
                >
                  Conversion Tracking
                </Button>
              </>
            )}
            <Button
              variant="outlined"
              color="primary"
              className={classes.executeButton}
              onClick={handleOpenDialog}
            >
              {planResults?.plan?.campaignId ? 'Edit' : 'Create'} Campaign
            </Button>
            {planResults?.plan?.adGroupId && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.executeButton}
                onClick={handleOpenAdGroupDialog}
              >
                Edit Ad Group
              </Button>
            )}
          </Grid>
          <Grid item xs={5} align="right">
            <ForecastDialog planResults={planResults} selected={selected} router={router} />
            {!showOpportunityGraph && (
              <Button
                disabled={selected.length === 0}
                variant="contained"
                color="secondary"
                className={classes.executeButton}
                onClick={handleExecute}
              >
                Execute
              </Button>
            )}
          </Grid>
          <Grid item xs={12} className={classes.currentSearch}>
            <Typography className={classes.titlePlan}>
              Current Plan:{' '}
              <Typography component="span" className={classes.planKeywords}>
                {planResults.plan.keywords}
              </Typography>{' '}
              in{' '}
              <Typography component="span" className={classes.planKeywords}>
                {planResults.plan.locations.features
                  .map(location => location.properties.name)
                  .join(', ')}
              </Typography>
            </Typography>
          </Grid>
          {planResults?.plan?.campaignId ? (
            <>
              <EditCampaignDialog
                plan={planResults?.plan}
                isOpen={isOpenCreateCampaign}
                onClose={handleCloseDialog}
              />
              <ConversionTrackingDialog
                plan={planResults?.plan}
                isOpen={isOpenConversionTracking}
                onClose={handleCloseDialog}
              />
            </>
          ) : (
            <CreateCampaignDialog
              plan={planResults}
              isOpen={isOpenCreateCampaign}
              onClose={handleCloseDialog}
            />
          )}
          {!!planResults?.plan?.adGroupId && isOpenAdGroup && (
            <EditAdGroupDialog
              plan={planResults?.plan}
              isOpen={isOpenAdGroup}
              onClose={handleCloseDialog}
            />
          )}
        </Grid>
      )}
      <Container style={{marginBottom: 100}}>
        {showOpportunityGraph && (
          <LocationOpportunity planResults={planResults} selected={selected} />
        )}
        <Paper className={classes.tablePaper} style={{marginBottom: 32}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellRegion style={{width: '30%', paddingLeft: 24}}>Region</TableCellRegion>
                <TableCellNormal align="right">Impressions</TableCellNormal>
                <TableCellNormal align="right">Leads</TableCellNormal>
                <TableCellNormal align="right">Customers</TableCellNormal>
                <TableCellNormal align="right">Revenue</TableCellNormal>
                <TableCellNormal align="right">Profit</TableCellNormal>
              </TableRow>
            </TableHead>
            <TableBody>
              {planResults.locations.map(item => {
                return (
                  <GrowthResultsTableRow
                    key={item.id}
                    item={item}
                    showOpportunityGraph={showOpportunityGraph}
                    toggleLocation={toggleLocation}
                  />
                )
              })}
            </TableBody>
          </Table>
        </Paper>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            {showOpportunityGraph && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.executeButton}
                onClick={handleBackToResultsTable}
              >
                Back
              </Button>
            )}
          </Grid>
          <Grid item xs={6} align="right">
            <ForecastDialog planResults={planResults} selected={selected} router={router} />
            {!showOpportunityGraph && (
              <Button
                disabled={selected.length === 0}
                variant="contained"
                color="secondary"
                className={classes.executeButton}
                onClick={handleExecute}
              >
                Execute
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
      <GrowthEditPlanBar plan={planResults.plan} />
    </React.Fragment>
  )
}

GrowthResultsTable.displayName = 'GrowthResultsTable'

GrowthResultsTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

function mapStateToProps() {
  return {}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true}),
)(GrowthResultsTable)
