import React from 'react'
import {Typography, Divider} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import Loading from '../../common/Loading.jsx'
import styles from './GrowthReportsCampaign.styles'

function GrowthReportsCampaign({classes, report, isLoading}) {
  const {
    advertisingChannelType: campaignType,
    status: campaignStatus,
    clicks,
    impressions,
    ctr,
    averageCpc,
    costMicros: cost,
    conversions,
    allConversions,
    viewThroughConversions,
    costPerConversion,
    conversionsFromInteractionsRate,
  } = report

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classes.reportsTable}>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Campaign State</Typography>
            <Typography className={classes.reportsCellContent}>
              {campaignStatus ? campaignStatus[0] + campaignStatus.slice(1).toLowerCase() : '-'}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Campaign Type</Typography>
            <Typography className={classes.reportsCellContent}>
              {campaignType ? campaignType[0] + campaignType.slice(1).toLowerCase() : ''}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Clicks</Typography>
            <Typography className={classes.reportsCellContent}>{clicks || 0}</Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Impressions</Typography>
            <Typography className={classes.reportsCellContent}>{impressions || 0}</Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>CTR</Typography>
            <Typography className={classes.reportsCellContent}>
              {`${Number(ctr || 0).toFixed(2)}%`}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Avg. CPC</Typography>
            <Typography className={classes.reportsCellContent}>
              {`$${Number(averageCpc || 0).toFixed(2)}`}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Cost</Typography>
            <Typography className={classes.reportsCellContent}>
              {`$${(Number(cost || 0) * 1000000).toFixed(2)}`}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Conversions</Typography>
            <Typography className={classes.reportsCellContent}>
              {Number(conversions || 0).toFixed(2)}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>All Conversions</Typography>
            <Typography className={classes.reportsCellContent}>
              {Number(allConversions || 0).toFixed(2)}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>View-through conv.</Typography>
            <Typography className={classes.reportsCellContent}>
              {Number(viewThroughConversions || 0).toFixed(2)}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Cost / conv.</Typography>
            <Typography className={classes.reportsCellContent}>
              {`$${Number(costPerConversion || 0).toFixed(2)}`}
            </Typography>
          </div>
          <div className={classes.reportsCell}>
            <Typography className={classes.reportsCellHeader}>Conversion Rate</Typography>
            <Typography className={classes.reportsCellContent}>
              {`${Number(conversionsFromInteractionsRate || 0).toFixed(2)}%`}
            </Typography>
          </div>
        </div>
      )}
      <Divider />
    </>
  )
}

export default withStyles(styles)(GrowthReportsCampaign)
