import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/styles'
import {
  AppBar,
  Toolbar,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Button,
  IconButton,
  Divider,
  Typography,
  Container,
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import {Link} from 'react-router'
import {connect} from 'react-redux'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import {fade} from '@material-ui/core/styles/colorManipulator'
import {logout} from '../auth/redux/actions'
import {Logo} from './Icons.jsx'

const styles = theme => ({
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  navbar: {
    padding: '0',
    backgroundColor: theme.palette.Primary[700],
    overflow: '',
    boxShadow: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '80px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navbarTitle: {
    flex: 1,
  },
  navbarTitleLink: {
    color: 'white',
    fontSize: '32px',
    padding: 0,
    borderRadius: 0,
  },
  navbarProfile: {
    width: 34,
    height: 34,
    [theme.breakpoints.down('sm')]: {
      width: 32,
      height: 32,
    },
    padding: 0,
    color: theme.palette.secondary.main,
  },
  navbarDropdown: {
    zIndex: 1,
    minWidth: '12em',
  },
  brandImageContainer: {
    flex: 1,
  },
  brandImage: {
    height: '48px',
    [theme.breakpoints.up('sm')]: {
      height: '56px',
    },
    [theme.breakpoints.up('md')]: {
      height: '56px',
    },
  },
  registerBtn: {
    marginLeft: '15px',
    color: theme.palette.primary.main,
    background: `#e3c05b`,
    '&:hover': {
      background: '#d0ad4a',
    },
  },
  search: {
    display: 'none',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    color: theme.palette.common.white,
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: theme.palette.common.white,
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  menuEmail: {
    marginTop: '0',
    marginBottom: '12px',
    minHeight: '0',
  },
  menuName: {
    marginBottom: '0',
    minHeight: '0',
  },
})

export class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      profileMenuOpened: false,
    }

    this.logout = this.logout.bind(this)
    this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this)
    this.handleProfileMenuClose = this.handleProfileMenuClose.bind(this)
  }

  componentWillMount() {
    this.setState({searchText: this.props.searchText})
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.searchText !== nextProps.searchText) {
      this.setState({searchText: nextProps.searchText})
    }
  }

  handleProfileMenuOpen() {
    this.setState({profileMenuOpened: true})
  }

  handleProfileMenuClose() {
    this.setState({profileMenuOpened: false})
  }

  render() {
    const {props} = this
    const {classes} = props
    const profile = _.get(props, 'profile')

    let profileName = ''
    let profileEmail = ''

    if (profile) {
      profileName = profile.firstName ? profile.firstName : 'user'
      profileEmail = profile.email
    }

    const isLoggedIn = !!_.get(props, 'auth.token')

    return (
      <AppBar color="default" className={classes.navbar} position="static">
        <Container>
          <Toolbar className={classes.toolbar}>
            <div className={classes.brandImageContainer}>
              <IconButton
                id="logoContain"
                onClick={() => this.props.onHomeClick()}
                className={classes.navbarTitleLink}
                component={Link}
                to="/"
              >
                <Logo className={classes.brandImage} />
              </IconButton>
            </div>
            {isLoggedIn ? (
              <React.Fragment>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{'aria-label': 'Search'}}
                  />
                </div>
                <IconButton
                  buttonRef={n => {
                    this.profileButtonRef = n
                  }}
                  onClick={this.handleProfileMenuOpen}
                  title={profileName}
                  className={classes.navbarProfile}
                >
                  <AccountCircle className={classes.navbarProfile} />
                </IconButton>
                <Popper
                  anchorEl={this.profileButtonRef}
                  open={this.state.profileMenuOpened}
                  transition
                  disablePortal
                  className={classes.navbarDropdown}
                >
                  {({TransitionProps, placement}) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleProfileMenuClose}>
                          <MenuList>
                            <MenuItem className={classes.menuName} button={false}>
                              Hi {profileName}!
                            </MenuItem>
                            <MenuItem className={classes.menuEmail} button={false}>
                              <Typography variant="overline">{profileEmail}</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={this.handleProfileMenuClose}
                              component={Link}
                              to="/profile"
                            >
                              Profile
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={this.handleProfileMenuClose}
                              component={Link}
                              to="/history"
                            >
                              History
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={this.handleProfileMenuClose}
                              component={Link}
                              to="/dashboard"
                            >
                              Google Ads Dashboard
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={this.handleProfileMenuClose}
                              component={Link}
                              to="/billing"
                            >
                              Billing
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={e => {
                                this.handleProfileMenuClose(e)
                                this.logout(e)
                              }}
                            >
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* <Button component={Link} to="/login">
                  Login
                </Button> */}
                {/*<Button
                  className={classes.registerBtn}
                  component={Link}
                  to="/signup"
                  color="secondary"
                  variant="contained"
                >
                  Register
                </Button>*/}
              </React.Fragment>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    )
  }

  logout() {
    this.props.dispatch(logout())
  }

  onSearch(keyEvent) {
    const key = keyEvent.which || keyEvent.keyCode

    if (key === 13) {
      const {searchText} = this.state

      const query = {q: searchText}
      this.context.router.push({pathname: '/', query})
    }
  }
}

Header.displayName = 'Header'
Header.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object,
  onHomeClick: PropTypes.func.isRequired,
}
Header.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {searchText: _.get(state, 'home.query.q', '')}
}

export default connect(mapStateToProps)(withStyles(styles)(Header))
