import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import {Dialog, DialogTitle, Grid, Button, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import AutoComplete from '../common/Autocomplete'
import {ConversionTrackingTypes} from './CreateCampaignDialog.const'
import PlanApi from './redux/api'
import {
  createNotificationFromError,
  createSuccess,
  createError,
} from '../common/redux/actions.notifications'
import {styles} from './CreateCampaignDialog.styles'

function ConversionTrackingDialog({
  classes,
  createNotificationFromError,
  createSuccess,
  createError,
  isOpen,
  plan,
  onClose,
}) {
  const [isLoading, setIsLoading] = useState(false)

  const [conversionActionType, setConversionActionType] = useState('')
  const [type, setType] = useState({value: '', label: ''})

  const [eventSnippet, setEventSnippet] = useState(null)
  const [globalSiteTag, setGlobalSiteTag] = useState(null)
  const [originalEventSnippet, setOriginalEventSnippet] = useState(null)
  const [originalGlobalSiteTag, setOriginalGlobalSiteTag] = useState(null)

  useEffect(() => {
    getConversionAction()
  }, [])

  const getConversionAction = async () => {
    setIsLoading(true)
    return PlanApi.getConversionAction(plan?.id).then(({conversionAction}) => {
      if (_.get(conversionAction, 'type') === 'WEBPAGE') {
        if (_.get(conversionAction, 'category') === 'PAGE_VIEW') {
          const tagSnippets = _.get(conversionAction, 'tagSnippets', []).find(
            ts => ts.type === 'WEBPAGE' && ts.pageFormat === 'HTML',
          )
          setEventSnippet(tagSnippets?.eventSnippet)
          setOriginalEventSnippet(tagSnippets?.eventSnippet)
          setGlobalSiteTag(tagSnippets?.globalSiteTag)
          setOriginalGlobalSiteTag(tagSnippets?.globalSiteTag)
          setType({value: 'WEBPAGE', label: 'WEBPAGE'})
          setConversionActionType('WEBPAGE')
        } else {
          const tagSnippets = _.get(conversionAction, 'tagSnippets', []).find(
            ts => ts.type === 'WEBPAGE_ONCLICK' && ts.pageFormat === 'HTML',
          )
          setEventSnippet(tagSnippets?.eventSnippet)
          setOriginalEventSnippet(tagSnippets?.eventSnippet)
          setGlobalSiteTag(tagSnippets?.globalSiteTag)
          setOriginalGlobalSiteTag(tagSnippets?.globalSiteTag)
          setType({value: 'WEBPAGE_ONCLICK', label: 'WEBPAGE_ONCLICK'})
          setConversionActionType('WEBPAGE_ONCLICK')
        }
      } else if (_.get(conversionAction, 'type') === 'CLICK_TO_CALL') {
        const tagSnippets = _.get(conversionAction, 'tagSnippets', []).find(
          ts => ts.pageFormat === 'HTML',
        )
        setEventSnippet(tagSnippets?.eventSnippet)
        setOriginalEventSnippet(tagSnippets?.eventSnippet)
        setGlobalSiteTag(tagSnippets?.globalSiteTag)
        setOriginalGlobalSiteTag(tagSnippets?.globalSiteTag)
        setType({value: 'CLICK_TO_CALL', label: 'CLICK_TO_CALL'})
        setConversionActionType('CLICK_TO_CALL')
      } else {
        setType({value: '', label: ''})
      }
      setIsLoading(false)
    })
  }

  const handleClose = () => {
    onClose()
  }

  const getPayload = () => {
    const payload = {
      type: type.value,
    }

    return payload
  }

  const handleSubmit = async () => {
    try {
      if (!!originalGlobalSiteTag || !!originalEventSnippet) {
        await PlanApi.updateConversionAction(plan?.id, getPayload())
      } else {
        await PlanApi.createConversionAction(plan?.id, getPayload())
      }
      createSuccess('Your conversion action was succesfully set.')
      await getConversionAction()
    } catch (e) {
      console.log(e)
      createNotificationFromError(`An error occurred due to ${e}.`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      aria-labelledby="conversion-tracking-dialog-title"
      open={isOpen}
      scroll="body"
      className={classes.dialog}
      onClose={handleClose}
    >
      <Grid container spacing={0} className={classes.container}>
        {isLoading ? (
          <Typography>Loading conversion tracking data...</Typography>
        ) : (
          <>
            <Grid item sm={12} className={classes.center}>
              <DialogTitle
                id="conversion-tracking-dialog-title"
                className={classes.dialogTitle}
                style={{fontSize: 32}}
                disableTypography
              >
                Setup Web Conversion Tracking
              </DialogTitle>
            </Grid>
            <Grid container spacing={1} style={{marginLeft: '-14px'}}>
              <Grid item sm={12} className={classes.inputGrid}>
                <Typography className={classes.label}>Type</Typography>
                <AutoComplete
                  options={ConversionTrackingTypes}
                  className={classes.inputField}
                  onChange={value => {
                    if (value.value !== conversionActionType) {
                      setEventSnippet(null)
                      setGlobalSiteTag(null)
                    } else {
                      setEventSnippet(originalEventSnippet)
                      setGlobalSiteTag(originalGlobalSiteTag)
                    }
                    setType(value)
                  }}
                  value={type}
                  placeholder="Type"
                />
              </Grid>
              {eventSnippet && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <Typography className={classes.label}>Event Snippet</Typography>

                  <textarea readOnly className={classes.snippet}>
                    {eventSnippet}
                  </textarea>
                </Grid>
              )}
              {globalSiteTag && (
                <Grid item sm={12} className={classes.inputGrid}>
                  <Typography className={classes.label}>Global Site Tag</Typography>

                  <textarea readOnly className={classes.snippet}>
                    {globalSiteTag}
                  </textarea>
                </Grid>
              )}
            </Grid>

            <Grid item sm={12} className={classes.buttons}>
              <Grid item sm={12} className={classes.center}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className={classes.continueButton}
                  onClick={handleSubmit}
                  disabled={isLoading || type.value === conversionActionType}
                >
                  {!!originalEventSnippet || !!originalGlobalSiteTag ? 'Change' : 'Add'} Conversion
                  Tracking
                </Button>
              </Grid>
              <Grid item sm={12} className={classes.center}>
                <Button size="small" className={classes.cancelButton} onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createNotificationFromError, createSuccess, createError}, dispatch)
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(ConversionTrackingDialog)
