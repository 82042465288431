export const styles = theme => ({
  page: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.Gray[100],
      justifyContent: 'flex-start',
      width: '100%',
      height: 'calc(100vh - 60px)',
      minHeight: '580px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '592px',
      minHeight: '794px',
      justifyContent: 'flex-start',
      marginBottom: '96px',
    },
  },
  page2: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      minHeight: '794px',
      justifyContent: 'flex-start',
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.Gray[100],
      padding: '36px 24px 0 24px',
      paddingBottom: 0,
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '592px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '48px 24px 32px 24px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '592px',
      borderRadius: '12px',
      padding: '48px 24px 48px 24px',
    },
  },
  wrapperPage2: {
    [theme.breakpoints.up('md')]: {
      width: '962px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '48px 24px 0 24px',
      flex: 0,
    },
    [theme.breakpoints.up('lg')]: {
      width: '1064px',
      padding: '48px 24px 0 24px',
    },
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '48px 24px',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.Gray[100],
      width: '100%',
      flex: 1,
    },
    [theme.breakpoints.up('sm')]: {
      width: '592px',
      flex: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 96px',
      borderRadius: '12px 12px 0 0',
      flex: 1,
    },
    [theme.breakpoints.up('lg')]: {
      width: '592px',
      borderRadius: '12px 12px 0 0',
      backgroundColor: theme.palette.Gray[100],
      padding: '64px 96px 0',
    },
  },
  plansWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: '16px',
    backgroundColor: theme.palette.Gray[100],
    padding: '24px',
    width: '100%',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      width: '592px',
      flex: 0,
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '12px 12px 0 0',
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'row',
      gap: '16px',
      padding: '64px 0',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.Gray[300],
      borderRadius: '12px 12px 0 0',
      width: '1064px',
      gap: '32px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.Gray[100],
      padding: theme.scale[24],
      paddingTop: 0,
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '592px',
    },
    [theme.breakpoints.up('md')]: {
      width: '592px',
      borderRadius: '0 0 12px 12px',
      backgroundColor: theme.palette.Gray[100],
      padding: '48px 96px 64px 96px',
    },
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  stepCircle: {
    marginRight: '4px',
    width: '8px',
    height: '8px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '6px',
      width: '10px',
      height: '10px',
    },
    borderRadius: '5px',
  },
  title: {
    color: theme.palette.Gray[800],
    margin: '24 0 8',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '24px',
    '& > strong': {
      fontWeight: 900,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '36px',
    },
  },
  subtitle: {
    color: theme.palette.Gray[700],
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      marginTop: '8px',
    },
  },
  addressText: {
    color: theme.palette.Gray[800],
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '16px',
    marginTop: '32px',
  },
  cardInfoLabel: {
    color: theme.palette.Gray[800],
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '16px',
    marginBottom: '24px',
  },
  form: {
    margin: 0,
  },
  inputField: {
    backgroundColor: theme.palette.Gray[100],
    justifyContent: 'flex-start',
    margin: '16px 0 0',
    borderRadius: '8px',
    height: '48px',
    '& > fieldset': {
      borderRadius: '8px',
    },
    '& > .MuiOutlinedInput-input': {
      fontSize: '14px',
    },
    '& ~ .MuiFormHelperText-root': {
      fontSize: '12px',
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '24px 0 0',
    },
  },
  iconButton: {
    padding: 0,
  },
  submitButton: {
    backgroundColor: theme.palette.Primary[600],
    color: theme.palette.Gray[200],
    height: '48px',
    width: '100%',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '8px',
    marginBottom: '16px',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.Gray[400],
      color: theme.palette.Gray[600],
    },
    '&:hover': {
      backgroundColor: theme.palette.Primary[700],
      color: theme.palette.Gray[200],
    },
  },
  buttonWrapperPage2: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      margin: '0 24px',
      padding: '24px 0',
      width: '962px',

      '& > div': {
        width: '213px',
        alignSelf: 'flex-end',
      },
    },
    [theme.breakpoints.up('lg')]: {
      width: '1190px',
      margin: 0,
    },
  },
  submitButtonPage2: {
    [theme.breakpoints.down('md')]: {},
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.Primary[600],
    },
    marginTop: '8px',
    marginBottom: '8px',
    padding: '8px',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  text2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
  },
  link: {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.Primary[600],
    textDecoration: 'none',
  },
  link2: {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.Primary[600],
    textDecoration: 'none',
  },
  toggleGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '24px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '6px',
    },
  },
  toggle: {
    backgroundColor: theme.palette.Gray[200],
    color: theme.palette.Gray[700],
    width: '173px',
    height: '42px',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '4px',
    [theme.breakpoints.up('md')]: {
      width: '188px',
      height: '56px',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.Gray[400],
      '&:hover': {
        color: theme.palette.Primary[600],
        backgroundColor: `${theme.palette.Primary[600]}33`,
      },
    },
  },
  active: {
    backgroundColor: theme.palette.Blue[600],
    color: theme.palette.Gray[100],
    padding: '9px',
  },
  option: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
    height: '34px',
    flex: 1,
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      height: '48px',
    },
  },
  save: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.Secondary[100],
    color: theme.palette.Secondary[500],
    width: '86px',
    height: '23px',
    borderRadius: '24px',
    marginLeft: '8px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '8px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.Secondary[100],
    },
    [theme.breakpoints.up('md')]: {
      height: '32px',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '.4px',
      textTransform: 'none',
    },
  },
  saveOn: {
    backgroundColor: theme.palette.Green[100],
    color: theme.palette.Green[500],
    '&:hover': {
      backgroundColor: theme.palette.Green[100],
    },
  },
  planContainer: {
    backgroundColor: theme.palette.Gray[200],
    height: '200px',
    borderRadius: '0 0 12px 12px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '310px',
      height: '474px',
      backgroundColor: theme.palette.Gray[100],
      border: `1px solid ${theme.palette.Gray[600]}`,
      borderRadius: '12px',
      '&:hover': {
        backgroundColor: `${theme.palette.Primary[600]}0C`,
      },
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.Gray[300],
      width: '333px',
    },
  },
  planCard: {
    border: `1px solid ${theme.palette.Gray[400]}`,
    boxSizing: 'border-box',
    borderRadius: '12px',
    height: '72px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
    [theme.breakpoints.up('md')]: {
      height: '278px',
      width: '310px',
      border: 'none',
      padding: '48px',
      justifyContent: 'space-between',

      '& > span': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },

      '&:hover': {
        backgroundColor: `${theme.palette.Gray[300]}00`,
      },
    },
    [theme.breakpoints.up('lg')]: {
      width: '333px',
    },
  },
  checkCircle: {
    border: `2px solid ${theme.palette.Gray[500]}`,
    boxSizing: 'border-box',
    borderRadius: '24px',
    width: '22px',
    height: '22px',
    marginRight: '16px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '12px',
      border: `2px solid ${theme.palette.Gray[600]}`,
    },
  },
  checkCircleOn: {
    width: '22px',
    height: '22px',
    marginRight: '16px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '12px',
    },
  },
  planName: {
    color: theme.palette.Gray[800],
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      color: theme.palette.Gray[600],
      fontSize: '24px',
      marginBottom: '12px',
    },
  },
  planGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  },
  priceGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  planPrice: {
    color: theme.palette.Gray[800],
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
      lineHeight: '48px',
    },
  },
  planFreq: {
    textTransform: 'none',
    color: theme.palette.Gray[800],
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '20px',
      marginLeft: '8px',
    },
  },
  textFlavor: {
    color: theme.palette.Gray[700],
    width: '100%',
    textAlign: 'justify',
    textJustify: 'inter-word',
    margin: '24px 0 0 0',
  },
  planInfoGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '16px',
    width: '100%',
  },
  infoKey: {
    color: theme.palette.Gray[600],
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    marginLeft: '32px',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '20px',
      marginLeft: '16px',
    },
  },
  infoValue: {
    color: theme.palette.Gray[800],
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '16px',
    marginRight: '32px',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '20px',
      marginRight: '16px',
    },
  },
  dividerPage: {
    [theme.breakpoints.up('lg')]: {
      border: `1px solid ${theme.palette.Gray[500]}`,
      width: '1190px',
    },
  },
  dividerCard: {
    width: '100%',
    height: '128px',
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      borderTop: `1px solid ${theme.palette.Gray[600]}`,
      width: '268px',
      height: '196px',
      gap: '24px',
    },
  },
  cardElement: {
    padding: '14px',
    border: `1px solid ${theme.palette.Gray[500]}`,
    backgroundColor: theme.palette.Gray[100],
    color: theme.palette.Gray[900],
    willChange: 'opacity, transform',
    borderRadius: '8px',
    width: '100%',
    margin: 0,
  },
})
