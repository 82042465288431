import React from 'react'
import {InputBase} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import InputValidator from '../InputValidator'

const styles = theme => ({
  input: {
    color: `#040D14`,
    margin: '3px 0',
    padding: '6px 0',
    fontFamily: 'Source Sans Pro',
    fontSize: `24px`,
    lineHeight: `36px`,
    textAlign: `left`,
    fontWeight: 600,
    border: 'black solid 1px',
    borderRadius: `6px`,
    backgroundColor: '#F7F7F7',
    marginLeft: props => (props.withValidator ? 0 : theme.spacing(1)),
    marginRight: props => (props.withValidator ? 0 : theme.spacing(1)),
  },
  textInput: {
    textAlign: 'left',
    marginLeft: 20,
  },
  validator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
})

export default withStyles(styles)(props => {
  const {classes, shouldValidateOnBlur, errors, withValidator, inputProps, ...otherProps} = props
  const input = (
    <InputBase
      margin="none"
      variant="outlined"
      classes={{root: classes.input}}
      inputProps={{
        className: classes.textInput,
        ...inputProps,
      }}
      {...otherProps}
    />
  )
  if (withValidator) {
    return (
      <InputValidator
        shouldValidateOnBlur={shouldValidateOnBlur}
        errors={errors}
        className={classes.validator}
      >
        {input}
      </InputValidator>
    )
  }
  return input
})
