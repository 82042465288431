import {createMuiTheme} from '@material-ui/core/styles'
import {darken, lighten} from 'polished'

const fontFam = '"Roboto","Source Sans Pro", "Arial", sans-serif'

// Palette
export const colorPrimary = '#00235e'
export const colorSecondary = '#e3c05b'
export const colorBg = grey50
export const colorSuccess = '#45B880'
export const colorInfo = '#1070CA'
export const colorWarning = '#FFB822'
export const colorDanger = '#ed4740'

export const colorBlack = '#000'
export const colorWhite = '#fff'

export const grey50 = '#fafafa'
export const grey100 = '#f5f5f5'
export const grey200 = '#eeeeee'
export const grey300 = '#e0e0e0'
export const grey400 = '#bdbdbd'
export const grey500 = '#9e9e9e'
export const grey600 = '#757575'
export const grey700 = '#616161'
export const grey800 = '#424242'
export const grey900 = '#212121'

export const borderColor = '#DFE3E8'

export const defaultPrimary = 'rgba(0, 0, 0, 0.87)'
export const defaultSecondary = 'rgba(0, 0, 0, 0.54)'
export const defaultDisabled = 'rgba(0, 0, 0, 0.38)'
export const defaultHint = 'rgba(0, 0, 0, 0.38)'

const valueLighten = 0.2
const valueDarken = 0.12

// New Design System

export const Blue = {
  100: '#DAEFFF',
  200: '#90CAFC',
  300: '#50A9FA',
  400: '#0081F9',
  500: '#0068D0',
  600: '#004799',
  700: '#00235E',
  800: '#051736',
}

export const Primary = Blue

export const Yellow = {
  100: '#FFF8CD',
  200: '#FFEF93',
  300: '#F0D56F',
  400: '#E3C05B',
  500: '#A98D35',
  600: '#836A1B',
  700: '#55440D',
}

export const Secondary = Yellow

export const Gray = {
  100: '#FBFBFB',
  200: '#ECEEF0',
  300: '#DDE1E5',
  400: '#C8CED3',
  500: '#B0B9C2',
  600: '#858D95',
  700: '#676E77',
  800: '#3D4550',
  900: '#22272F',
}

export const Red = {
  100: '#FCE8E8',
  200: '#F5AAAA',
  300: '#E46464',
  400: '#F44336',
  500: '#B82020',
  600: '#891B1B',
  700: '#611818',
}

export const Green = {
  100: '#E3FCEC',
  200: '#A8EEC1',
  300: '#74D99F',
  400: '#38C172',
  500: '#259D58',
  600: '#197741',
  700: '#155239',
}

export const scale = {
  4: '4px',
  8: '8px',
  16: '16px',
  24: '24px',
  32: '32px',
  48: '48px',
  64: '64px',
  96: '96px',
  128: '128px',
  193: '192px',
  256: '256px',
  384: '384px',
  512: '512px',
  640: '640px',
  768: '768px',
}

// Type
export const h1 = '3.75rem'
export const h2 = '3rem'
export const h3 = '2.25rem'
export const h4 = '1.75rem'
export const h5 = '1.3125rem'
export const h6 = '1.125rem'

const subtitle1 = '1rem'
const subtitle2 = '0.875rem'
const body1 = '1rem'
const body2 = '0.875rem'
const button = '0.875rem'
const caption = '0.6875rem'
const overline = '0.6125rem'

const hMargin = 0.55
const hTopMar = hMargin + 'em'
const hBotMar = hMargin / 2 + 'em'

// Forms
const formType = 'standard'

export default createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: formType,
    },
    Input: {
      variant: formType,
    },
    MuiIcon: {
      fontSize: 'small',
    },
  },
  scale,
  // Color
  palette: {
    type: 'light',
    common: {
      black: colorBlack,
      white: colorWhite,
    },
    Primary,
    Secondary,
    Gray,
    Red,
    Blue,
    Green,
    primary: {
      contrastText: colorWhite,
      main: colorPrimary,
      light: lighten(valueLighten, colorPrimary),
      dark: darken(valueDarken, colorPrimary),
    },
    secondary: {
      contrastText: colorWhite,
      main: colorSecondary,
      light: lighten(valueLighten, colorSecondary),
      dark: darken(valueDarken * 0.8, colorSecondary),
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {},
    action: {
      active: defaultSecondary,
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    success: {
      contrastText: colorWhite,
      main: colorSuccess,
      light: lighten(valueLighten, colorSuccess),
      dark: darken(valueDarken, colorSuccess),
    },
    info: {
      contrastText: colorWhite,
      main: colorInfo,
      light: lighten(valueLighten, colorInfo),
      dark: darken(valueDarken, colorInfo),
    },
    warning: {
      contrastText: colorWhite,
      main: colorWarning,
      light: lighten(valueLighten, colorWarning),
      dark: darken(valueDarken, colorWarning),
    },
    danger: {
      contrastText: colorWhite,
      main: colorDanger,
      light: lighten(valueLighten, colorDanger),
      dark: darken(valueDarken, colorDanger),
    },
    border: borderColor,
    grey: {
      '50': grey50,
      '100': grey100, // Default Color
      '200': grey200,
      '300': grey300,
      '400': grey400,
      '500': grey500,
      '600': grey600,
      '700': grey700,
      '800': grey800,
      '900': grey900,
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  // Typography
  typography: {
    fontFamily: fontFam,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: h1,
      fontWeight: 200,
      fontFamily: fontFam,
      letterSpacing: '-.02em',
      lineHeight: '1.14286em',
      marginLeft: '-.04em',
      marginTop: hTopMar,
      marginBottom: hBotMar,
    },
    h2: {
      fontSize: h2,
      fontWeight: 400,
      fontFamily: fontFam,
      letterSpacing: '-.02em',
      lineHeight: '1.30357em',
      marginLeft: '-.02em',
      marginTop: hTopMar,
      marginBottom: hBotMar,
    },
    h3: {
      fontSize: h3,
      fontWeight: 400,
      fontFamily: fontFam,
      lineHeight: '1.13333em',
      marginLeft: '-.02em',
      marginTop: hTopMar,
      marginBottom: hBotMar,
    },
    h4: {
      fontSize: h4,
      fontWeight: 400,
      fontFamily: fontFam,
      lineHeight: '1.20588em',
      marginTop: hTopMar,
      marginBottom: hBotMar,
    },
    h5: {
      fontSize: h5,
      fontWeight: 700,
      fontFamily: fontFam,
      lineHeight: '1.35417em',
      marginTop: hTopMar,
      marginBottom: hBotMar,
      textTransform: 'uppercase',
    },
    h6: {
      fontSize: h6,
      fontWeight: 500,
      fontFamily: fontFam,
      lineHeight: '1.16667em',
      marginTop: hTopMar,
      marginBottom: hBotMar,
    },
    subtitle1: {
      fontSize: subtitle1,
      fontWeight: 400,
      fontFamily: fontFam,
      lineHeight: '1.5em',
      marginTop: hTopMar,
      marginBottom: hBotMar,
    },
    subtitle2: {
      color: colorWhite,
      fontFamily: fontFam,
      fontWeight: 700,
      fontSize: subtitle2,
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
      textTransform: 'uppercase',
      marginTop: hTopMar,
      marginBottom: hBotMar,
    },
    body1: {
      fontFamily: fontFam,
      fontWeight: 400,
      fontSize: body1,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: fontFam,
      fontWeight: 400,
      fontSize: body2,
      lineHeight: 1.5,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: fontFam,
      fontWeight: 500,
      fontSize: button,
      lineHeight: 1.5,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: fontFam,
      fontWeight: 400,
      fontSize: caption,
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: fontFam,
      fontWeight: 400,
      fontSize: overline,
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  overrides: {
    input: {
      height: '2em',
    },
  },
})

/*

Customizable objects

mixins: {},
breakpoints: {},
direction: 'ltr',
overrides: {},
shadows: {},
shape: {},
spacing: {},
transitions: {},

*/
