import {fork, all} from 'redux-saga/effects'
import errorFlow from './common/redux/sagas.errors'
import authFlow from './auth/redux/sagas'
import forgotPasswordFlow from './auth/redux/sagas.forgotPw'
import profileFlow from './profile/redux/sagas'
import signupFlow from './auth/redux/sagas.signup'
import publicProfileFlow from './publicUser/redux/sagas'
import growthPlanFlow from './growthPlan/redux/sagas'

export default function* root() {
  yield all([
    fork(errorFlow),
    fork(authFlow),
    fork(forgotPasswordFlow),
    fork(growthPlanFlow),
    fork(profileFlow),
    fork(signupFlow),
    fork(publicProfileFlow),
  ])
}
