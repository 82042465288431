import _ from 'lodash'
import humps from 'humps'

export function getApiErrors(errorObj) {
  return humps.camelizeKeys(_.get(errorObj, 'response.data', {}))
}

export function formatApiErrorMessages(errors, messages = [], prefix = '') {
  _.forOwn(errors, (v, k) => {
    const newPrefix = prefix ? `${prefix}[${formatFieldName(k)}]` : formatFieldName(k)
    if (typeof v === 'string') {
      messages.push(`${newPrefix}: ${v}`)
    }
    if (Array.isArray(v) && v.length) {
      if (typeof v[0] === 'string') {
        messages.push(`${newPrefix}: ` + v.join('; '))
      } else {
        v.forEach((errors, i) => {
          messages = formatApiErrorMessages(errors, messages, `${newPrefix}[${i + 1}]`)
        })
      }
    } else if (typeof v === 'object') {
      messages = formatApiErrorMessages(v, messages, `${newPrefix}`)
    }
  })
  return messages
}

function formatFieldName(name) {
  let result = `${humps.decamelize(name).replace(/_/g, ' ')}`
  result = result.substr(0, 1).toUpperCase() + result.substr(1)
  return result
}

export function createErrorNotificationMessage(err) {
  const status = _.get(err, 'response.status')

  if (status && status !== 401) {
    let errMessages = []
    if (_.get(err, 'response.data')) {
      try {
        errMessages = formatApiErrorMessages(err.response.data)
      } catch (ex) {
        console.error(ex)
      }
    }

    let errMessage = ''
    if (errMessages.length === 1) {
      errMessage = errMessages[0]
    } else {
      errMessage = errMessages.join('\n\n')
    }

    const message =
      errMessage ||
      err.message ||
      (err.responseText ? 'Error: ' + err.responseText : 'Error (see console logs)')

    return message
  }

  return 'Error (see console logs)'
}

export function parseCampaignErrors(e) {
  const error = createErrorNotificationMessage(e)
  const possibleErrors = {
    'tracking url template must start with a valid protocol':
      "Tracking Url Template must start with a valid protocol. Please enter a valid protocol (e.g. 'https://')",
    'tracking url template must contain at least one tag':
      "Tracking Url Template must contain at least one tag. Please enter a valid tag (e.g. '{lpurl}')",
    'tracking url template has an invalid or missing top level domain extension':
      "Tracking Url Template has an invalid domain. Please enter a valid domain (e.g. 'example.com')",
    'request would exceed a limit on the number of allowed resources':
      'The number of allowed resources has been exceeded. Please contact admin for generate a new Google Ads account.',
    'ad group type is not compatible with the campaign channel type':
      'The Ad Groups Type is not compatible with the Advertising Channel Type. Please select a different Ad Groups Type or Advertising Channel Type.',
  }
  if (error.includes('Resource was not found') && error.includes('merchant_id')) {
    return "Merchant Center ID was not found or it's invalid. Please enter a valid Merchant Center ID."
  }
  let message
  Object.keys(possibleErrors).forEach(key => {
    if (error.includes(key)) {
      message = possibleErrors[key]
    }
  })
  return message
}
