import React, {useState} from 'react'
import _ from 'lodash'
import PlanApi from './redux/api.js'
import Autocomplete from '../common/Autocomplete.jsx'

function LocationsSelect({onChange, ...props}) {
  const [isLoading, setIsLoading] = useState(true)

  function loadLocations(inputValue, callback) {
    setIsLoading(true)
    return PlanApi.getLocations({q: inputValue, country: 'US'}).then(response => {
      const locationsResponse = response.results.map(item => ({
        label: item.canonicalName.split(',').join(', '),
        value: item.locationId,
      }))
      callback(locationsResponse)
      setIsLoading(false)
      return locationsResponse
    })
  }

  function onLocationChange(value) {
    onChange(
      value
        ? value.map(location => ({
            value: location.value,
            label: location.label,
            locationId: location.value,
            name: location.label,
          }))
        : [],
    )
  }

  return (
    <Autocomplete
      async
      loadOptions={loadLocations}
      placeholder="Locations"
      noOptionsMessage={({inputValue}) =>
        inputValue ? 'No location found' : 'Search location by name...'
      }
      isLoading={isLoading}
      isMulti
      onChange={onLocationChange}
      {...props}
    />
  )
}

export default LocationsSelect
