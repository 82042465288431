import React from 'react'
import {Button} from '@material-ui/core'
import clsx from 'clsx'
import _ from 'lodash'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {SelectPlan} from '../../common/Icons'

const formatNumber = string => Math.floor(parseFloat(string)).toLocaleString()

function Item({title, value = '0', prefix = '', complement = '', classes}) {
  return (
    <div className={classes.planInfoGroup}>
      <span className={classes.infoKey}>{title}</span>
      <span className={classes.infoValue}>{prefix + value + complement}</span>
    </div>
  )
}

export default function PlanCard({
  tierPlan,
  classes,
  selectedPlan,
  onSelectPlan,
  theme,
  isMonthly,
}) {
  const isMediumOrGreater = useMediaQuery(theme.breakpoints.up('md'))
  let isSelected
  if (_.get(tierPlan, 'name') === _.get(selectedPlan, 'name')) {
    isSelected = true
  }

  const getPerUserPrice = () => {
    if (tierPlan.name === 'PRO') return '$45'
    if (tierPlan.name === 'BUSINESS') return '$100'
    return 'N/A'
  }

  return (
    <div
      className={isSelected || isMediumOrGreater ? classes.planContainer : ''}
      style={{
        backgroundColor: isSelected && isMediumOrGreater && theme.palette.Gray[100],
        border: isSelected && '0px',
      }}
    >
      <Button
        className={classes.planCard}
        onClick={() => onSelectPlan(isSelected ? null : tierPlan)}
        style={
          isSelected && {
            backgroundColor: theme.palette.Primary[600],
          }
        }
      >
        <div className={classes.planGroup}>
          {isSelected ? (
            <SelectPlan className={classes.checkCircleOn} />
          ) : (
            <div className={classes.checkCircle} />
          )}
          <span
            className={classes.planName}
            style={
              isSelected && {
                color: theme.palette.Gray[100],
                fontWeight: 700,
              }
            }
          >
            {tierPlan.name}
          </span>
        </div>
        <div className={classes.priceGroup}>
          <span
            className={classes.planPrice}
            style={
              isSelected && {
                color: theme.palette.Gray[100],
              }
            }
          >
            ${isMonthly ? formatNumber(tierPlan.monthlyPrice) : formatNumber(tierPlan.yearlyPrice)}
          </span>
          <span
            className={clsx(classes.planFreq, classes.text)}
            style={
              isSelected && {
                color: theme.palette.Gray[100],
              }
            }
          >
            {isMonthly ? '/Month' : '/Year'}
          </span>
        </div>
        {/*isMediumOrGreater && (
          <span
            className={clsx(classes.planFreq, classes.text, classes.textFlavor)}
            style={
              isSelected && {
                color: theme.palette.Gray[500],
              }
            }
          >
            Flavor text of the plan “Find the best place to get your idea going
          </span>
        )*/}
      </Button>
      {(isSelected || isMediumOrGreater) && (
        <section className={classes.dividerCard} style={{border: isSelected && '0px'}}>
          <Item
            classes={classes}
            title="Users (included)"
            value={tierPlan.name === 'ENTERPRISE' ? 'Unlimited' : '1'}
          />
          <Item
            classes={classes}
            title="Additional Users"
            value={getPerUserPrice()}
            complement={tierPlan.name !== 'ENTERPRISE' ? '/user' : ''}
          />
          <Item
            classes={classes}
            title="Monthly Searches"
            value={
              tierPlan.name === 'ENTERPRISE'
                ? 'Unlimited'
                : `$${formatNumber(tierPlan.searchesPerMonth)}`
            }
            complement={tierPlan.name === 'ENTERPRISE' ? '' : '/month'}
          />
        </section>
      )}
    </div>
  )
}
