import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import {AreaChart, XAxis, YAxis, Tooltip, Area, ReferenceLine} from 'recharts'
import {withStyles} from '@material-ui/styles'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import URLSearchParams from 'url-search-params'
import {updateLocal} from './redux/actions.js'

import styles, {
  Dot,
  TableCellRegion,
  TableCellNormal,
  TableCellHeadPadding,
} from './GrowthResults.styles.js'
import PlanApi from './redux/api'

const formatCurrency = value => numeral(value).format('$0,0.00')
const formatCurrencyMin = value => {
  const tmp = numeral(value).format('$0a')
  if (tmp.includes('k')) return tmp
  return tmp.toUpperCase()
}
const formatNumber = value => numeral(value).format('0.[00]')

class ForecastResultsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      isLoading: true,
    }
  }

  renderBreakEvenProjection() {
    const {results} = this.state
    let totalInvestment = 0
    let totalProfit = 0
    const year1 = {year: '', profit: 0}
    const year2 = {
      year: 'Year 1',
      profit: 0,
    }
    const year3 = {
      year: 'Year 2',
      profit: 0,
    }
    const year4 = {
      year: 'Year 3',
      profit: 0,
    }
    results.forEach(item => {
      totalInvestment += item.initialInvestment
      year2.profit += item.year1
      year3.profit += item.year2
      year4.profit += item.year3
    })
    year2.profit += year1.profit
    year3.profit += year2.profit
    year4.profit += year3.profit
    totalProfit = year4.profit
    year1.profit = formatNumber(year1.profit)
    year2.profit = formatNumber(year2.profit)
    year3.profit = formatNumber(year3.profit)
    year4.profit = formatNumber(year4.profit)
    const data = [year1, year2, year3, year4]
    return (
      <AreaChart
        width={600}
        height={300}
        data={data}
        margin={{top: 50, right: 50, bottom: 20, left: 40}}
      >
        <Area type="monotone" dataKey="profit" stroke="#e3c05b" fill="#e3c05b" fillOpacity={1} />
        <XAxis dataKey="year" />
        <YAxis
          domain={[
            0,
            totalInvestment > year1.profit &&
            totalInvestment > year2.profit &&
            totalInvestment > year3.profit &&
            totalInvestment > year4.profit
              ? totalInvestment
              : totalProfit,
          ]}
          tickFormatter={formatCurrencyMin}
        />
        <Tooltip
          formatter={value => {
            return formatCurrencyMin(value)
          }}
        />
        {totalInvestment !== 0 && (
          <ReferenceLine
            y={totalInvestment}
            label={'Capital investment ' + formatCurrencyMin(totalInvestment)}
            stroke="green"
            strokeDasharray="3 3"
            isFront={true}
          />
        )}
      </AreaChart>
    )
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.params = new URLSearchParams(this.props.location.search)
    } else {
      this.params = new URLSearchParams()
    }
    const rpc = this.params.get('rpc')
    const rog = this.params.get('rog')
    const lts = this.params.get('lts')
    const lts1 = this.params.get('lts1')
    const lts2 = this.params.get('lts2')
    const lts3 = this.params.get('lts3')
    const ii = this.params.get('ii')
    const locs = this.params.getAll('locs')[0].split(',')

    this.props.updateLocal(
      {
        revenuePerContract: rpc,
        rateOfGrowth: rog,
        lifetimeSpend: lts,
        lifetimeSpendYear1: lts1,
        lifetimeSpendYear2: lts2,
        lifetimeSpendYear3: lts3,
        initialInvestment: ii,
        locations: locs,
      },
      'forecast',
    )
    this.setState({isLoading: true}, () => {
      PlanApi.getForecast(this.props.params.id, this.props.forecast).then(response => {
        this.setState({results: response.locations, isLoading: false})
      })
    })
  }

  renderMap() {
    if (window) {
      const {planResults} = this.props
      const {results} = this.state
      const MapComponent = require('./GrowthResultsMap.jsx').default
      return (
        <MapComponent plan={planResults.plan} locations={results.map(result => result.location)} />
      )
    }
    return null
  }

  render() {
    const {classes} = this.props
    const {results, isLoading} = this.state

    if (isLoading) {
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )
    }

    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography className={classes.paperTitle}>Break Even Projection</Typography>
              <Paper>{this.renderBreakEvenProjection()}</Paper>
            </Grid>
            <Grid item sm={6} className={classes.forecastMapGripItem}>
              <Typography className={classes.paperTitle}>Area of Interest</Typography>
              <Paper className={classes.forecastMapPaper}>{this.renderMap()}</Paper>
            </Grid>
          </Grid>
          <Paper className={classes.tablePaper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellRegion style={{width: '30%', paddingLeft: 24}}>Region</TableCellRegion>
                  <TableCellNormal align="right">Capital Investment</TableCellNormal>
                  <TableCellNormal align="right">Break Even</TableCellNormal>
                  <TableCellHeadPadding align="right">Profit Year 1</TableCellHeadPadding>
                  <TableCellHeadPadding align="right">Profit Year 2</TableCellHeadPadding>
                  <TableCellHeadPadding align="right">Profit Year 3</TableCellHeadPadding>
                  <TableCellNormal align="right">Total Revenue</TableCellNormal>
                  <TableCellNormal align="right">Total Profit</TableCellNormal>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map(item => {
                  const monthsToBreakEven = Math.ceil(item.monthsToBreakEven)
                  return (
                    <TableRow key={item.location.id}>
                      <TableCellRegion style={{paddingRight: 0}}>
                        <Dot color={item.location.color} /> {item.location.location.properties.name}
                      </TableCellRegion>
                      <TableCellNormal align="right">
                        {formatCurrency(item.initialInvestment)}
                      </TableCellNormal>
                      <TableCellNormal align="right">
                        {monthsToBreakEven > 36 ? `> 36 Mos` : `${monthsToBreakEven} Mos`}
                      </TableCellNormal>
                      <TableCellNormal align="right">{formatCurrency(item.year1)}</TableCellNormal>
                      <TableCellNormal align="right">{formatCurrency(item.year2)}</TableCellNormal>
                      <TableCellNormal align="right">{formatCurrency(item.year3)}</TableCellNormal>
                      <TableCellNormal align="right">
                        {formatCurrency(item.totalRevenue)}
                      </TableCellNormal>
                      <TableCellNormal align="right" style={{color: '#47B258', fontWeight: 'bold'}}>
                        {formatCurrency(item.totalProfit)}
                      </TableCellNormal>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

ForecastResultsTable.displayName = 'ForecastResultsTable'

ForecastResultsTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({updateLocal}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.growthPlan,
  }
}

const customStyles = theme => {
  const currentStyles = styles(theme)
  return {
    ...currentStyles,
    tablePaper: {
      ...currentStyles.tablePaper,
      marginTop: 30,
    },
    paperTitle: {
      textAlign: 'center',
      fontSize: 27,
      marginBottom: 15,
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customStyles, {withTheme: true}),
)(ForecastResultsTable)
