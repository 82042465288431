import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {TableRow, Checkbox, IconButton, LinearProgress} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import autobind from 'autobind-decorator'
import styles, {Dot, TableCellRegion, TableCellNormal} from './GrowthResults.styles.js'
import PlanApi from './redux/api'

const formatNumber = value => numeral(value).format('0,0.[00]')
const formatInteger = value => numeral(value).format('0,0')
const formatCurrency = value => numeral(value).format('$0,0.00')

class GrowthResultsTableRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isKeywordsOpen: false,
      isLocationsOpen: false,
      isLoadingDrilldown: false,
      children: [],
      locations: [],
      checked: false,
    }
  }

  @autobind
  toggleLocation() {
    if (!this.props.showOpportunityGraph) {
      this.setState(state => ({
        checked: !state.checked,
      }))
      this.props.toggleLocation(this.props.item.id)
    }
  }

  @autobind
  toggleDrilldown(e) {
    e.stopPropagation()
    if (this.state.isKeywordsOpen || this.state.isLocationsOpen) {
      this.setState({isKeywordsOpen: false, isLocationsOpen: false, children: []})
    } else if (this.state.checked && !this.state.isKeywordsOpen && !this.state.isLocationsOpen) {
      this.toggleKeywordsDrilldown()
    } else if (
      ['Postal Code', 'Neighborhood'].indexOf(this.props.item.location.properties.targetType) >= 0
    ) {
      this.toggleKeywordsDrilldown()
    } else {
      this.toggleLocationsDrilldown()
    }
  }

  toggleKeywordsDrilldown() {
    if (!this.state.isLoadingDrilldown) {
      this.setState({isKeywordsOpen: true})
      this.setState({isLoadingDrilldown: true}, async () => {
        try {
          const result = await PlanApi.getResultLocationKeywordDrilldown(this.props.item.id)
          this.setState({isLoadingDrilldown: false, children: result.children})
        } catch (e) {
          console.error(e)
        }
      })
    }
  }

  getLocations() {
    this.setState({isLoadingDrilldown: true}, async () => {
      try {
        const result = await PlanApi.getResultLocationDrilldown(this.props.item.id)
        this.setState({isLoadingDrilldown: !result.isFininshed, children: result.children})
        if (!result.isFininshed) {
          setTimeout(() => {
            this.getLocations()
          }, 1000)
        }
      } catch (e) {
        this.setState({isLoadingDrilldown: false})
        console.error(e)
      }
    })
  }

  toggleLocationsDrilldown() {
    if (!this.state.isLoadingDrilldown) {
      this.setState({isLocationsOpen: true})
      this.getLocations()
    }
  }

  render() {
    const {item, showOpportunityGraph, classes, className, color} = this.props
    const {isKeywordsOpen, isLocationsOpen, isLoadingDrilldown, children, checked} = this.state
    const isDrilldownOpen = isKeywordsOpen || isLocationsOpen
    return (
      <React.Fragment>
        {((checked && showOpportunityGraph) || !showOpportunityGraph) && (
          <TableRow
            hover={!showOpportunityGraph}
            onClick={this.toggleLocation}
            className={className}
          >
            <TableCellRegion>
              <div className={classes.regionWrapper}>
                <div className={classes.selectWrapper}>
                  {!showOpportunityGraph && (
                    <IconButton onClick={this.toggleDrilldown} disabled={isLoadingDrilldown}>
                      {isDrilldownOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                  )}{' '}
                  <Checkbox checked={checked} disabled={showOpportunityGraph} />{' '}
                  <Dot color={color || item.color} />
                </div>
                <div className={classes.regionName}>{item.location.properties.name}</div>
              </div>
            </TableCellRegion>
            <TableCellNormal align="right">
              {formatInteger(item.searchVolume)}{' '}
              <span className={classes.tableSearchPercent}>
                {formatNumber(item.searchPercent)}%
              </span>
            </TableCellNormal>
            <TableCellNormal align="right">{formatNumber(item.totalLead)}</TableCellNormal>
            <TableCellNormal align="right">{formatInteger(item.newCustomers)}</TableCellNormal>
            <TableCellNormal align="right">{formatCurrency(item.monthlyRevenue)}</TableCellNormal>
            <TableCellNormal align="right" style={{color: '#47B258', fontWeight: 'bold'}}>
              {formatCurrency(item.estimatedProfit)}
            </TableCellNormal>
          </TableRow>
        )}

        {isDrilldownOpen && isLoadingDrilldown && (
          <TableRow className={classes.childRow}>
            <TableCellNormal align="right" colSpan={6}>
              <LinearProgress />
            </TableCellNormal>
          </TableRow>
        )}

        {isDrilldownOpen &&
          isKeywordsOpen &&
          children.map(child => (
            <TableRow key={child.id} className={classes.childRowKeywords}>
              <TableCellRegion>
                <Dot color={color || item.color} /> {child.keywords}
              </TableCellRegion>
              <TableCellNormal align="right">
                {formatInteger(child.searchVolume)}{' '}
                <span className={classes.tableSearchPercent}>
                  {formatNumber(child.searchPercent)}%
                </span>
              </TableCellNormal>
              <TableCellNormal align="right">{formatNumber(child.totalLead)}</TableCellNormal>
              <TableCellNormal align="right">{formatInteger(child.newCustomers)}</TableCellNormal>
              <TableCellNormal align="right">
                {formatCurrency(child.monthlyRevenue)}
              </TableCellNormal>
              <TableCellNormal align="right" style={{color: '#47B258', fontWeight: 'bold'}}>
                {formatCurrency(child.estimatedProfit)}
              </TableCellNormal>
            </TableRow>
          ))}

        {isDrilldownOpen &&
          isLocationsOpen &&
          children.map(child => (
            <GrowthResultsTableRowComponent
              item={child}
              key={child.id}
              className={classes.childRow}
              color={item.color}
              showOpportunityGraph={showOpportunityGraph}
              toggleLocation={this.props.toggleLocation}
            />
          ))}
      </React.Fragment>
    )
  }
}

GrowthResultsTableRow.displayName = 'GrowthResultsTableRow'

GrowthResultsTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  depth: PropTypes.number,
}

GrowthResultsTableRow.defaultProps = {
  depth: 1,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

function mapStateToProps() {
  return {}
}

const childrenStyles = theme => ({
  ...styles(theme),
  childRow: {
    borderTop: '.5px solid #d8d8d8',
    borderBottom: '.5px solid #d8d8d8',
    '& > td:first-child': {
      paddingLeft: props => theme.spacing(5 + props.depth),
    },
  },
  childRowKeywords: {
    borderTop: '.5px solid #d8d8d8',
    borderBottom: '.5px solid #d8d8d8',
    '& > td:first-child': {
      paddingLeft: props => theme.spacing(18 + props.depth),
    },
  },
  regionWrapper: {
    display: 'flex',
  },
  selectWrapper: {
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
  },
  regionName: {
    flexShrink: 1,
    paddingTop: 17,
  },
})

const GrowthResultsTableRowComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(childrenStyles, {withTheme: true}),
)(GrowthResultsTableRow)

GrowthResultsTableRowComponent.propTypes = {
  depth: PropTypes.number,
}

GrowthResultsTableRowComponent.defaultProps = {
  depth: 1,
}

export default GrowthResultsTableRowComponent
